import CategoryModal from 'components/organisms/Admin/InspectionItem/CategoryModal'
import InspectionItemModal from 'components/organisms/Admin/InspectionItem/InspectionItemModal'
import TableCard from 'components/organisms/Admin/InspectionItem/TableCard'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'

const InspectionItem: React.VFC = () => {
  const { storeCache } = useContext(AuthContext)
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState<boolean>(false)
  const [isInspectionItemModalOpen, setIsInspectionItemModalOpen] =
    useState<boolean>(false)

  const onToggleCategoryModal = () =>
    setIsCategoryModalOpen(!isCategoryModalOpen)
  const onToggleisInspectionItemModal = () =>
    setIsInspectionItemModalOpen(!isInspectionItemModalOpen)

  return (
    <>
      <CategoryModal
        isOpen={isCategoryModalOpen}
        storeCache={storeCache}
        onToggle={onToggleCategoryModal}
      />
      <InspectionItemModal
        isOpen={isInspectionItemModalOpen}
        storeCache={storeCache}
        onToggle={onToggleisInspectionItemModal}
      />
      <TableCard
        storeCache={storeCache}
        onToggle={onToggleisInspectionItemModal}
        onToggleForCategory={onToggleCategoryModal}
      />
    </>
  )
}

export default InspectionItem
