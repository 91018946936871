import { IStoreCache } from 'common/types/auth_provider'
import { Category } from 'common/types/category'
import {
  CategoryError,
  CategoryForm,
  CategoryFormArray,
} from 'common/types/form/category'
import { getErrorMessage } from 'common/utils'
import { reloadCachedCategories } from 'providers/AuthProvider'
import { FieldError } from 'react-hook-form'
import { save } from 'repositories/category'
import { alertService } from 'services/alert'

/**
 * カテゴリ配列フォーム用初期値設定
 */
export function initCategoryArray(
  client_id: number,
  categories: Category[]
): CategoryFormArray {
  return {
    items: categories.map(({ id, name, color }) => {
      return { category_id: id, client_id, name, color }
    }),
  }
}

/**
 * カテゴリ初期値設定
 */
export function initCategory(client_id: number): CategoryForm {
  return { category_id: 0, client_id, name: '', color: '#000000' }
}

/**
 * react-hook-form：エラーフィールド定義
 */
export function errorField(
  errors: CategoryError,
  index: number,
  field: 'name' | 'color'
): FieldError | undefined {
  if (
    errors.items === undefined ||
    errors.items[index] === undefined ||
    errors.items[index][field] === undefined
  )
    return undefined
  return errors.items[index][field]
}

/**
 * すべてのカテゴリを保存する
 */
export async function saveCategories(
  storeCache: IStoreCache,
  data: CategoryFormArray
): Promise<void> {
  try {
    if (data.items.length === 0)
      throw new Error('カテゴリを一つ以上作成してください。')

    await save(storeCache.token, data)
    await reloadCachedCategories(storeCache)

    alertService.show(true, 'カテゴリの保存が完了しました。')
  } catch (error) {
    getErrorMessage(error)
  }
}
