import React from 'react'

type TDisplay4 = {
  content: string
}

const Display4: React.VFC<TDisplay4> = (props: TDisplay4) => {
  const { content } = props
  return <span className="display-4 page-title">{content}</span>
}

export default Display4
