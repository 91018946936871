import * as React from 'react'
import { SVGProps } from 'react'

const IconAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={19}
    viewBox="0 0 19 19"
    {...props}
  >
    <path
      data-name="Path 761"
      d="M9.5 0A9.5 9.5 0 1 1 0 9.5 9.5 9.5 0 0 1 9.5 0Z"
      fill="#fff"
    />
    <path
      data-name="Union 1"
      d="M9.375 14.471v-4.375H5v-1.25h4.375V4.471h1.25v4.375H15v1.25h-4.375v4.375Z"
      fill="#17a2b8"
    />
  </svg>
)

export default IconAdd
