import React from 'react'
import { Button } from 'reactstrap'

type TTableButton = {
  className?: string
  content: string
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'danger'
    | 'warning'
  size?: 'sm' | 'md' | 'lg'
  onClick: () => void
}

const NomalButton: React.VFC<TTableButton> = (props: TTableButton) => {
  const { className, content, color, size, onClick } = props
  return (
    <Button
      className={className ?? undefined}
      onClick={onClick}
      color={color ?? 'default'}
      size={size ?? 'md'}
      type="button"
    >
      {content}
    </Button>
  )
}

export default NomalButton
