import { Labels } from 'common/enums'
import { Category } from 'common/types/category'
import {
  InspectionItemError,
  InspectionItemFormArray,
} from 'common/types/form/inspection_item'
import {
  createRequiredMessage,
  createRequiredMessageForSelect,
} from 'common/utils'
import Feedback from 'components/atoms/Feedback/Feedback'
import { selectCategoriesOptions } from 'components/atoms/Form/Select/Options'
import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { Col, Input, ListGroupItem, Row } from 'reactstrap'
import { errorField } from 'services/admin/inspection_item'

type TListItem = {
  index: number
  categories: Category[]
  control: Control<InspectionItemFormArray, object> | undefined
  errors: InspectionItemError
  onRemove: () => void
}

const InspectionItemListItem: React.VFC<TListItem> = ({
  index,
  categories,
  control,
  errors,
  onRemove,
}: TListItem) => {
  return (
    <ListGroupItem className="px-0 inspection-input">
      <Row className="align-items-start">
        <Col sm={12} md={3} lg={2} className="pl-1 pr-2">
          <div className="selectwrap">
            <Controller
              name={`items.${index}.category_id`}
              control={control}
              rules={{
                required: createRequiredMessageForSelect(Labels.Category),
              }}
              render={({ field }) => (
                <Input
                  type="select"
                  className={`form-control-flush ${
                    errorField(errors, index, 'category_id') ? 'is-invalid' : ''
                  }`}
                  placeholder={Labels.Category}
                  {...field}
                >
                  {selectCategoriesOptions(categories)}
                </Input>
              )}
            />
          </div>
          <Feedback error={errorField(errors, index, 'category_id')} />
        </Col>
        <Col sm={12} md={4} lg={4} className="pl-0 pr-2">
          <Controller
            name={`items.${index}.content`}
            control={control}
            rules={{
              required: createRequiredMessage(Labels.Content),
            }}
            render={({ field }) => (
              <Input
                type="text"
                className={`form-control-flush ${
                  errorField(errors, index, 'content') ? 'is-invalid' : ''
                }`}
                placeholder={Labels.Content}
                {...field}
              />
            )}
          />
          <Feedback error={errorField(errors, index, 'content')} />
        </Col>
        <Col sm={12} md={4} lg={5} className="pl-0 pr-0 prev-delete-col">
          <Controller
            name={`items.${index}.detail`}
            control={control}
            rules={{
              required: createRequiredMessage(Labels.Detail),
            }}
            render={({ field }) => (
              <Input
                type="text"
                className={`form-control-flush ${
                  errorField(errors, index, 'detail') ? 'is-invalid' : ''
                }`}
                placeholder={Labels.Detail}
                {...field}
              />
            )}
          />
          <Feedback error={errorField(errors, index, 'detail')} />
        </Col>
        <Col sm={1} className="delete-col">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={onRemove}
          >
            <span aria-hidden className="delete-btn">
              &#x2715;
            </span>
          </button>
        </Col>
      </Row>
    </ListGroupItem>
  )
}

export default InspectionItemListItem
