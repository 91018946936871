import axios from 'axios'
import { get, post } from 'common/fetch'
import { User, GetUserInfo } from 'common/types/user'
import { alertService } from 'services/alert'

const path = (): string => '/api/user'

export const getUserInfo = async (
  token: string,
  data: GetUserInfo
): Promise<any> => {
  const getUserInfoPath = `${process.env.REACT_APP_ENV}` === 'dev' ?
    'https://dev-container.telema-core.com/api/test/ncs/getUserInfo' :
    `${process.env.REACT_APP_API_URL}/ncsdd/rest/v3.0/DACK/DACK010100/getUserInfo`
  return axios
    .post(getUserInfoPath, data, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((result) => result.data)
    .catch((error) => {
      console.log('error', error)
      alertService.show(
        false,
        'システムエラーが発生しました。しばらく時間をおいてから再アクセスするか、お問い合わせください。（立会者取得）'
      )
    return { data: {result: -1} }
  })
}

/**
 * ユーザ自身を取得する
 */
export async function fetchMe(token: string): Promise<any> {
  return await post<{ me: User }>(
    token,
    `${process.env.REACT_APP_API_URL}/ncsdd/rest/v3.0/DACK/DACK010100/getUserInfo`,
    {
      kaisyaCode: 'NCSDD',
      ncsUserId: 'smp123456',
      checkDate: '20220301T123400+0900',
    }
  )
}

/**
 * ユーザを全て取得する
 */
export async function fetch(token: string, client_id: number): Promise<User[]> {
  return (await get<{ users: User[] }>(token, path(), { client_id })).users
}
