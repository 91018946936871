import { Routes, ScreenLabels } from 'common/enums'
import { IStoreCache } from 'common/types/auth_provider'
import { IPreworkProvider } from 'common/types/prework_provider'
import LoadingIndicator from 'components/atoms/LoadingIndicator'
import Sidebar from 'components/molecules/Sidebar/Sidebar'
import Topbar from 'components/molecules/Topbar/Topbar'
import TableCard from 'components/organisms/Admin/Answer/TableCard'
import { AuthContext, reloadCachedToken } from 'providers/AuthProvider'
import { PreworkContext, PreworkProvider } from 'providers/PreworkProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Prompt } from 'react-router-dom'
import { customModalService } from 'services/customModal'

const Answer: React.VFC = () => {
  const { push } = useHistory()
  const storeCache: IStoreCache = useContext(AuthContext).storeCache
  const { onApplyFilter, isLoading, editedData }: IPreworkProvider =
    useContext(PreworkContext)
  const [nextPath, setNextPath] = useState<string>('')

  const notService = useMemo(
    () =>
      (storeCache.loginInfo?.permission === '1' &&
        storeCache.loginInfo?.carChkSvc === '0' &&
        storeCache.loginInfo?.alcoholChkSvc === '0') ||
      (storeCache.loginInfo?.permission === '2' &&
        storeCache.loginInfo?.carChkSvc === '0' &&
        storeCache.loginInfo?.alcoholChkSvc === '0') ||
      (storeCache.loginInfo?.permission === '0' &&
          storeCache.loginInfo?.carChkSvc === '0' &&
          storeCache.loginInfo?.alcoholChkSvc === '0') ||
      storeCache.loginInfo?.error,
    [storeCache.loginInfo]
  )
  const goToLogin = () => {
    localStorage.removeItem('token')
    reloadCachedToken(storeCache)
    push(Routes.Login)
  }

  useEffect(() => {
    if (nextPath) {
      setNextPath('')
      push(nextPath)
    }
  }, [nextPath])

  useEffect(() => {
    const hanleClosePage = (e: BeforeUnloadEvent) => {
      e.returnValue = ''
    }
    if (editedData.length) {
      window.addEventListener('beforeunload', hanleClosePage)
    }
    return () => {
      window.removeEventListener('beforeunload', hanleClosePage)
    }
  }, [editedData])

  const handleBlockedNavigation = (nextLocation: any) => {
    if (editedData?.length > 0 && !nextPath) {
      customModalService.show(
        '変更データが未登録ですがよろしいですか？',
        '',
        'はい',
        'いいえ',
        () => {
          setNextPath(nextLocation.pathname)
        }
      )
      return false
    }
    return true
  }

  return (
    <div className="page-container">
      {isLoading && <LoadingIndicator />}
      <Topbar title={ScreenLabels.Answer} editData={editedData} />
      <div className="table-container">
        <Sidebar onFilter={onApplyFilter} editData={editedData} />
        <TableCard />
      </div>
      <Modal
        className="modal-dialog-centered modal-custom-content"
        isOpen={notService}
      >
        <ModalHeader></ModalHeader>
        <ModalBody>
          サービス対象外のため、日常点検管理Webを利用できません
        </ModalBody>
        <ModalFooter
          style={{
            justifyContent: 'center',
          }}
        >
          <Button
            style={{
              backgroundColor: '#17A2B8',
              color: 'white',
            }}
            onClick={goToLogin}
          >
            日常点検管理Web ログイン画面へ
          </Button>
        </ModalFooter>
      </Modal>
      <Prompt message={handleBlockedNavigation} />
    </div>
  )
}

const AnswerWrap: React.VFC = () => {
  return (
    <PreworkProvider>
      <Answer />
    </PreworkProvider>
  )
}

export default AnswerWrap
