import * as React from 'react'
import { SVGProps } from 'react'

const IconTickCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    {...props}
  >
    <g data-name="Line Checkbox Tick">
      <g data-name="Rectangle 727" fill="#fff" stroke="#3b86ff" strokeWidth={2}>
        <rect width={16} height={16} rx={6} stroke="none" />
        <rect x={1} y={1} width={14} height={14} rx={5} fill="none" />
      </g>
      <path
        data-name="Path 319"
        d="M7.2 11.093 3.733 7.626l1.12-1.12L7.2 8.8l3.466-3.467 1.12 1.12Z"
        fill="#3b86ff"
      />
    </g>
  </svg>
)

export default IconTickCheck
