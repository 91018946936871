import React, { useContext } from 'react'
import { HeaderGroup, Row } from 'react-table'
import TableRows from 'components/atoms/Table/AlcoholTableRow'
import { IAlcoholProvider } from 'common/types/alcohol_provider'
import { AlcoholContext } from 'providers/AlcoholProvider'
import UpArrow from 'assets/img/UpArow.svg'
import DownArrow from 'assets/img/DownArrow.svg'
import { IStoreCache } from 'common/types/auth_provider'
import { AuthContext } from 'providers/AuthProvider'
import { alertService } from 'services/alert'
import { AchievementAlc } from 'common/types/achievement'
import IconTickNone from '../Img/IconTickNone'
import IconTickCheck from '../Img/IconTickCheck'
import IconTickLine from '../Img/IconTickLine'

type TStandardTable = {
  headerGroups: HeaderGroup<any>[]
  rows: Row<any>[]
  prepareRow: (row: Row<any>) => void
  toggleDetail: (params: AchievementAlc) => void
}

const AnswerStandardTable: React.VFC<TStandardTable> = ({
  headerGroups,
  rows,
  prepareRow,
  toggleDetail,
}: TStandardTable) => {
  const {
    checkAll,
    toggleSelectAll,
    handleSort,
    sortBy,
    editData,
    editedData,
    registerAchievementRows,
  }: IAlcoholProvider = useContext(AlcoholContext)
  const { loginInfo }: IStoreCache = useContext(AuthContext).storeCache

  const onClickSort = (id: string) => {
    let sortId = id
    if (id === 'witness_kind' || id === 'implementation') {
      sortId = `alc_${id}`
    }
    if (editedData.length === 0) {
      handleSort(sortId)
    }
  }

  const onClickShonin = () => {
    if (
      editData.some(
        (item) =>
          !item.alc_checklogs_status_shonin && !item.alc_checklogs_status_shuki
      ) &&
      !checkAll.alc_checklogs_status_shuki
    ) {
      alertService.show(false, '先に酒気帯びなしの確認が必要です')
      return
    }
    toggleSelectAll('alc_checklogs_status_shonin')
  }

  const onClickShuki = () => {
    if (
      checkAll.alc_checklogs_status_shonin &&
      checkAll.alc_checklogs_status_shuki
    ) {
      return
    }
    toggleSelectAll('alc_checklogs_status_shuki')
  }

  return (
    <>
      <div>
        <div className="wrap-header-table">
          {headerGroups.map((headerGroup) =>
            headerGroup.headers.map(
              (header, index) =>
                index >= 0 && (
                  <React.Fragment key={index}>
                    <div className={`alcohol-table-column column-label`}>
                      <div
                        onClick={
                          registerAchievementRows.length <= 0 ? () => onClickSort(header.id) : () => {}
                        }
                        className="header-wrap"
                      >
                        <div
                          className={`${registerAchievementRows.length <= 0 ? 'header-title ' : 'header-title-2 '} ${
                            index === 12 ? 'approver' : ''
                          }`}
                        >
                          <div>{header.render('Header')}</div>
                          {index === 14 && <span>その他必要な事項</span>}
                          {index === 9 && <span>体調</span>}
                          {index === 10 && <span>びなし</span>}
                          <div
                            className={`${
                              index === 6 ||
                              index === 7 ||
                              index === 9 ||
                              index === 10
                                ? 'header-sort-1'
                                : index === 14
                                ? 'header-sort-2'
                                : index === 8
                                ? 'header-sort-3'
                                : 'header-sort'
                            }`}
                          >
                            {(header.id === 'witness_kind' ||
                            header.id === 'implementation'
                              ? sortBy[0] === `alc_${header.id}`
                              : sortBy[0] === header.id) && (
                              <img
                                src={UpArrow}
                                alt=""
                                className="custom-sort-arrow"
                              />
                            )}
                            {(header.id === 'witness_kind' ||
                            header.id === 'implementation'
                              ? sortBy[0] === `-alc_${header.id}`
                              : sortBy[0] === `-${header.id}`) && (
                              <img
                                src={DownArrow}
                                alt=""
                                className="custom-sort-arrow"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      {index === 10 &&
                        (loginInfo?.permission === '0' || registerAchievementRows.length > 0 ? (
                          <div className="wrap-checkbox-table wrap-checkbox-alcoho checkbox-margin">
                            <IconTickNone fill="#E9ECEF" color="#E9ECEF" />
                          </div>
                        ) : (
                          <div
                            className="wrap-checkbox-table checkbox-margin"
                            onClick={onClickShuki}
                          >
                            {checkAll.alc_checklogs_status_shuki === null && (
                              <IconTickLine />
                            )}
                            {checkAll.alc_checklogs_status_shuki === true && (
                              <IconTickCheck />
                            )}
                            {checkAll.alc_checklogs_status_shuki === false && (
                              <IconTickNone />
                            )}
                          </div>
                        ))}
                      {index === 11 &&
                        (loginInfo?.permission === '0' || registerAchievementRows.length > 0 ? (
                          <div className="wrap-checkbox-table wrap-checkbox-alcoho">
                            <IconTickNone fill="#E9ECEF" color="#E9ECEF" />
                          </div>
                        ) : (
                          <div
                            className="wrap-checkbox-table"
                            onClick={onClickShonin}
                          >
                            {checkAll.alc_checklogs_status_shonin === null && (
                              <IconTickLine />
                            )}
                            {checkAll.alc_checklogs_status_shonin === true && (
                              <IconTickCheck />
                            )}
                            {checkAll.alc_checklogs_status_shonin === false && (
                              <IconTickNone />
                            )}
                          </div>
                        ))}
                    </div>
                  </React.Fragment>
                )
            )
          )}
        </div>
        <div className="table-row custom-scrollbar">
          {rows.map((row, index) => {
            prepareRow(row)
            return row && <TableRows data={row} toggleDetail={toggleDetail} key={index} />
          })}
        </div>
      </div>
    </>
  )
}

export default AnswerStandardTable
