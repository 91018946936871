import { Achievement, AchievementAlc } from 'common/types/achievement'
import { UpdateParams } from 'common/types/archievement-update'
import { getErrorMessage } from 'common/utils'
import {
  updateArchievementAlcohol,
  updateArchievementWeb,
} from 'repositories/achievement-update'
import { alertService } from 'services/alert'

export async function updateArchievement(
  params: UpdateParams
): Promise<boolean> {
  try {
    const res = await updateArchievementWeb(params)

    if (String(res.status) === '1') {
      return true
    }
    alertService.show(false, String(res.message))
    return false
  } catch (error) {
    return false
  }
}
export async function updateArchievementAlc(
  params: UpdateParams
): Promise<boolean> {
  try {
    const res = await updateArchievementAlcohol(params)
    // console.log(res)

    if (String(res.status) === '1') {
      return true
    }
    alertService.show(false, String(res.message))
    return false
  } catch (error) {
    return false
  }
}

export function getDifferencePrework(
  array1: Achievement[],
  array2: Achievement[]
) {
  return array1.filter((object1) => {
    const object2 = array2.find(
      (e) => object1.achievement_id === e.achievement_id
    )

    return (
      object2 &&
      (object1.car_name !== object2.car_name ||
        object1.car_soshiki_name !== object2.car_soshiki_name ||
        object1.checklogs_status !== object2.checklogs_status ||
        object1.checklogs_user_name !== object2?.checklogs_user_name)
    )
  })
}

export function getDifferenceAlc(
  array1: AchievementAlc[],
  array2: AchievementAlc[]
) {
  return array1.filter((object1) => {
    const object2 = array2.find(
      (e) => object1.achievement_id === e.achievement_id
    )

    return (
      object2 &&
      (object1.car_name !== object2.car_name ||
        object1.car_soshiki_name !== object2.car_soshiki_name ||
        object1.alc_checklogs_status_shuki !==
          object2.alc_checklogs_status_shuki ||
        object1.alc_checklogs_status_shonin !==
          object2.alc_checklogs_status_shonin ||
        object1.alc_checklogs_shonin_user_name !==
          object2.alc_checklogs_shonin_user_name ||
        object1.description !== object2.description)
    )
  })
}
