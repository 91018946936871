import { Routes, Icons, ScreenLabels, InputType } from 'common/enums'
import ItemLink from 'components/atoms/Nav/ItemLink'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarToggler,
  Button,
  Input,
  Col,
} from 'reactstrap'
import Select from 'react-select'
import 'styles/sidebar.scss'
import { getInputType } from 'common/utils'
import IconSetting from 'components/atoms/Img/IconSetting'
import { useLocation } from 'react-router'
import PreworkInspectionPattern from 'components/organisms/Admin/PreworkInspectionPattern'
import { AuthContext } from 'providers/AuthProvider'
import { IStoreCache } from 'common/types/auth_provider'
import moment from 'moment'
import { FilterParams } from 'common/types/achievement'
import { alertService } from 'services/alert'
import { customModalService } from 'services/customModal'
import { AlcoholContext } from 'providers/AlcoholProvider'
import { IAlcoholProvider } from 'common/types/alcohol_provider'
import { PreworkContext } from 'providers/PreworkProvider'
import { IPreworkProvider } from 'common/types/prework_provider'

interface DateRange {
  from: string
  to: string
}

type SearchType = 'car_name' | 'car_soshiki' | 'user_name' | 'user_soshiki'

interface Search {
  search_type: SearchType
  search_text: string
}

interface Props {
  onFilter?: (params: FilterParams) => void
  type?: string
  editData?: any[]
  showConfirmModal?: boolean
}

const Sidebar: React.VFC<Props> = (props) => {
  const location = useLocation()
  const { editData, onFilter } = props
  const [collapseOpen, setCollapseOpen] = useState<boolean>(false)
  const [selectDay, setSelectDay] = useState<boolean>(true)
  const [showInpsection, setShowInspection] = useState<boolean>(false)
  const {
    registerAchievements,
    resetRegisterAchievements
  }: IAlcoholProvider = useContext(AlcoholContext)

  const {
    inspectionPatternNameOption
  }: IPreworkProvider = useContext(PreworkContext)

  const toggleInspectionPattern = () => {
    // if (registerAchievements?.length > 0) {
    //   customModalService.show(
    //     '変更データが未登録ですがよろしいですか？',
    //     '',
    //     'はい',
    //     'いいえ',
    //     () => {
    //       setShowInspection((prev) => !prev)
    //     }
    //   )
    // } else {
      setShowInspection((prev) => !prev)
    // }
  }
  const sideBarToggle = () => setCollapseOpen(!collapseOpen)
  const { loginInfo }: IStoreCache = useContext(AuthContext).storeCache

  const [dateRange, setDateRange] = useState<DateRange>({
    from: moment().startOf('day').format('YYYYMMDDHHmmss'),
    to: moment().endOf('day').format('YYYYMMDDHHmmss'),
  })
  const [currentSelectDay, setCurrentSelectDay] = useState<DateRange>({
    ...dateRange,
  })
  const [search, setSearch] = useState<Search>({
    search_type: 'user_name',
    search_text: '',
  })
  const [checkData, setCheckData] = useState({
    value: '3',
    label: '全て',
  })
  const [checkLog, setCheckLog] = useState({
    value: '3',
    label: '全て',
  })
  const [checkStatus, setCheckStatus] = useState({
    value: '3',
    label: '全て',
  })
  const [checkInspectionPattern, setInspectionPattern] = useState({
    value: '',
    label: '全て',
  })
  const validateStart = moment(
    `${dateRange.from.slice(0, 4)}-${dateRange.from.slice(
      4,
      6
    )}-${dateRange.from.slice(6, 8)}`
  )
  const validateEnd = moment(
    `${dateRange.to.slice(0, 4)}-${dateRange.to.slice(
      4,
      6
    )}-${dateRange.to.slice(6, 8)}`
  )

  const onClickFilter = () => {

    if (dateRange.from === 'Invalid date' || dateRange.to === 'Invalid date') {
      alertService.show(
        false,
        '登録日の期間指定が不正です。'
      )
      return
    }
    if (
      (Array.isArray(editData) && editData?.length > 0) ||
      registerAchievements?.length > 0
    ) {
      customModalService.show(
        '変更データが未登録ですがよろしいですか？',
        '',
        'はい',
        'いいえ',
        onApplyFilter
      )
    } else {
      onApplyFilter()
    }
  }

  const onApplyFilter = () => {

    const params: FilterParams = {
      search_type: search.search_text ? search.search_type : '',
      search_text: search.search_text,
      check_data: checkData.value,
      check_status: checkStatus.value,
      check_log: checkLog.value,
      start_date: dateRange.from,
      end_date: dateRange.to,
      inspection_pattern_name: checkInspectionPattern.value,
    }
    if (
      moment(validateStart).format('YYYYMMDD') <
      moment().subtract(13, 'month').format('YYYYMMDD') ||
      validateStart > moment()
    ) {
      alertService.show(
        false,
        'エラーがあります。開始年月日が不正です。'
      )
      return
    }
    if (validateEnd > moment()) {
      alertService.show(
        false,
        'エラーがあります。終了年月日が不正です。'
      )
      return
    }
    if (validateStart > validateEnd) {
      alertService.show(
        false,
        'エラーがあります。開始年月日が終了年月日を超えています。'
      )
      return
    }

    onFilter && onFilter(params)
  }

  const selectCurrentDay = () => {
    if (!selectDay) {
      setSelectDay(true)
      setDateRange({
        from: moment().startOf('date').format('YYYYMMDDHHmmss'),
        to: moment().endOf('date').format('YYYYMMDDHHmmss'),
      })
      setCurrentSelectDay({
        ...dateRange,
      })
    }
  }

  const selectDateRange = () => {
    if (selectDay) {
      setSelectDay(false)
      setDateRange({
        ...currentSelectDay,
      })
    }
  }

  const onChangeStartDate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setDateRange((prev) => ({
        ...prev,
        from: moment(e.target.value, 'YYYY-MM-DD')
          .startOf('date')
          .format('YYYYMMDDHHmmss'),
      }))
    },
    []
  )
  const onChangeEndDate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setDateRange((prev) => ({
        ...prev,
        to: moment(e.target.value, 'YYYY-MM-DD')
          .endOf('date')
          .format('YYYYMMDDHHmmss'),
      }))
    },
    []
  )

  const onChangeSearchText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch((prev) => ({
        ...prev,
        search_text: e.target.value,
      }))
    },
    []
  )

  const onChangeSearchType = (value: SearchType) => {
    setSearch((prev) => ({
      ...prev,
      search_type: value,
    }))
  }

  return (
    <Navbar
      className="navbar-vertical navbar-light nav-container admin-navbar"
      id="sidenav-main"
    >
      <Container fluid>
        <div>
          <Nav navbar>
            {!!loginInfo?.carChkSvc && loginInfo?.carChkSvc === '1' && (
              <div
                className={
                  !!loginInfo?.permission && loginInfo?.permission === '2'
                    ? 'inspection-menu'
                    : ''
                }
              >
                <ItemLink
                  setCollapseOpen={setCollapseOpen}
                  nextPage={Routes.Answer}
                  content={ScreenLabels.Answer}
                  iconType={Icons.Home}
                  className="custom-font-size-link"
                />
                {!!loginInfo?.permission && (loginInfo?.permission === '2' || `${process.env.REACT_APP_ENV}` === 'dev' || `${process.env.REACT_APP_ENV}` === 'local') && (
                  <div
                    className={`${
                      location.pathname === '/answer'
                        ? 'menu-active'
                        : 'setting-icon'
                    }`}
                  >
                    <IconSetting
                      onClick={toggleInspectionPattern}
                      className="icon-setting"
                    />
                  </div>
                )}
              </div>
            )}
            {!!loginInfo?.alcoholChkSvc && loginInfo?.alcoholChkSvc === '1' && (
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AlcoholCheck}
                content={ScreenLabels.AlcoholCheck}
                iconType={Icons.Alcohol}
                className="custom-font-size-link"
              />
            )}
            <div className="hr" />
            <div className="wrap-period">
              <span className="title-period">登録日</span>
              <div>
                <div className="mt-1 mb-2">
                  <div className="d-flex align-items-center  mr-3">
                    <label
                      className="form-custom-radio"
                      onChange={selectCurrentDay}
                    >
                      <input
                        type="radio"
                        checked={selectDay}
                        name="select_radio"
                        onChange={() => {
                          // console.log('onChange_current')
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <span
                      className="radio-label label-pointer mb--1 pleft-8"
                      onClick={selectCurrentDay}
                    >
                      本日分
                    </span>
                  </div>
                  <div className="d-flex mr-1 wrap-date-sidebar">
                    <div>
                      <label
                        className="form-custom-radio"
                        onChange={selectDateRange}
                      >
                        <input
                          type="radio"
                          checked={!selectDay}
                          name="select_radio"
                          onChange={() => {
                            // console.log('onChange_select_date')
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <Col className="pleft-8">
                      <span
                        className="label-radio-date label-pointer"
                        onClick={selectDateRange}
                      >
                        期間指定
                      </span>
                      <Input
                        type={getInputType(InputType.DATE)}
                        className="answer-input answer-calendar-input sidebar-input"
                        disabled={selectDay}
                        onChange={onChangeStartDate}
                        defaultValue={moment().format('YYYY-MM-DD')}
                        min={moment()
                          .subtract(13, 'month')
                          .format('YYYY-MM-DD')}
                        max={validateEnd.format('YYYY-MM-DD')}
                      />
                      <Input
                        type={getInputType(InputType.DATE)}
                        className="answer-input answer-calendar-input sidebar-input"
                        disabled={selectDay}
                        onChange={onChangeEndDate}
                        defaultValue={moment().format('YYYY-MM-DD')}
                        min={moment()
                          .subtract(13, 'month')
                          .format('YYYY-MM-DD')}
                        max={moment().format('YYYY-MM-DD')}
                      />
                    </Col>
                  </div>
                  <div className="wrap-input-search">
                    <span className="radio-label font-bold">検索文字列</span>
                    <div className="align-self-end">
                      <div className="list-button">
                        <Button
                          id="user_name"
                          onClick={() => onChangeSearchType('user_name')}
                          className={`button-filter ${
                            search.search_type === 'user_name'
                              ? 'active'
                              : 'not-active-button'
                          }`}
                        >
                          運転者
                        </Button>
                        <Button
                          id="car_name"
                          onClick={() => onChangeSearchType('car_name')}
                          className={`button-filter ${
                            search.search_type === 'car_name'
                              ? 'active'
                              : 'not-active-button'
                          }`}
                        >
                          車両
                        </Button>
                        <Button
                          id="user_soshiki"
                          onClick={() => onChangeSearchType('user_soshiki')}
                          className={`button-filter ${
                            search.search_type === 'user_soshiki'
                              ? 'active'
                              : 'not-active-button'
                          }`}
                        >
                          運転者 組織
                        </Button>
                        <Button
                          id="car_soshiki"
                          onClick={() => onChangeSearchType('car_soshiki')}
                          className={`button-filter ${
                            search.search_type === 'car_soshiki'
                              ? 'active'
                              : 'not-active-button'
                          }`}
                        >
                          車両 組織
                        </Button>
                      </div>

                      <div className="search-wrap">
                        <Input
                          type={getInputType(InputType.TEXT)}
                          style={{ width: '218px' }}
                          className="answer-input answer-input-search"
                          placeholder="検索"
                          onChange={onChangeSearchText}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="wrap-filter-conditions">
                    <span className="radio-label font-bold">絞り込み</span>
                    <div className="container-select">
                      <div className="wrap-select">
                        <span className="radio-label">点検有無</span>
                        <Select
                          options={check_data_options}
                          className="answer-select-input"
                          value={checkData}
                          onChange={(value) => value && setCheckData(value)}
                        />
                      </div>
                      <div className="wrap-select">
                        <span className="radio-label">点検結果</span>
                        <Select
                          options={
                            props.type === '2'
                              ? check_status_alcohol_options
                              : check_status_options
                          }
                          className="answer-select-input"
                          value={checkStatus}
                          onChange={(value) => value && setCheckStatus(value)}
                        />
                      </div>
                      <div className="wrap-select">
                        <span className="radio-label">
                          {location.pathname === '/answer'
                            ? '確認状態'
                            : location.pathname === '/alcohol_check'
                            ? '承認状態'
                            : ''}
                        </span>
                        <Select
                          options={check_log_options}
                          className="answer-select-input"
                          value={checkLog}
                          onChange={(value) => value && setCheckLog(value)}
                        />
                      </div>

                      {location.pathname === '/answer'
                        ?
                        <div className="wrap-select-inspection-pattern">
                          <span className="radio-label">点検パターン</span>
                        </div>
                        :''}
                        
                      {location.pathname === '/answer'
                        ?
                        <div className="wrap-select">
                          <Select
                            options={inspectionPatternNameOption}
                            className="answer-select-input-inspection-pattern"
                            value={checkInspectionPattern}
                            onChange={(value) => value && setInspectionPattern(value)}
                          />
                        </div>
                        :''}

                    </div>
                  </div>
                  <Button
                    content="submit"
                    className="submit-search"
                    color="primary"
                    onClick={onClickFilter}
                  >
                    検索
                  </Button>
                </div>
              </div>
            </div>
            {/* <ItemLink */}
            {/*  setCollapseOpen={setCollapseOpen} */}
            {/*  nextPage={Routes.User} */}
            {/*  content={ScreenLabels.User} */}
            {/*  iconType={Icons.Customer} */}
            {/* /> */}
            {/* <ItemLink */}
            {/*  setCollapseOpen={setCollapseOpen} */}
            {/*  nextPage={Routes.Vehicle} */}
            {/*  content={ScreenLabels.Vehicle} */}
            {/*  iconType={Icons.Help} */}
            {/* /> */}
            {/* <ItemLink */}
            {/*  setCollapseOpen={setCollapseOpen} */}
            {/*  nextPage={Routes.Permission} */}
            {/*  content={ScreenLabels.Permission} */}
            {/*  iconType={Icons.Customer} */}
            {/* /> */}
          </Nav>
        </div>
        {showInpsection && (
          <PreworkInspectionPattern onClose={toggleInspectionPattern} />
        )}
      </Container>
    </Navbar>
  )
}

export default Sidebar

const check_data_options = [
  {
    value: '3',
    label: '全て',
  },
  {
    value: '1',
    label: '点検通知あり',
  },
  {
    value: '0',
    label: '点検通知なし',
  },
]
const check_status_options = [
  {
    value: '3',
    label: '全て',
  },
  {
    value: '0',
    label: '〇',
  },
  {
    value: '1',
    label: '×',
  },
]
const check_status_alcohol_options = [
  {
    value: '3',
    label: '全て',
  },
  {
    value: '0',
    label: '〇',
  },
  {
    value: '1',
    label: '×',
  },
  {
    value: '2',
    label: '画像相違あり',
  },
]
const check_log_options = [
  {
    value: '3',
    label: '全て',
  },
  {
    value: '1',
    label: '済',
  },
  {
    value: '0',
    label: '未',
  },
]
