import { getCsv, post } from 'common/fetch'
import { AxiosResponse } from 'axios'
import {
  AchievementGetReq,
  AchievementGetRes,
  AchievementAlcGetRes,
  AchievementDetailGetRes,
  AchievementAlcDetailGetRes,
  AchievementDetailGetReq,
  AchievementGetCsvReq,
  AchievementGetCsvRes,
} from 'common/types/achievement'

const path = (): string => '/api/achievement-web-get'
const pathDetail = (): string => '/api/achievement-web-dtlget'
const pathCsv = (): string => '/api/achievement-web-get-csv'
const pathPdf = (): string => '/api/achievement-web-get-pdf'

export async function fetchAchievement(
  token: string,
  body: AchievementGetReq
): Promise<AchievementGetRes> {
  return await post(token, path(), body)
}
export async function fetchAchievementAlcohol(
  token: string,
  body: AchievementGetReq
): Promise<AchievementAlcGetRes> {
  return await post(token, path(), body)
}

export async function fetchAchievementDetail(
  token: string,
  body: AchievementDetailGetReq
): Promise<AchievementDetailGetRes | AchievementAlcDetailGetRes> {
  return await post(token, pathDetail(), body)
}

export async function fetchAchievementCsv(
  token: string,
  body: AchievementGetCsvReq
): Promise<AchievementGetCsvRes> {
  return await post(token, pathCsv(), body)
}

export async function fetchCsvDownLoad(
  token: string,
  path: string
): Promise<AxiosResponse<ArrayBuffer>> {
  return await getCsv(token, path)
}

export async function fetchAchievementPdf(
  token: string,
  body: AchievementGetCsvReq
): Promise<AchievementGetCsvRes> {
  return await post(token, pathPdf(), body)
}
