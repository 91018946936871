import { IStoreCache } from 'common/types/auth_provider'
import { CategoryFormArray } from 'common/types/form/category'
import ConfirmModal from 'components/atoms/Modal/ConfirmModal'
import CategoryListItem from 'components/molecules/Admin/InspectionItem/CategoryListItem'
import React, { useState, useEffect, useRef } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { Button, Form, ListGroup, Modal } from 'reactstrap'
import {
  initCategory,
  initCategoryArray,
  saveCategories,
} from 'services/admin/categoy'

type TCategoryModal = {
  isOpen: boolean
  storeCache: IStoreCache
  onToggle: () => void
}

const CategoryModal: React.VFC<TCategoryModal> = ({
  isOpen,
  storeCache,
  onToggle,
}: TCategoryModal) => {
  const { user, categories } = storeCache
  const { client_id } = user!
  const defaultValues = initCategoryArray(client_id, categories)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<CategoryFormArray>({ defaultValues })
  const { fields, append, remove } = useFieldArray({ name: 'items', control })
  const watchFieldArray = watch('items')
  const controlledFields = fields.map((field, index) => {
    return { ...field, ...watchFieldArray[index] }
  })

  const onToggleConfirmModal = () => {
    if (JSON.stringify(watchFieldArray) === JSON.stringify(defaultValues.items))
      onToggle()
    else setIsConfirmModalOpen(!isConfirmModalOpen)
  }
  const closeAllModal = () => {
    setIsConfirmModalOpen(false)
    onToggle()
    reset()
  }
  const save = (data: CategoryFormArray) => saveCategories(storeCache, data)

  const messagesEndRef = useRef<null | HTMLDivElement>(null)

  const scrollToBottom = () =>
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })

  useEffect(scrollToBottom, [controlledFields])

  return (
    <>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onAction={() => {
          setIsConfirmModalOpen(false)
          handleSubmit(save)()
        }}
        onToggle={closeAllModal}
        onClose={() => setIsConfirmModalOpen(!isConfirmModalOpen)}
      />
      <Modal
        className="modal-dialog-centered inspection-item-modal"
        size="lg"
        isOpen={isOpen}
        toggle={onToggleConfirmModal}
      >
        <div className="modal-body modal-body-inspection">
          <div className="text-right btn-close" onClick={onToggleConfirmModal}>
            &#x2715;
          </div>
          <div className="modal-body-header">
            <div className="modal-body-header-left">
              <span className="modal-body-header-title">
                カテゴリ追加・編集
              </span>
              <Button
                className="modal-body-header-add px-2 py-2 ml-4"
                type="button"
                onClick={() => append(initCategory(client_id))}
              >
                <span>+</span>新規カテゴリ追加
              </Button>
            </div>
          </div>
          <Form className="px-5 pt-4 pb-5 modal-form">
            <ListGroup className="list category-modal-list custom-scrollbar" flush>
              {controlledFields.map((field, index) => {
                return (
                  <CategoryListItem
                    index={index}
                    control={control}
                    errors={errors}
                    onRemove={() => remove(index)}
                    key={field.id}
                  />
                )
              })}
              <div ref={messagesEndRef} />
            </ListGroup>
            <div className="mt-5 d-flex justify-content-end btn-oper-wrap pl-3">
              <Button
                color="primary"
                type="button"
                onClick={onToggleConfirmModal}
              >
                閉じる
              </Button>
              <Button onClick={handleSubmit(save)} color="success">
                保存
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  )
}

export default CategoryModal
