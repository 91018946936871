import { IAuthProvider, IStoreCache } from 'common/types/auth_provider'
import { Category } from 'common/types/category'
import { Answers } from 'common/types/answer/answers'
import { InspectionItem } from 'common/types/inspection_item'
import { InspectionSnapshot } from 'common/types/inspection_snapshot'
import { User } from 'common/types/user'
import React, { useEffect, useState } from 'react'
import {
  fetchPageNateAnswer,
  fetchSearchAnswer,
  fetch as fetchAnswer,
} from 'repositories/answer'
import { fetch as fetchCategories } from 'repositories/category'
import { fetch as fetchInspectionItems } from 'repositories/inspection_item'
import { fetch as fetchInspectionPatterns } from 'repositories/inspection_pattern'
import { fetch as fetchInspectionSnapshots } from 'repositories/inspection_snapshot'
import { fetch as fetchUsers, fetchMe } from 'repositories/user'
import { fetch as fetchVehicles, getCarList } from 'repositories/vehicle'
import { InspectionPattern } from 'common/types/inspection_patterns'
import { Vehicle } from 'common/types/vehicle'
import { SearchForm } from 'common/types/form/Answer/search'
import moment from 'moment'
import { Car } from 'common/types/prework_provider'
import { useLocation } from 'react-router'

export const AuthContext = React.createContext<IAuthProvider>({
  storeCache: {
    isLoading: true,
    token: '',
    user: null,
    users: [],
    vehicles: [],
    categories: [],
    inspectionPatterns: [],
    inspectionItems: [],
    inspectionSnapshots: [],
    answers: null,
    setStoreCache: null,
    loginInfo: null,
  },
  todayCar: [],
})

export const AuthProvider = ({ children }: { children: any }): JSX.Element => {
  const location = useLocation()
  const [storeCache, setStoreCache] = useState<IStoreCache>({
    isLoading: true,
    token: '',
    user: null,
    users: [],
    vehicles: [],
    categories: [],
    inspectionPatterns: [],
    inspectionItems: [],
    inspectionSnapshots: [],
    answers: null,
    setStoreCache: null,
    loginInfo: null,
  })
  const [todayCar, setTodayCar] = useState<Car[]>([])

  useEffect(() => {
    ;(async () => {
      const cache = await getCache(setStoreCache)
      setStoreCache(cache)
    })()
  }, [setStoreCache])

  useEffect(() => {
    const loadData = async () => {
      if (
        storeCache.loginInfo?.token &&
        (location.pathname === '/answer' ||
          location.pathname === '/alcohol_check')
      ) {
        const res = await getCarList(storeCache.token, {
          kaisyaCode: storeCache.loginInfo?.kaisyaCode || '',
          ncsUserId: storeCache.loginInfo?.ncsUserId || '',
          keyword: '',
          checkDate: moment().format('YYYYMMDDTHHmmss+0900'),
        })
        setTodayCar(res.carList)
      }
    }
    loadData()
  }, [storeCache.loginInfo, location])

  return (
    <AuthContext.Provider value={{ storeCache, todayCar }}>
      {children}
    </AuthContext.Provider>
  )
}

const getCache = async (
  setStoreCache: React.Dispatch<React.SetStateAction<IStoreCache>>
): Promise<IStoreCache> => {
  try {
    let token = localStorage.getItem('token')
    if (!token) throw new Error()

    const loginInfo = JSON.parse(String(localStorage.getItem('loginInfo')))

    const user: User | null = loginInfo

    const users: User[] = []
    const vehicles: Vehicle[] = []
    const categories: Category[] = []
    const inspectionPatterns: InspectionPattern[] = []
    const inspectionItems: InspectionItem[] = []
    const inspectionSnapshots: InspectionSnapshot[] = []
    const answers: Answers | null = null

    return {
      isLoading: false,
      token,
      user,
      users,
      vehicles,
      categories,
      inspectionPatterns,
      inspectionItems,
      inspectionSnapshots,
      answers,
      setStoreCache,
      loginInfo,
    }
  } catch (error) {
    return {
      isLoading: false,
      token: '',
      user: null,
      users: [],
      vehicles: [],
      categories: [],
      inspectionPatterns: [],
      inspectionItems: [],
      inspectionSnapshots: [],
      answers: null,
      setStoreCache,
      loginInfo: null,
    }
  }
}

const hasTokenAndUser = (token: string, user: User | null) => token && user

export async function reloadCachedToken(storeCache: IStoreCache) {
  const token: string = localStorage.getItem('token') ?? ''
  storeCache.setStoreCache?.({ ...storeCache, token })
}

export async function reloadCachedCategories(storeCache: IStoreCache) {
  const { token, user, setStoreCache } = storeCache
  const categories: Category[] = hasTokenAndUser(token, user)
    ? await fetchCategories(token, user!.client_id)
    : []
  setStoreCache?.({ ...storeCache, categories })
}

export async function reloadCachedInspectionPatterns(storeCache: IStoreCache) {
  const { token, user, setStoreCache } = storeCache
  const inspectionPatterns: InspectionPattern[] = hasTokenAndUser(token, user)
    ? await fetchInspectionPatterns(token, user!.client_id)
    : []
  setStoreCache?.({ ...storeCache, inspectionPatterns })
}

export async function reloadCachedInspectionItems(storeCache: IStoreCache) {
  const { token, user, setStoreCache } = storeCache
  const inspectionItems: InspectionItem[] = hasTokenAndUser(token, user)
    ? await fetchInspectionItems(token, user!.client_id)
    : []
  setStoreCache?.({ ...storeCache, inspectionItems })
}

export async function searchAnswer(
  storeCache: IStoreCache,
  params: SearchForm
) {
  const { token, setStoreCache } = storeCache
  const answers: Answers | null = token
    ? await fetchSearchAnswer(token, params)
    : null
  setStoreCache?.({ ...storeCache, answers })
}

export async function pageNateAnswer(
  storeCache: IStoreCache,
  page: string | undefined
) {
  const { token, user, setStoreCache } = storeCache
  const { client_id } = user!
  const answers: Answers | null = token
    ? await fetchPageNateAnswer(token, page, client_id)
    : null
  setStoreCache?.({ ...storeCache, answers })
}
