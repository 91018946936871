import axios from 'axios'
import { get } from 'common/fetch'
import {
  LoginForm,
  LoginInfoReq,
  LoginFromDDReq,
  oneTimeTokenReq,
} from 'common/types/form/login'

/**
 * Get CSRF
 */
export async function getToken(data: LoginForm): Promise<void> {
  const headers = { withCredentials: true }
  // console.log('getToken')
  // console.log('headers: ' + headers)
  // console.log('data: ' + data)
  await axios.post<void>('/get-token', data, { headers })
}

/**
 * Init Sanctum CSRF
 */
export async function sanctumCSRF(): Promise<void> {
  const headers = { withCredentials: true }
  await axios.get<void>('/sanctum/csrf-cookie', { headers })
}

/**
 * Login User
 */
export async function login(data: LoginForm): Promise<any> {
  const res = await axios.get(`${process.env.REACT_APP_DD_URL}/ncsdd/`, {
    withCredentials: true,
  })
  const parser = new DOMParser()
  const htmlDoc = parser.parseFromString(res.data, 'text/html')
  const input = htmlDoc.getElementsByName('_csrf')
  const csrf =
    input[0].attributes.item(2)?.value || input[0].attributes.item(1)?.value
  var formdata = new URLSearchParams()
  formdata.append('companyCode', data.company_code)
  formdata.append('userID', data.ncs_user_id)
  formdata.append('password', data.password)
  formdata.append('_csrf', String(csrf))

  const requestOptions: any = {
    method: 'POST',
    body: formdata,
    redirect: 'follow',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  }

  return fetch(
    `${process.env.REACT_APP_API_URL}/ncsdd/SYRA/authentication`,
    requestOptions
  )
    .then((result) => result)
    .catch((error) => console.log('error', error))
}

export async function oneTimeToken(data: oneTimeTokenReq): Promise<any> {
  // Convert One-Time-Token
  var formdata = new URLSearchParams()
  formdata.append('token', data.token)
  // console.log('OneTokenに送る内容こちら')
  // console.log(formdata)

  return axios
    .post(`${process.env.REACT_APP_API_URL_2}/useOneTimeToken`, formdata)
    .then((result) => result)
    .catch((error) => console.log('error', error))
}

export async function loginFromDD(data: LoginFromDDReq): Promise<any> {
  const headers = { withCredentials: true }
  return axios
    .post(`${process.env.REACT_APP_API_URL_2}/api/openReportChk`, data, headers)
    .then((result) => result)
    .catch((error) => console.log('error', error))
}

export async function getLoginInfo(data: LoginInfoReq): Promise<any> {
  const headers = { withCredentials: true }
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/ncsdd/rest/v3.0/DACK/DACK010100/getLoginInfo/ZDC`,
      data,
      headers
    )
    .then((result) => result)
    .catch((error) => {
      console.log('error', String(error))
      if (String(error).includes('500')) {
        return {
          data: {
            error: true,
          },
        }
      }
    })
}
