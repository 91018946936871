import { Labels, ScreenLabels } from 'common/enums'
import { dateFormat } from 'common/time'
import { VehicleTable } from 'common/types/table/vehicle'
import { Vehicle } from 'common/types/vehicle'
import { null2str } from 'common/utils'
import LeftBlue from 'components/atoms/BorderLine/LeftBlue'
import StandardTable from 'components/atoms/Table/StandardTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useMemo } from 'react'
import { useTable, useSortBy, Column } from 'react-table'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'

const TableCard: React.VFC = () => {
  const { vehicles } = useContext(AuthContext).storeCache
  const columns: Column<VehicleTable>[] = useMemo(
    () => [
      {
        Header: Labels.CompanyCODE,
        accessor: 'company_code',
      },
      {
        Header: Labels.VehicleCODE,
        accessor: 'vehicle_code',
      },
      {
        Header: Labels.VehicleNameBefore,
        accessor: 'vehicle_name_before',
      },
      {
        Header: Labels.OldOrganization1,
        accessor: 'old_organization_1',
      },
      {
        Header: Labels.OldOrganization2,
        accessor: 'old_organization_2',
      },
      {
        Header: Labels.OldOrganization3,
        accessor: 'old_organization_3',
      },
      {
        Header: Labels.OldOrganization4,
        accessor: 'old_organization_4',
      },
      {
        Header: Labels.OldOrganization5,
        accessor: 'old_organization_5',
      },
      {
        Header: Labels.OldOrganization6,
        accessor: 'old_organization_6',
      },
      {
        Header: Labels.VehicleNameAfter,
        accessor: 'vehicle_name_after',
      },
      {
        Header: Labels.NewOrganization1,
        accessor: 'new_organization_1',
      },
      {
        Header: Labels.NewOrganization2,
        accessor: 'new_organization_2',
      },
      {
        Header: Labels.NewOrganization3,
        accessor: 'new_organization_3',
      },
      {
        Header: Labels.NewOrganization4,
        accessor: 'new_organization_4',
      },
      {
        Header: Labels.NewOrganization5,
        accessor: 'new_organization_5',
      },
      {
        Header: Labels.NewOrganization6,
        accessor: 'new_organization_6',
      },
      {
        Header: Labels.SwitchingAt,
        accessor: 'switching_at',
      },
      {
        Header: Labels.UseStartAt,
        accessor: 'use_start_at',
      },
      {
        Header: Labels.UseEndAt,
        accessor: 'use_end_at',
      },
    ],
    []
  )
  const data: VehicleTable[] = useMemo(
    () =>
      vehicles.map((v: Vehicle) => {
        return {
          company_code: v.client.company_code,
          vehicle_code: v.vehicle_code,
          vehicle_name_before: null2str(v.vehicle_name_before),
          old_organization_1: null2str(v.old_organization_1),
          old_organization_2: null2str(v.old_organization_2),
          old_organization_3: null2str(v.old_organization_3),
          old_organization_4: null2str(v.old_organization_4),
          old_organization_5: null2str(v.old_organization_5),
          old_organization_6: null2str(v.old_organization_6),
          vehicle_name_after: null2str(v.vehicle_name_after),
          new_organization_1: null2str(v.new_organization_1),
          new_organization_2: null2str(v.new_organization_2),
          new_organization_3: null2str(v.new_organization_3),
          new_organization_4: null2str(v.new_organization_4),
          new_organization_5: null2str(v.new_organization_5),
          new_organization_6: null2str(v.new_organization_6),
          switching_at: dateFormat(v.switching_at),
          use_start_at: v.use_start_at ? dateFormat(v.use_start_at) : '',
          use_end_at: v.use_end_at ? dateFormat(v.use_end_at) : '',
        }
      }),
    [vehicles]
  )
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<VehicleTable>({ columns, data }, useSortBy)

  return (
    <div className="main-content">
      <Container className="mt-3">
        <Row className="justify-content-center">
          <Col lg="12" md="12">
            <LeftBlue content={ScreenLabels.Vehicle} />
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <StandardTable
                  getTableProps={getTableProps}
                  getTableBodyProps={getTableBodyProps}
                  headerGroups={headerGroups}
                  rows={rows}
                  prepareRow={prepareRow}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default TableCard
