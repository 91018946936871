import { InspectionItem } from 'common/types/inspection_item'
import { Labels } from 'common/enums'
import Display4 from 'components/atoms/Span/Display4'
import PatternCard from 'components/organisms/Admin/InspectionCheckSheet/PatternCard'
import TableBody from 'components/organisms/Admin/InspectionCheckSheet/TableBody'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'

const InspectionCheckSheet: React.VFC = () => {
  const { storeCache } = useContext(AuthContext)
  const [inspectionItemsObj, setInspectionItems] = useState<InspectionItem[]>(
    []
  )
  return (
    <div className="main-content">
      <Container className="mt-3">
        <Row className="justify-content-center">
          <Col lg="12" md="12">
            <Card className="border-0">
              <CardBody className="pl-lg-4 pr-lg-3 py-lg-0">
                <Display4 content={Labels.InspectionPatternList} />
                <Row className="mt-4">
                  <PatternCard
                    storeCache={storeCache}
                    setInspectionItems={setInspectionItems}
                  />
                  <TableBody
                    categories={storeCache.categories}
                    inspectionItems={inspectionItemsObj}
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default InspectionCheckSheet
