import React, { useMemo } from 'react'
import { Column, useTable } from 'react-table'
import { Labels } from 'common/enums'
import { InspectionItems } from 'common/types/achievement'
import RowTableDetail from './RowTableDetail'

type Props = {
  inspectionItems?: InspectionItems[]
}

const TableDetail: React.VFC<Props> = ({ inspectionItems }) => {
  const columns: Column<any>[] = useMemo(
    () => [
      {
        Header: Labels.InspectionItem,
        accessor: 'item_content',
      },
      {
        Header: Labels.ImplementResult,
        accessor: 'item_result',
      },
    ],
    []
  )

  const { headerGroups, rows, prepareRow } = useTable<any>({
    columns,
    data: inspectionItems || [],
  })

  return (
    <>
      <div className="wrap-header-table-detail">
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map(
            (header, index) =>
              index >= 0 && (
                <div
                  className={`${
                    index === 0
                      ? 'detail-inspection-content'
                      : 'detail-inspection-result'
                  }`}
                  key={index}
                >
                  {index === 0 ? (
                    <div className="content-header">
                      {header.render('Header')}
                    </div>
                  ) : (
                    header.render('Header')
                  )}
                </div>
              )
          )
        )}
      </div>
      <div className="table-prework-detail custom-scrollbar">
        {rows.map((row, i: number) => {
          prepareRow(row)
          if (
            row.original.item_content === '燃料' ||
            row.original.item_content === '充電量' ||
            row.original.item_content === '総走行距離(km)' ||
            row.original.item_content === '総走行距離(オドメータ値）Km'
          ) {
            return null
          }
          return row && <RowTableDetail displayAnswer={row} key={i} />
        })}
      </div>
    </>
  )
}
export default TableDetail
