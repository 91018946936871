import { Labels } from 'common/enums'
import { CategoryError, CategoryFormArray } from 'common/types/form/category'
import { createRequiredMessage } from 'common/utils'
import Feedback from 'components/atoms/Feedback/Feedback'
import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { Col, Input, ListGroupItem, Row } from 'reactstrap'
import { errorField } from 'services/admin/categoy'

type TListItem = {
  index: number
  control: Control<CategoryFormArray, object> | undefined
  errors: CategoryError
  onRemove: () => void
}

const CategoryListItem: React.VFC<TListItem> = (props: TListItem) => {
  const { index, control, errors, onRemove } = props
  return (
    <ListGroupItem className="px-0">
      <Row className="align-items-start">
        <Col sm={8}>
          <Controller
            name={`items.${index}.name`}
            control={control}
            rules={{
              required: createRequiredMessage(Labels.Category),
            }}
            render={({ field }) => (
              <Input
                type="text"
                className={`form-control-flush ${
                  errorField(errors, index, 'name') ? 'is-invalid' : ''
                }`}
                placeholder={Labels.Category}
                {...field}
              />
            )}
          />
          <Feedback error={errorField(errors, index, 'name')} />
        </Col>
        <Col sm={3} className="pl-0 item-color">
          <Controller
            name={`items.${index}.color`}
            control={control}
            render={({ field }) => (
              <Input
                type="color"
                className="form-control-flush"
                placeholder={Labels.CategoryColor}
                {...field}
              />
            )}
          />
        </Col>
        <Col sm={1}>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={onRemove}
          >
            <span aria-hidden>&#x2715;</span>
          </button>
        </Col>
      </Row>
    </ListGroupItem>
  )
}

export default CategoryListItem
