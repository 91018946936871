import Select from 'react-select'
import { Labels } from 'common/enums'
import AlcoholTable from 'components/atoms/Table/AlcoholTable'
import PdfImg from 'components/atoms/Img/PdfImg'
import SvgImg from 'components/atoms/Img/SvgImg'
import React, { useContext, useMemo, useState } from 'react'
import { useTable, useSortBy, Column } from 'react-table'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'
import 'styles/alcohol.scss'
import AlcoholCheckDetailModal from 'components/atoms/Modal/AlcoholCheckDetail'
import { IAlcoholProvider } from 'common/types/alcohol_provider'
import { AlcoholContext } from 'providers/AlcoholProvider'
import { IStoreCache } from 'common/types/auth_provider'
import { AuthContext } from 'providers/AuthProvider'
import moment from 'moment'
import { createAchievementsAlcohol } from 'services/admin/achievement-create'
import { updateArchievementAlc } from 'services/admin/archievement-update'
import { fetchAchievementDetail } from 'repositories/achievenment'
import {
  AchievementAlcDetailGetRes,
  AchievementAlc,
  AchievementAlcRegisterParam,
} from 'common/types/achievement'
import LoadingIndicator from 'components/atoms/LoadingIndicator'
import { AchievementUpdate } from 'common/types/archievement-update'
import Pagination from 'components/atoms/Pagination'
import { alertService } from 'services/alert'
import { customModalService } from 'services/customModal'
import AlcoholAchievementRegisterModal from 'components/organisms/Admin/AchievementRegister/AlcoholAchivementRegisterModal'
import IconAdd from 'components/atoms/Img/IconAdd'
import { getLoginInfo } from 'repositories/login'

const TableCard: React.VFC = () => {
  const { loginInfo }: IStoreCache = useContext(AuthContext).storeCache
  const {
    totalPage,
    totalRecord,
    currentPage,
    limit,
    onChangePage,
    onChangeLimit,
    editData,
    onUpdateSuccess,
    editedData,
    scopeCar,
    downLoadCsv,
    registerAchievements,
    registerAchievementRows,
    filters,
    downLoadPdf,
    successRegisteredAchievementAlcRows,
    failedRegisteredAchievementAlcRows,
    resetRegisterAchievements,
    resetRegisterItems,
    changeIsAllFailed,
  }: IAlcoholProvider = useContext(AlcoholContext)
  const [isShowDetail, setIsShowDetail] = useState<boolean>(false)
  const pageArr = useMemo(
    () => Array.from(Array(totalPage + 2).keys()),
    [totalPage]
  )

  const [dataDetail, setDataDetail] = useState<AchievementAlcDetailGetRes>()
  const [loadingDetail, setLoadingDetail] = useState<boolean>(false)
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false)
  const [isShowAchievementRegister, setIsShowAchievementRegister] = useState<boolean>(false)

  const toggleAchievementRegister = () => {
    if (editedData?.length > 0) {
      alertService.show(
        false,
        '変更データが未登録です。先にページ登録をおこなってください'
      )
      return
    }
    if (registerAchievements.length >= limit) {
      alertService.show(
        false,
        '追加可能な件数を越えるため、先にページ登録をおこなってください'
      )
      return
    }
    resetRegisterItems()
    setIsShowAchievementRegister((prev) => !prev)
  }

  const onToggleDetail = async (params?: AchievementAlc) => {
    if (!isShowDetail) {
      setLoadingDetail(true)
      // console.log('onToggleDetail params')
      // console.log(params)
      try {
        const data = (await fetchAchievementDetail(loginInfo?.token || '', {
          kaisya_code: loginInfo?.kaisyaCode || '',
          ncs_user_id: loginInfo?.ncsUserId || '',
          achievement_id: String(params?.achievement_id),
          user_soshiki_name: params?.user_soshiki_name || '',
          car_soshiki_name: params?.car_soshiki_name || '',
          user_name: params?.user_name,
          car_name: params?.car_name || '',
          assets_id: params?.assets_id || '',
          alc_checklogs_id: params?.alc_checklogs_id || '',
          checklogs_user_name: params?.alc_checklogs_shonin_user_name || '',
        })) as AchievementAlcDetailGetRes
        setLoadingDetail(false)
        if (String(data.status) === '1') {
          if (data?.achievements?.has_s3_error_msg) {
            alertService.show(
              false,
              String(
                'システムエラーが発生しました。しばらく時間をおいてから再アクセスするか、お問い合わせください。（詳細画像表示）'
              )
            )
          }
          setDataDetail(data)
          setIsShowDetail(true)
        } else {
          alertService.show(
            false,
            'システムエラーが発生しました。しばらく時間をおいてから再アクセスするか、お問い合わせください。（詳細情報表示）'
          )
        }
      } catch (error) {
        setLoadingDetail(false)
        alertService.show(
          false,
          'システムエラーが発生しました。しばらく時間をおいてから再アクセスするか、お問い合わせください。（詳細取得）'
        )
      }
    } else {
      setIsShowDetail(false)
    }
  }

  const pdfdata = async (): Promise<void> => {}

  const { headerGroups, rows, prepareRow } = useTable<any>(
    { columns, data: editData },
    useSortBy
  )

  const convertEditData = () => {
    const arr: AchievementUpdate[] = []

    editedData.forEach((item) => {
      if (item.achievement_id && item.confirm_date) {
        const achievement: any = {
          achievement_type: 'アルコールチェック',
          achievement_id: String(item.achievement_id),
          car: {
            drive_recorder_id: item?.drive_recorder_id,
            car_name: item?.car_name,
          },
          inspection_check_logs: {
            log_type: 1,
            updated_at: moment().format('YYYYMMDDHHmmss'),
            ncs_user_id: loginInfo?.ncsUserId,
            ncs_user_name: loginInfo?.ncsUserName,
            data_from: 'Web',
            shuki_status: item?.alc_checklogs_status_shuki ? 1 : 0,
            check_status: item?.alc_checklogs_status_shonin ? 1 : 0,
            description: item?.alc_description,
          },
        }
        if (!item.drive_recorder_id) {
          const cars = scopeCar?.filter((e) => e.name === item?.car_name)
          var result =
            cars?.length > 0
              ? cars?.reduce(function (res, obj) {
                  return moment(obj.startDate).unix() <
                    moment(res.startDate).unix()
                    ? obj
                    : res
                })
              : null
          achievement.car.drive_recorder_id =
            (result && result?.driveRecorderId) || 'unknown'
        }
        arr.push(achievement)
      }
    })
    return arr
  }

  const onClickRegister = () => {
    if (editedData?.length > 0) {
      customModalService.show(
        'このページに対して行った変更を登録しますか？',
        '',
        'はい',
        'いいえ',
        onRegister
      )
    } else if (registerAchievements?.length > 0) {
      customModalService.show(
        'このページに対して行った変更を登録しますか？',
        '',
        'はい',
        'いいえ',
        createAchievement
      )
    }
  }

  const onRegister = async () => {
    const data = convertEditData()
    const res = await updateArchievementAlc({
      achievements: data,
      token: loginInfo?.token || '',
      kaisya_code: loginInfo?.kaisyaCode || '',
      ncs_user_id: loginInfo?.ncsUserId || '',
    })
    if (res) {
      if (
        filters?.search_type === 'car_name' ||
        filters?.search_type === 'car_soshiki' ||
        filters.check_log !== '3'
      ) {
        alertService.show(
          true,
          <>
            表示中のデータを登録しました。
            <br />
            検索ボタンを押すと検索条件に従って再表示します。
          </>
        )
      } else {
        alertService.show(true, '表示中のデータを登録しました。')
      }
      onUpdateSuccess()
    }
  }

  const createAchievement = async () => {
    setLoadingCreate(true)
    const token = loginInfo?.token || ''
    let success = true
    let suceedAchievementRows: AchievementAlc[] = []
    let failedAchievementRows: AchievementAlcRegisterParam[] = []
    await Promise.all(registerAchievements.map(async (achievement, key) => {
      const res = await createAchievementsAlcohol(token, achievement)
      const achievementRow = registerAchievementRows[key]
      if (achievementRow && res.status == '1') {
        achievementRow.achievement_id = res.message
        achievementRow.reg_date = moment().format('YYYY/MM/DD HH:mm')
        suceedAchievementRows.push(achievementRow)
      } else {
        success = false
        failedAchievementRows.push(achievement)
      }
      return true
    }))
    if (success) {
      changeIsAllFailed(false)
      successRegisteredAchievementAlcRows(suceedAchievementRows)
      alertService.show(
        true,
        <>
          表示中のデータを登録しました。
          <br />
          検索ボタンを押すと検索条件に従って再表示します。
        </>
      )
    } else {
      changeIsAllFailed(suceedAchievementRows.length <= 0)
      failedRegisteredAchievementAlcRows(failedAchievementRows)
      if (suceedAchievementRows.length > 0) {
        successRegisteredAchievementAlcRows(suceedAchievementRows)
      }
      changeIsAllFailed(false)
      alertService.show(
        false,
        'システムエラーが発生しました。しばらく時間をおいてから再アクセスするか、お問い合わせください。（実績登録）'
      )
    }
    setLoadingCreate(false)
  }

  const onChangeLimitValue = (value: any) => {
    if (editedData.length > 0 || registerAchievements?.length > 0) {
      customModalService.show(
        '変更データが未登録ですがよろしいですか？',
        '',
        'はい',
        'いいえ',
        () => {
          resetRegisterAchievements()
          value && onChangeLimit(value.value)
        }
      )
    } else {
      value && onChangeLimit(value.value)
    }
  }

  const onChangePageValue = (page: number) => {
    if (page === currentPage || page === 0 || page === pageArr.length - 1) {
      return
    }
    if (editedData.length > 0 || registerAchievements?.length > 0) {
      customModalService.show(
        '変更データが未登録ですがよろしいですか？',
        '',
        'はい',
        'いいえ',
        () => {
          resetRegisterAchievements()
          onChangePage(page)
        }
      )
    } else {
      onChangePage(page)
    }
  }

  const onClickCsv = () => {
    if (editedData.length > 0 || registerAchievements?.length > 0) {
      customModalService.show(
        '変更データが未登録ですがよろしいですか？',
        '',
        'はい',
        'いいえ',
        downLoadCsv
      )
    } else {
      downLoadCsv()
    }
  }

  const onClickPdf = () => {
    if (editedData.length > 0 || registerAchievements?.length > 0) {
      customModalService.show(
        '変更データが未登録ですがよろしいですか？',
        '',
        'はい',
        'いいえ',
        downLoadPdf
      )
    } else {
      downLoadPdf()
    }
  }

  return (
    <div className="table-main-content">
      {(loadingDetail || loadingCreate) && <LoadingIndicator />}
      <div className="px-lg-3 pb-1">
        <Row className="justify-content-center">
          <Col lg="12" md="12">
            <div className="wrap-title-table">
              <div className="wrap-button-title">
                <div className="button-header-container">
                  {/* <PdfImg
                    content="印刷（PDF）"
                    onClick={onClickPdf}
                    disable={editedData.length > 0}
                  /> */}
                  <SvgImg
                    content="CSVで保存"
                    onClick={onClickCsv}
                    disable={editedData.length > 0 || registerAchievements?.length > 0}
                  />
                  <button
                    type="button"
                    color="primary"
                    className={
                      `achievement-create-button ${loginInfo?.permission === '0' ? 'disabled' : ''}`
                    }
                    onClick={toggleAchievementRegister}
                    disabled={loginInfo?.permission === '0'}
                  >
                    <IconAdd />
                    <span className="add-title">実績登録</span>
                  </button>
                  <Button
                    color="primary"
                    className="button-register"
                    onClick={onClickRegister}
                    disabled={loginInfo?.permission === '0'}
                  >
                    ページ登録
                  </Button>
                </div>
              </div>
            </div>
            <Card className="mt-2 shadow border-0">
              <CardBody className="custom-card-body">
                <AlcoholTable
                  headerGroups={headerGroups}
                  rows={rows}
                  prepareRow={prepareRow}
                  toggleDetail={onToggleDetail}
                />
                <div className="wrap-paginate">
                  <div className="select-page">
                    <label className="mr-4 mb-0">1ページ表示件数</label>
                    <Select
                      options={pagesOptions}
                      className="answer-select-footer"
                      menuPlacement="top"
                      value={{ label: String(limit), value: limit }}
                      onChange={onChangeLimitValue}
                    />
                  </div>
                  <div className="total-item">
                    <span>
                      {`${
                        currentPage === 1
                          ? currentPage
                          : (currentPage - 1) * limit + 1
                      }-${
                        totalPage > currentPage
                          ? currentPage * limit
                          : totalRecord
                      }/
                      ${totalRecord}`}
                    </span>
                    <Pagination
                      currentPage={currentPage}
                      onPageChange={onChangePageValue}
                      pageSize={limit}
                      siblingCount={1}
                      totalCount={totalRecord}
                    />
                  </div>
                </div>
              </CardBody>
              <div className="daily-footer">
                <div className="wrap-text-footer">
                  <div className="text-footer-1">
                    <span
                      className="text-link"
                      onClick={() =>
                        window.open(`${process.env.REACT_APP_ZDC_API_URL}/download/kiyaku`)
                      }
                    >
                      NCSドライブドクター利用規約
                    </span>
                  </div>
                  <div className="text-footer-2">
                    <span
                      className="text-link"
                      onClick={() =>
                        window.open(`${process.env.REACT_APP_ZDC_API_URL}/download/privacy`)
                      }
                    >
                      プライバシーポリシー
                    </span>
                  </div>
                  <div className="text-footer-3">
                    copyright©2022 NIPPON CAR SOLUTIONS CO.,LTD
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <AlcoholCheckDetailModal
        open={isShowDetail}
        onClose={onToggleDetail}
        detailData={dataDetail?.achievements}
      />
      {isShowAchievementRegister && (
        <AlcoholAchievementRegisterModal
          onClose={toggleAchievementRegister}
        />
      )}
    </div>
  )
}

export default TableCard

const columns: Column<any>[] = [
  {
    Header: Labels.RegistedDate,
    accessor: 'reg_date',
  },
  {
    Header: Labels.DrivingUser,
    accessor: 'user_name',
  },
  {
    Header: Labels.DriverOrganization,
    accessor: 'user_soshiki_name',
  },
  {
    Header: Labels.VehicleNumber,
    accessor: 'car_name',
  },
  {
    Header: Labels.VehicleOrganization,
    accessor: 'car_soshiki_name',
  },
  {
    Header: Labels.ImplementDate,
    accessor: 'confirm_date',
  },
  {
    Header: Labels.ImplementCategory,
    accessor: 'implementation',
  },
  {
    Header: Labels.ImplementMethod,
    accessor: 'witness_kind',
  },
  {
    Header: Labels.CheckResult,
    accessor: 'alc_check_status',
  },
  {
    Header: Labels.FeverHealth,
    accessor: 'alc_check_condition_status',
  },
  {
    Header: Labels.CheckDrunkenness,
    accessor: 'alc_checklogs_status_shuki',
  },
  {
    Header: Labels.Checked,
    accessor: 'alc_checklogs_status_shonin',
  },
  {
    Header: Labels.Approver,
    accessor: 'alc_checklogs_shonin_user_name',
  },
  {
    Header: Labels.DriverComment,
    accessor: 'description',
  },
  {
    Header: Labels.Instructions,
    accessor: 'alc_description',
  },
]

const pagesOptions = [
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 300, label: '300' },
]
