import { Labels, ScreenLabels } from 'common/enums'
import { PermissionTable } from 'common/types/table/permission'
import LeftBlue from 'components/atoms/BorderLine/LeftBlue'
import StandardTable from 'components/atoms/Table/StandardTable'
import React, { useMemo } from 'react'
import { useTable, useSortBy, Column } from 'react-table'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'

const TableCard: React.VFC = () => {
  // const { vehicles } = useContext(AuthContext).storeCache
  const columns: Column<PermissionTable>[] = useMemo(
    () => [
      {
        Header: Labels.CompanyCODE,
        accessor: 'company_code',
      },
      {
        Header: Labels.PermissionContent,
        accessor: 'content',
      },
      {
        Header: Labels.AdminFlag,
        accessor: 'admin_flag',
      },
    ],
    []
  )
  const data: PermissionTable[] = useMemo(() => [], [])
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<PermissionTable>({ columns, data }, useSortBy)

  return (
    <div className="main-content">
      <Container className="mt-3">
        <Row className="justify-content-center">
          <Col lg="12" md="12">
            <LeftBlue content={ScreenLabels.Permission} />
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <StandardTable
                  getTableProps={getTableProps}
                  getTableBodyProps={getTableBodyProps}
                  headerGroups={headerGroups}
                  rows={rows}
                  prepareRow={prepareRow}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default TableCard
