import React from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import LeftBlue from 'components/atoms/BorderLine/LeftBlue'
import { Link } from 'react-router-dom'
import { Routes } from 'common/enums'

const NotFound: React.VFC = () => {
  return (
    <div className="main-content">
      <div className="header py-7 py-lg-8" />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="8" md="8">
            <LeftBlue content="404エラー" />
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <p>お探しのページは見つかりませんでした。</p>
                <Link to={Routes.Login}>ログイン画面へ</Link>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default NotFound
