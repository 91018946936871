import { Labels } from 'common/enums'
import { IStoreCache } from 'common/types/auth_provider'
import { Category } from 'common/types/category'
import {
  InspectionItemError,
  InspectionItemForm,
  InspectionItemFormArray,
} from 'common/types/form/inspection_item'
import { InspectionItem } from 'common/types/inspection_item'
import { InspectionItemTable } from 'common/types/table/inspection_item'
import { getErrorMessage } from 'common/utils'
import { reloadCachedInspectionItems } from 'providers/AuthProvider'
import { FieldError } from 'react-hook-form'
import { Column } from 'react-table'
import { save } from 'repositories/inspection_item'
import { alertService } from 'services/alert'

/**
 * 点検項目マトリックスヘッダー定義
 */
export function inspectionItemTableHeader(): Column<InspectionItemTable>[] {
  return [
    {
      Header: Labels.Category,
      accessor: 'category_color',
    },
    {
      Header: Labels.Category,
      accessor: 'category_name',
    },
    {
      Header: Labels.Content,
      accessor: 'content',
    },
    {
      Header: Labels.Detail,
      accessor: 'detail',
    },
  ]
}

/**
 * 点検項目マトリックスデータ描画
 */
export function inspectionItemTableBody(
  categories: Category[],
  inspectionItems: InspectionItem[]
): InspectionItemTable[] {
  return inspectionItems.map(({ id, category, content, detail }) => {
    const c = categories.find(({ id }) => id === category?.id)
    return {
      id,
      category_color: c ? c.color : '',
      category_name: c ? c.name : '',
      content,
      detail,
    }
  })
}

/**
 * 点検項目配列フォーム用初期値設定
 */
export function initInspectionItemArray(
  client_id: number,
  inspectionItems: InspectionItem[]
): InspectionItemFormArray {
  return {
    items: inspectionItems.map(({ id, category_id, content, detail }) => {
      return {
        inspection_item_id: id,
        client_id,
        category_id,
        content,
        detail,
      }
    }),
  }
}

/**
 * 点検項目初期値設定
 */
export function initInspectionItem(client_id: number): InspectionItemForm {
  return {
    inspection_item_id: 0,
    client_id,
    category_id: '',
    content: '',
    detail: '',
  }
}

/**
 * react-hook-form：エラーフィールド定義
 */
export function errorField(
  errors: InspectionItemError,
  index: number,
  field: 'category_id' | 'content' | 'detail'
): FieldError | undefined {
  if (
    errors.items === undefined ||
    errors.items[index] === undefined ||
    errors.items[index][field] === undefined
  )
    return undefined
  return errors.items[index][field]
}

/**
 * 点検項目を保存する
 */
export async function saveInspectionItem(
  storeCache: IStoreCache,
  data: InspectionItemFormArray
): Promise<void> {
  try {
    if (data.items.length === 0)
      throw new Error('カテゴリを一つ以上作成してください。')

    await save(storeCache.token, data)
    await reloadCachedInspectionItems(storeCache)

    alertService.show(true, '点検項目の保存が完了しました。')
  } catch (error) {
    getErrorMessage(error)
  }
}
