import { post } from 'common/fetch'
import {
  AchievementAlcRegisterParam,
  AchievementCreateRes,
} from 'common/types/achievement'

const path = (): string => '/api/achievement-web-create'

export async function createAchievementsAlc(
  token: string,
  body: AchievementAlcRegisterParam
): Promise<AchievementCreateRes> {
  return await post(token, path(), body)
}
