import React, { useState, useEffect } from 'react'
import { Routes } from 'common/enums'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'
import { useHistory } from 'react-router'
import { InspectionPattern } from 'common/types/inspection_patterns'
import { InspectionItem } from 'common/types/inspection_item'
import {
  onClick as serviceOnClick,
  onRemove as serviceOnRemove,
} from 'services/admin/inspection_check_sheet'
import { IStoreCache } from 'common/types/auth_provider'

type TPatternCard = {
  storeCache: IStoreCache
  setInspectionItems: React.Dispatch<React.SetStateAction<InspectionItem[]>>
}

const PatternCard: React.VFC<TPatternCard> = ({
  storeCache,
  setInspectionItems,
}: TPatternCard) => {
  const { inspectionPatterns, inspectionItems } = storeCache
  const [activeCard, setActiveCard] = useState<number>(1)
  const { push } = useHistory()
  const onClick = (inspectionPattern: InspectionPattern) =>
    serviceOnClick(
      inspectionPattern,
      inspectionItems,
      setInspectionItems,
      setActiveCard
    )
  const onRemove = (inspectionPattern: InspectionPattern) =>
    serviceOnRemove(storeCache, inspectionPattern)

  useEffect(() => {
    if (inspectionPatterns.length > 0) onClick(inspectionPatterns[0])
  }, [])

  return (
    <Col>
      {inspectionPatterns.map((i, index) => {
        return (
          <Card
            className={'check-card ' + (activeCard === i.id ? 'active' : '')}
            key={index}
            onClick={() => onClick(i)}
          >
            <CardBody className="p-2">
              <Row>
                <Col>
                  <div className="check-card-title mt-2 ml-2">{i.name}</div>
                </Col>
                <Col className="col-auto">
                  <button
                    className="btn-close close icon icon-shape bg-white rounded-circle shadow"
                    onClick={() => onRemove(i)}
                    aria-label="Close"
                    data-dismiss="modal"
                    type="button"
                  >
                    &#x2715;
                  </button>
                </Col>
              </Row>
              <div className="mt-2 ml-2 d-flex justify-content-between align-items-center">
                点検項目数：{i.inspection_items.length}件
                <Button
                  color="success"
                  className="check-card-btn"
                  type="button"
                  size="sm"
                  onClick={() =>
                    push(Routes.InspectionCheckSheetEdit, {
                      inspectionPattern: i,
                    })
                  }
                >
                  編集
                </Button>
              </div>
            </CardBody>
          </Card>
        )
      })}
      <div className="text-center">
        <Button
          className="mt-4 card-add-btn p-0 border-0"
          color="info"
          type="button"
          onClick={() => push(Routes.InspectionCheckSheetCreate)}
        >
          +
        </Button>
      </div>
    </Col>
  )
}

export default PatternCard
