import { getCsv, post } from 'common/fetch'
import { AxiosResponse } from 'axios'
import {
  InspectionPatternNamesReq,
  InspectionPatternNamesRes,
} from 'common/types/inspection_pattern_names'

const pathInspectionPatternName = (): string => '/api/inspection-pattern-name-web-get'

// 点検パターン名を取得
export async function fetchInspectionPatternName(
  token: string,
  body: InspectionPatternNamesReq
): Promise<InspectionPatternNamesRes> {
  return await post(token, pathInspectionPatternName(), body)
}

