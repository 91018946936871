export enum Routes {
  Login = '/login',
  Logout = '/logout',
  Answer = '/answer',
  AnswerDetail = '/answer_detail/:id',
  InspectionCheckSheet = '/inspection_check_sheet',
  InspectionCheckSheetCreate = '/inspection_check_sheet/create',
  InspectionCheckSheetEdit = '/inspection_check_sheet/edit',
  InspectionItem = '/inspection_item',
  User = '/user',
  Vehicle = '/vehicle',
  Permission = '/permission',
  AlcoholCheck = '/alcohol_check',
  WaitingLoginDD = '/waiting_login_dd',
}

export enum ViewType {
  LOGIN,
  LOADING,
  VISIBLE,
  USER,
}

export enum AlertType {
  SUCCESS,
  DANGER,
}

export enum InputType {
  TEXT,
  PASSWORD,
  DATE,
  CHECKBOX,
}

export enum Labels {
  GoBack = '戻る',
  Login = 'ログイン',
  Save = '保存',
  Create = '新規登録',
  Edit = '編集',
  Delete = '削除',
  CompanyCODE = '会社コード',
  ID = 'ID',
  NcsUserID = 'NcsUserID',
  VehicleCODE = '車載ID',
  UserName = '氏名',
  ICCard = 'ICカード情報',
  Email = 'メールアドレス',
  LoginID = 'ログインID',
  Password = 'パスワード',
  PasswordConfirm = 'パスワード（確認）',
  PermissionContent = '業務コンテンツ',
  AdminFlag = '管理者フラグ',
  OldPermissionName = '切替前業務権限名',
  VehicleNameBefore = '切替前車両番号',
  OldOrganization1 = '切替前組織1',
  OldOrganization2 = '切替前組織2',
  OldOrganization3 = '切替前組織3',
  OldOrganization4 = '切替前組織4',
  OldOrganization5 = '切替前組織5',
  OldOrganization6 = '切替前組織6',
  NewPermissionName = '切替後業務権限名',
  VehicleNameAfter = '切替後車両番号',
  NewOrganization1 = '切替前組織1',
  NewOrganization2 = '切替前組織2',
  NewOrganization3 = '切替前組織3',
  NewOrganization4 = '切替前組織4',
  NewOrganization5 = '切替前組織5',
  NewOrganization6 = '切替前組織6',
  SwitchingAt = '切替日時',
  UseStartAt = '利用開始年月日',
  UseEndAt = '利用終了年月日',
  InspectionAt = '報告日時',
  InspectionPattern = '点検パターン',
  InspectionItem = '点検項目',
  InspectionItemList = '点検項目一覧',
  InspectionItemEdit = '点検項目編集',
  InspectionItemAddEdit = '新規点検項目追加・編集',
  InspectionPatternList = '点検パターン一覧',
  CategoryEdit = 'カテゴリ編集',
  DrivingStatus = '運転開始/終了',
  InspectionApprovalStatus = '点検承認',
  DrivingUser = '運転者',
  Administrator = '管理者',
  InspectionPatternName = '点検パターン名',
  Category = 'カテゴリ',
  CategoryName = 'カテゴリ名',
  CategoryColor = 'カテゴリ色',
  Content = '内容',
  Detail = '詳細',
  StartWorkAlcCheck = 'ALCチェック-始業',
  EndWorkAlcCheck = 'ALCチェック-終業',
  Inspection = '点検',
  ImplementResult = '点検\n結果',
  RegistedDate = '登録日時',
  DriverOrganization = '運転者組織',
  VehicleNumber = '車両番号',
  VehicleOrganization = '車両組織',
  ImplementDate = '実施日時',
  ImplementCategory = '実施区分',
  ImplementMethod = '実施方法',
  CheckResult = 'チェック結果',
  FeverHealth = '発熱/',
  CheckDrunkenness = '酒気帯',
  Checked = '承認',
  Approver = '承認者',
  DriverComment = '運転者コメント',
  Instructions = '指示事項／',
  Confirmation = '確認',
  Confirmer = '確認者',
  // No.16 対応 ヘッダーに「燃料」「充電量」を追加
  Fuel = '燃料',
  Battery = '充電\n量',
}

export enum ScreenLabels {
  Answer = '始業前点検実績一覧',
  InspectionCheckSheet = '点検パターン',
  AnswerDetail = '実績詳細',
  InspectionItem = '点検項目設定',
  User = 'ユーザマスタ',
  Vehicle = '車両マスタ',
  Permission = '権限マスタ',
  AlcoholCheck = 'アルコールチェック実績一覧',
}

export enum Icons {
  Home = '/assets/icons/icon_home.png',
  Customer = '/assets/icons/icon_customers.png',
  Dashboard = '/assets/icons/icon_dashboard.png',
  Help = '/assets/icons/icon_help-center.png',
  Invoice = '/assets/icons/icon_Invoices.png',
  Product = '/assets/icons/icon_products.png',
  Alcohol = '/assets/icons/icon_alcohol.png',
}
