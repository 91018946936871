import * as React from 'react'
import { SVGProps } from 'react'

const IconSetting = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path fill="none" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
    <g data-name="Icon Setting" clipPath="url(#a)">
      <path
        data-name="Path 108"
        d="m13.3 5.2 1.1-2.1L13 1.7l-2.1 1.1a3.582 3.582 0 0 0-1.1-.4L9 0H7l-.8 2.3a4.179 4.179 0 0 0-1 .4L3.1 1.6 1.6 3.1l1.1 2.1a4.179 4.179 0 0 0-.4 1L0 7v2l2.3.8c.1.4.3.7.4 1.1L1.6 13 3 14.4l2.1-1.1a3.582 3.582 0 0 0 1.1.4L7 16h2l.8-2.3c.4-.1.7-.3 1.1-.4l2.1 1.1 1.4-1.4-1.1-2.1a3.582 3.582 0 0 0 .4-1.1L16 9V7l-2.3-.8a4.179 4.179 0 0 0-.4-1ZM8 11a2.946 2.946 0 0 1-3-3 2.946 2.946 0 0 1 3-3 2.946 2.946 0 0 1 3 3 2.946 2.946 0 0 1-3 3Z"
        fill="#a5a4bf"
      />
    </g>
  </svg>
)

export default IconSetting
