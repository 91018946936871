import React from 'react'
import { Button } from 'reactstrap'

type TOnSubmitButton = {
  content: string
  position?: 'center' | 'left' | 'right'
}

const OnSubmitButton: React.VFC<TOnSubmitButton> = (props: TOnSubmitButton) => {
  const { content, position } = props
  return (
    <div className={`login-btn text-${position ?? 'center'}`}>
      <Button color="primary" type="submit">
        {content}
      </Button>
    </div>
  )
}

export default OnSubmitButton
