import React from 'react'

type TLeftBlue = {
  content: string
}

const LeftBlue: React.VFC<TLeftBlue> = (props: TLeftBlue) => {
  const { content } = props
  return <div className="pl-2 py-1 left-border-line">{content}</div>
}

export default LeftBlue
