import {
  FilterParams,
  CarScope,
  Achievement,
  Pagination,
} from 'common/types/achievement'
import { IStoreCache } from 'common/types/auth_provider'
import { IPreworkProvider } from 'common/types/prework_provider'
import { nullToNum, nullToStr } from 'common/utils'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import {
  fetchAchievement,
  fetchAchievementCsv,
  fetchAchievementPdf,
  fetchCsvDownLoad,
} from 'repositories/achievenment'
import { InspectionPatternNamesRes } from 'common/types/inspection_pattern_names'
import { fetchInspectionPatternName } from 'repositories/inspection_pattern_name'
import { alertService } from 'services/alert'
import { AuthContext } from './AuthProvider'

export const PreworkContext = React.createContext<IPreworkProvider>({
  editData: [],
  editedData: [],
  toggleSelectAll: () => undefined,
  handleEdit: (id, key, value) => undefined,
  onApplyFilter: () => undefined,
  onUpdateSuccess: () => {},
  isLoading: false,
  currentPage: 1,
  totalPage: 1,
  totalRecord: 0,
  limit: 20,
  onChangeLimit: (num: number) => undefined,
  onChangePage: (num: number) => undefined,
  scopeCar: [],
  downLoadCsv: () => undefined,
  handleSort: (sortBy: string) => undefined,
  sortBy: [],
  checkAll: false,
  filters: {},
  downLoadPdf: () => undefined,
  inspectionPatternNameOption:"",
})

export const PreworkProvider = ({
  children,
}: {
  children: any
}): JSX.Element => {
  const { loginInfo }: IStoreCache = useContext(AuthContext).storeCache
  const token = localStorage.getItem('token') || ''
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<Pagination>({
    currentPage: 1,
    totalPage: 1,
    totalRecord: 0,
  })
  const [achievements, setAchievements] = useState<Achievement[]>([])
  const [editData, setEditData] = useState<Achievement[]>([])
  const [editedData, setEditedData] = useState<Achievement[]>([])
  const [sortBy, setSortBy] = useState<string[]>(['-reg_date'])
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [filters, setFilters] = useState({
    kaisya_code: loginInfo?.kaisyaCode || '',
    ncs_user_id: loginInfo?.ncsUserId || '',
    start_date: moment().startOf('date').format('YYYYMMDDHHmmss'),
    end_date: moment().endOf('date').format('YYYYMMDDHHmmss'),
    search_type: '',
    search_text: '',
    achievement_type: '1',
    check_data: '3',
    check_status: '3',
    check_log: '3',
    sort_by: '-reg_date',
    inspection_pattern_name: '',
  })

  // 点検パターン名：POSTデータ
  const [inspectionPatternNamePost, setInspectionPatternNamePost] = useState({
    kaisya_code: loginInfo?.kaisyaCode || '',
  })

  // 点検パターン名：選択肢
  const [inspectionPatternNameOption, setInspectionPatternNameOption] = useState([{value: '', label: '全て'}])
  const [scopeCar, setScopeCar] = useState<CarScope[]>([])
  const [checkAll, setCheckAll] = useState<boolean | null>(false)

  useEffect(() => {
    const arr: Achievement[] = []
    for (let index = 0; index < editData.length; index++) {
      if (editData[index].achievement_id) {
        const object1 = editData[index]
        const object2 = achievements.find(
          (e) => object1.achievement_id === e.achievement_id
        )
        if (
          object2 &&
          (nullToStr(object1.car_name) !== nullToStr(object2.car_name) ||
            nullToStr(object1.car_soshiki_name) !==
              nullToStr(object2.car_soshiki_name) ||
            nullToNum(object1.checklogs_status) !==
              nullToNum(object2.checklogs_status) ||
            nullToStr(object1.checklogs_user_name) !==
              nullToStr(object2.checklogs_user_name))
        ) {
          arr.push(object1)
        }
      }
    }
    setEditedData(arr)
  }, [achievements, editData, setEditedData])

  const handleSort = (sortName: string) => {
    const sort_by = [...sortBy]
    if (sortName === 'reg_date') {
      if (sort_by.length === 1) {
        sort_by[0] = sort_by[0] === 'reg_date' ? '-reg_date' : 'reg_date'
      } else {
        sort_by[0] = 'reg_date'
        sort_by.length = 1
      }
    } else if (sort_by.length === 1) {
      sort_by[0] = sortName
      sort_by[1] = '-reg_date'
    } else if (sortName === sort_by[0]) {
      sort_by[0] = `-${sortName}`
    } else {
      sort_by[0] = sortName
    }
    setSortBy(sort_by)
  }

  useEffect(() => {
    loadData()
  }, [filters, currentPage, limit, sortBy])

  // 画面描画時に点検パターン名を取得
  useEffect(() => {
    getInspectionPatternName()
  }, [inspectionPatternNamePost])

  const onApplyFilter = (params: FilterParams) => {

    setFilters((prev) => ({
      ...prev,
      ...params,
    }))
    if (currentPage > 1) {
      setCurrentPage(1)
    }
  }

  const getInspectionPatternName = async () => {
    
    try{
      
      // 絞り込み検索：点検パターン名の取得
      const inspectionPatternNameRes = await fetchInspectionPatternName(token,{
        ...inspectionPatternNamePost,
      }) as InspectionPatternNamesRes

      // 点検パターン名を初期化
      setInspectionPatternNameOption([{value: '', label: '全て'}])

      // 取得した点検パターン名の数だけ選択肢に追加
      if (String(inspectionPatternNameRes.status) === '1') {

        const resInspectionPatternNames = inspectionPatternNameRes.inspection_pattern_names;
        for (let index = 0; index < resInspectionPatternNames.length; index++) {

          const patternName = String(resInspectionPatternNames[index].inspection_pattern_name);
          setInspectionPatternNameOption((inspectionPatternNameOption) => ([...inspectionPatternNameOption, { value: patternName, label: patternName }]))
        }
      }

    } catch (error) {

      console.log(error)
      alertService.show(
        false,
        'システムエラーが発生しました。しばらく時間をおいてから再アクセスするか、お問い合わせください。（実績取得）'
      )
    }
  }

  const loadData = async () => {
    setIsLoading(true)
    try {
      const res = await fetchAchievement(token, {
        ...filters,
        page: currentPage.toString(),
        limit: limit.toString(),
        sort_by: sortBy.join(','),
      })
      setData({
        currentPage: res.currentPage || 1,
        totalPage: res.totalPage || 1,
        totalRecord: res.totalRecord || 0,
      })
      setAchievements(JSON.parse(JSON.stringify(res.achievements)))
      const data = res?.achievements?.map((e) => {
        if (!e.achievement_id || !e.confirm_date || !!e.checklogs_status) {
          return {
            ...e,
            editable: false,
          }
        }
        return {
          ...e,
          editable: true,
        }
      })
      setEditData(JSON.parse(JSON.stringify(data)))
      setEditedData([])
      if (Array.isArray(res?.scope_data)) {
        setScopeCar(res.scope_data[0]?.carList)
      } else {
        setScopeCar(res?.scope_data?.carList)
      }
      if (res.achievements && res.achievements.length > 0) {
        if (!res.achievements.some((e) => e.checklogs_status !== 1)) {
          setCheckAll(true)
        } else if (!res.achievements.some((e) => e.checklogs_status === 1)) {
          setCheckAll(false)
        } else {
          setCheckAll(null)
        }
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      alertService.show(
        false,
        'システムエラーが発生しました。しばらく時間をおいてから再アクセスするか、お問い合わせください。（実績取得）'
      )
      setIsLoading(false)
    }
  }

  const handleEdit = (id: string, key: string, value: any) => {
    // console.log(`handleEdit-${id}-${key}`, value)

    const dataEdit = [...editData]
    const index = dataEdit.findIndex((item) => item.achievement_id === id)
    if (key === 'checklogs_status') {
      dataEdit[index]['checklogs_status'] = value ? 1 : 0
      dataEdit[index]['checklogs_user_name'] = value
        ? loginInfo?.ncsUserName
        : null
      if (value) {
        dataEdit[index]['checklogs_ncs_user_id'] = loginInfo?.ncsUserId || null
      }
    }
    if (key === 'car_name') {
      const cars = scopeCar.filter((e) => e.driveRecorderId === value.value)
      var result =
        cars?.length > 0
          ? cars?.reduce(function (res, obj) {
              return moment(obj.startDate).unix() < moment(res.startDate).unix()
                ? obj
                : res
            })
          : null
      dataEdit[index]['drive_recorder_id'] = value?.value
      dataEdit[index]['car_name'] = value?.label
      dataEdit[index]['car_soshiki_name'] = (result && result?.pathName) || null
    }

    setEditData(dataEdit)
  }

  const toggleSelectAll = () => {
    const dataEdit = [...editData].map((item) => {
      if (!item.achievement_id || !item.confirm_date) {
        return item
      }
      if (!checkAll && !item.checklogs_status) {
        item.checklogs_status = 1
        item.checklogs_user_name = loginInfo?.ncsUserName || null
        item.checklogs_ncs_user_id = loginInfo?.ncsUserId || null
      }
      if (
        checkAll &&
        item?.checklogs_status &&
        (!item?.checklogs_ncs_user_id ||
          loginInfo?.ncsUserId === item?.checklogs_ncs_user_id)
      ) {
        item.checklogs_status = 0
        item.checklogs_user_name = null
      }
      return item
    })
    setEditData([...dataEdit])
    setCheckAll((prev) => !prev)
  }

  const onUpdateSuccess = () => {
    const data = editData.map((e) => {
      if (!e.achievement_id || !e.confirm_date || !!e.checklogs_status) {
        return {
          ...e,
          editable: false,
        }
      }
      return {
        ...e,
        editable: true,
      }
    })
    setAchievements(JSON.parse(JSON.stringify(data)))
    setEditData(JSON.parse(JSON.stringify(data)))
  }

  const onChangeLimit = (num: number) => {
    setLimit(num)
    if (currentPage > 1) {
      setCurrentPage(1)
    }
  }
  const onChangePage = (num: number) => setCurrentPage(num)

  const downLoadCsv = async () => {
    try {
      setIsLoading(true)
      const data = await fetchAchievementCsv(token, {
        kaisya_code: loginInfo?.kaisyaCode || '',
        ncs_user_id: loginInfo?.ncsUserId || '',
        ncs_user_name: loginInfo?.ncsUserName || '',
        search_type: filters.search_type,
        search_text: filters.search_text,
        achievement_type: 1,
        check_data: filters.check_data,
        check_status: filters.check_status,
        check_log: filters.check_log,
        sort_by: filters.sort_by,
        date_range: {
          from: filters.start_date,
          to: filters.end_date,
        },
        inspection_pattern_name: filters.inspection_pattern_name,
      })
      if (data.status == '1') {
        const csvData = await fetchCsvDownLoad(token, data.resulturl)
        const link = document.createElement('a')
        const blob = new Blob([csvData as any], { type: 'application/zip' })
        link.href = URL.createObjectURL(blob)
        link.download =
          '始業前点検_' +
          filters.start_date.replace(/\//g, '') +
          '-' +
          filters.end_date.replace(/\//g, '') +
          '.zip'
        link.click()
      } else {
        alertService.show(false, data.message)
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      alertService.show(
        false,
        'システムエラーが発生しました。しばらく時間をおいてから再アクセスするか、お問い合わせください。（CSV出力）'
      )
      setIsLoading(false)
    }
  }

  const downLoadPdf = async () => {
    try {
      setIsLoading(true)
      const data = await fetchAchievementPdf(token, {
        kaisya_code: loginInfo?.kaisyaCode || '',
        ncs_user_id: loginInfo?.ncsUserId || '',
        ncs_user_name: loginInfo?.ncsUserName || '',
        search_type: filters.search_type,
        search_text: filters.search_text,
        achievement_type: 1,
        check_data: filters.check_data,
        check_status: filters.check_status,
        check_log: filters.check_log,
        sort_by: filters.sort_by,
        date_range: {
          from: filters.start_date,
          to: filters.end_date,
        },
      })
      if (data.status == '1') {
        const csvData = await fetchCsvDownLoad(token, data.resulturl)
        const link = document.createElement('a')
        const blob = new Blob([csvData as any], { type: 'application/pdf' })
        link.href = URL.createObjectURL(blob)
        const userName = loginInfo?.ncsUserName || 'non_user'
        link.download =
          'アルコールチェック_' +
          userName +
          '_' +
          moment().format('YYYYMMDD_HHmmss') +
          '.pdf'
        link.click()
      } else {
        alertService.show(false, data.message)
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  return (
    <PreworkContext.Provider
      value={{
        editData,
        handleEdit,
        toggleSelectAll,
        onApplyFilter,
        onUpdateSuccess,
        isLoading,
        currentPage,
        totalPage: data.totalPage,
        totalRecord: data.totalRecord,
        limit,
        onChangeLimit,
        onChangePage,
        scopeCar,
        editedData,
        downLoadCsv,
        handleSort,
        sortBy: sortBy,
        checkAll,
        filters,
        downLoadPdf,
        inspectionPatternNameOption,
      }}
    >
      {children}
    </PreworkContext.Provider>
  )
}
