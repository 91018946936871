import React, { ReactChild, ReactChildren } from 'react'
import { Modal, Button } from 'reactstrap'

type TConfirmModal = {
  isOpen: boolean
  onAction: () => void
  onToggle: () => void
  onClose: () => void
  children: ReactChild | ReactChildren
  dataDetail: any
}

const DetailModal: React.VFC<TConfirmModal> = ({
  isOpen,
  onAction,
  onToggle,
  onClose,
  children,
  dataDetail,
}: TConfirmModal) => {
  return (
    <Modal
      className="modal-dialog-centered  modal-detail"
      isOpen={isOpen}
      size="lg"
    >
      <div className="header-modal-detail">
        <div className="detail-close">
          <div className="close" onClick={onClose} />
        </div>
        <span>始業前点検実績詳細</span>
        <div className="wrap-right-header">
          <span>確認者：{dataDetail?.checklogs_user_name}</span>
          <div className="confirmation">
            <span>確認：</span>
            <span
              className={
                dataDetail?.checklogs_status === 1 ? 'confirmation-icon' : ''
              }
            >
              {dataDetail?.checklogs_status === 1 ? '済' : '未'}
            </span>
          </div>
        </div>
      </div>
      <div className="modal-detail-list">
        <div>{children}</div>
        <div className="footer-detail">
          <button
            type="button"
            className="close-modal-button"
            onClick={onClose}
          >
            閉じる
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default DetailModal
