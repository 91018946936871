import { LoginForm } from 'common/types/form/login'
import React, { useContext, useEffect, useState, useLayoutEffect } from 'react'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap'
import { Control, Controller, FieldError, useForm } from 'react-hook-form'
import { initLoginForm, loginUser } from 'services/login'
import { AuthContext } from 'providers/AuthProvider'
import { IStoreCache } from 'common/types/auth_provider'
import { useHistory } from 'react-router'
import { createRequiredMessage, getInputType } from 'common/utils'
import Feedback from 'components/atoms/Feedback/Feedback'
import { InputType, Labels } from 'common/enums'
import OnSubmitButton from 'components/atoms/Button/OnSubmitButton'
import LoginLogo from 'assets/img/logo-login.svg'
import { fetchAll } from 'repositories/information'
import LoadingIndicator from 'components/atoms/LoadingIndicator'

const FormBody: React.VFC = () => {
  const storeCache: IStoreCache = useContext(AuthContext).storeCache
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>({
    defaultValues: initLoginForm(),
  })
  const history = useHistory()
  const [loadingInformation, setLoadingInformation] = useState<boolean>(false)
  const [information, setInformation] = useState<string>('')

  useLayoutEffect(() => {
    // No.xx3対応
    const getInfo = async () => {
      setLoadingInformation(true)
      let res = await fetchAll('')
      if (res !== undefined) {
        setInformation(res.result)
        setLoadingInformation(false)
      }
    }
    getInfo()
  }, [])

  useEffect(() => {
    /* eslint no-underscore-dangle: 0 */
    var __dcid: any[] = []
    __dcid.push({ cid: 'DigiCertClickID_J1zeEX9U', tag: 'J1zeEX9U' })
    ;(function () {
      var cid = document.createElement('script')
      cid.async = true
      cid.src = '//seal.digicert.com/seals/cascade/seal.min.js'
      var s = document.getElementsByTagName('script')
      var ls = s[s.length - 1]
      ls?.parentNode && ls.parentNode.insertBefore(cid, ls.nextSibling)
    })()
  }, [])

  const login = (data: LoginForm): Promise<void> =>
    loginUser(data, storeCache, history)

  return (
    <div className="main-content login-main">
      <div className="wrap-logo logo-header" style={{ flexDirection: 'column', margin: '0 auto' }}>
        <img src={LoginLogo} alt="" style={{ marginBottom: '3px' }} />
        {/* No.xx2対応 */}
        <span style={{ display: 'block', textAlign: 'right', marginRight: '5px' }}>1.7.3</span>
      </div>
      {/* <Link to={Routes.WaitingLoginDD}>LoginDD</Link> */}
      <Container>
        <Row className="justify-content-center">
          <Col>
            <div className="text-white border-0 card-login">
              <div className="login-card">
                <Form role="form" onSubmit={handleSubmit(login)}>
                  {renderForm(
                    control,
                    Labels.CompanyCODE,
                    'company_code',
                    errors.company_code,
                    InputType.TEXT
                  )}
                  {renderForm(
                    control,
                    Labels.LoginID,
                    'ncs_user_id',
                    errors.ncs_user_id,
                    InputType.TEXT
                  )}
                  {renderForm(
                    control,
                    Labels.Password,
                    'password',
                    errors.password,
                    InputType.PASSWORD
                  )}
                  <OnSubmitButton content={Labels.Login} position="center" />
                </Form>
              </div>
            </div>
            <div className="text-center text-under-form">
              <div>パスワードを忘れた場合は</div>
              <div>
                <span
                  className="text-link"
                  onClick={() =>
                    window.open(`${process.env.REACT_APP_DD_URL}/ncsdd/`)
                  }
                >
                  NCSドライブドクター
                </span>
                の画面でご対応ください
              </div>
            </div>
            <div id="DigiCertClickID_J1zeEX9U"></div>
            {loadingInformation ? <LoadingIndicator /> : ''}
            {
              information ?
              <div className="text-center login-info">
                <span>{information}</span>
              </div>
              : ''
            }
          </Col>
        </Row>
      </Container>
      <div className="wrap-logo copy-right">
        <span
          className="text-link"
          onClick={() => window.open(`${process.env.REACT_APP_ZDC_API_URL}/download/kiyaku`)}
        >
          NCSドライブドクター利用規約
        </span>
        <span
          className="text-link"
          onClick={() => window.open(`${process.env.REACT_APP_ZDC_API_URL}/download/privacy`)}
        >
          プライバシーポリシー
        </span>
        <span>copyright©2022 NIPPON CAR SOLUTIONS CO.,LTD</span>
      </div>
    </div>
  )
}

function renderForm(
  control: Control<LoginForm, object>,
  title: string,
  fieldName: 'company_code' | 'ncs_user_id' | 'password',
  errorField: FieldError | undefined,
  type: InputType
) {
  return (
    <FormGroup className="login-form">
      <Row className="mx-0">
        <Controller
          name={fieldName}
          control={control}
          rules={{
            required: createRequiredMessage(title),
          }}
          render={({ field }) => (
            <Input
              type={getInputType(type)}
              className={`input-login ${errorField ? 'is-invalid' : ''}`}
              placeholder={title}
              {...field}
            />
          )}
        />
      </Row>
      <Feedback error={errorField} />
    </FormGroup>
  )
}

export default FormBody
