import { AlertType } from 'common/enums'
import { Alert as TAlert } from 'common/types/alert'
import React, { CSSProperties, useEffect, useState } from 'react'
import { Alert as ReactstrapAlert } from 'reactstrap'
import { alertService } from 'services/alert'

const Alert: React.VFC = () => {
  const [alert, setAlert] = useState<TAlert>({
    isShow: false,
    type: AlertType.SUCCESS,
    message: '',
  })

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null
    const subscription = alertService.onAlert().subscribe((alert: TAlert) => {
      setAlert(alert)
      if (alert.isShow) {
        if (timeoutId !== null) clearTimeout(timeoutId)

        const timeout = alert.type === AlertType.SUCCESS ? 5 : 6
        timeoutId = setTimeout(() => {
          alertService.close(alert)
          timeoutId = null
        }, timeout * 1000)
      }
    })

    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId)
      }
      subscription.unsubscribe()
    }
  }, [])

  let alertColor = 'success'
  if (alert.type === AlertType.DANGER) alertColor = 'danger'

  const style: CSSProperties = {
    textAlign: 'center',
    fontSize: 'unset',
    borderRadius: 'unset',
    zIndex: 1100,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
  }

  return (
    <ReactstrapAlert isOpen={alert.isShow} color={alertColor} style={style}>
      {alert.message}
    </ReactstrapAlert>
  )
}

export default Alert
