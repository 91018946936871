import { Routes } from 'common/enums'
import { IStoreCache } from 'common/types/auth_provider'
import {
  InspectionCheckSheetForm,
  SelectedInspectionItem,
} from 'common/types/form/inspection_check_sheet'
import { InspectionItem } from 'common/types/inspection_item'
import { InspectionPattern } from 'common/types/inspection_patterns'
import { getErrorMessage } from 'common/utils'
import { History } from 'history'
import { reloadCachedInspectionPatterns } from 'providers/AuthProvider'
import { save } from 'repositories/inspection_pattern'
import { alertService } from 'services/alert'

/**
 * 点検チェックシートフォーム用初期値設定
 */
export function initInspectionCheckSheetForm(
  isCreate: boolean,
  client_id: number,
  inspectionPattern: InspectionPattern | undefined,
  inspectionItems: InspectionItem[]
): InspectionCheckSheetForm {
  if (isCreate) return { id: 0, client_id, name: '', inspection_items: [] }
  const { id, name, inspection_items } = inspectionPattern!
  return {
    id,
    client_id,
    name,
    inspection_items: inspectionItems.filter(({ id }) =>
      inspection_items.includes(id)
    ),
  }
}

/**
 * 点検パターンマトリックスチェックボックス用初期値設定
 */
export function initInspectionItems(
  inspectionPattern: InspectionPattern | undefined,
  inspectionItems: InspectionItem[],
  isChecked: boolean | undefined = undefined
): SelectedInspectionItem[] {
  return inspectionItems.map(({ id }) => {
    return {
      [id]:
        isChecked === undefined
          ? false
          : inspectionPattern?.inspection_items.includes(id) || isChecked,
    }
  })
}

/**
 * 点検パターンマトリックスチェックボックス`onChange`イベントハンドラ
 */
export function onChange(
  e: React.ChangeEvent<HTMLInputElement>,
  index: number | null,
  inspectionPattern: InspectionPattern | undefined,
  inspectionItems: InspectionItem[],
  data: SelectedInspectionItem[],
  setData: React.Dispatch<React.SetStateAction<SelectedInspectionItem[]>>,
  selected: boolean,
  setSelected: React.Dispatch<React.SetStateAction<boolean>>
): void {
  const { checked } = e.target
  if (index === null) {
    // テーブルヘッダ押下時
    if (checked)
      setData(initInspectionItems(inspectionPattern, inspectionItems, true))
    else setData(initInspectionItems(inspectionPattern, inspectionItems))
    setSelected(!selected)
    return
  }
  const keyName = Object.keys(data[index])[0]
  data.splice(index, 1, { [keyName]: checked })
  setData([...data])
}

/**
 * 点検パターンを保存する
 */
export async function saveInspectionPattern(
  storeCache: IStoreCache,
  { push }: History,
  data: InspectionCheckSheetForm,
  inspectionItems: SelectedInspectionItem[]
): Promise<void> {
  try {
    validateInspectionItems(inspectionItems)

    data.inspection_items = inspectionItems
    await save(storeCache.token, data)
    await reloadCachedInspectionPatterns(storeCache)

    alertService.show(true, '点検パターンの保存が完了しました。')
    push(Routes.InspectionCheckSheet)
  } catch (error) {
    getErrorMessage(error)
  }
}

function validateInspectionItems(inspectionItems: SelectedInspectionItem[]) {
  if (inspectionItems.length === 0)
    throw new Error('点検項目を一つ以上作成してください。')

  let inspectionItemCount = 0
  for (const i of inspectionItems) {
    if (i[Object.keys(i)[0] as unknown as number])
      return (inspectionItemCount += inspectionItemCount)
  }
  if (inspectionItemCount === 0)
    throw new Error('点検項目を一つ以上選択してください。')
}
