import { post } from 'common/fetch'
import {
  ListWebGetReq,
  InspectionPatternItemsRes,
  ListWebUpdateRes,
  ListWebUpdateParams,
  ListWebStoreParams,
  ListWebStoreRes,
  ListWebDeleteParams,
  ListWebDeleteRes,
  ListWebCheckParams,
  ListWebCheckRes
} from 'common/types/list_web'
import { alertService } from 'services/alert'

const path: string = '/api/list-web-get'
const updatePath: string = '/api/list-web-update'
// No.9対応：点検パターン新規登録用パス
const storePath: string = '/api/list-web-store'
// No.9対応：点検パターン削除用パス
const deletePath: string = '/api/list-web-delete'
// No.9対応：点検パターン名バリデーション用パス
const checkPath: string = '/api/list-web-check-inspection-name'

/**
 * Get All Data.
 */
export async function fetch(
  token: string,
  data: ListWebGetReq
): Promise<InspectionPatternItemsRes> {
  return post<InspectionPatternItemsRes>(token, path, data)
    .then((result) => result)
    .catch(() => {
      alertService.show(
        false,
        'システムエラーが発生しました。しばらく時間をおいてから再アクセスするか、お問い合わせください。（点検項目取得）'
      )
      return {
        status: '-1',
        message: '',
        inspection_pattern_items: [],
      }
    })
}

export async function postListWebUpdate(
  token: string,
  data: ListWebUpdateParams
): Promise<ListWebUpdateRes> {
  return post<ListWebUpdateRes>(token, updatePath, data)
    .then((result) => result)
    .catch(() => {
      return {
        status: '-1',
        message: ''
      }
    })
}

// No.9対応：点検パターン新規登録
export async function postListWebStore(
  token: string,
  data: ListWebStoreParams
): Promise<ListWebStoreRes> {
  return post<ListWebStoreRes>(token, storePath, data)
    .then((result) => result)
    .catch(() => {
      return {
        status: '-1',
        message: ''
      }
    })
}

export async function postListWebCheckInspectionName(token: string,
  data: ListWebCheckParams
): Promise<ListWebCheckRes> {
  return post<ListWebCheckRes>(token, checkPath, data)
    .then((result) => result)
    .catch(() => {
      return {
        message: ''
      }
    })
}

// No.9対応：点検パターン新規登録
export async function postListWebDelete(
  token: string,
  data: ListWebDeleteParams
): Promise<ListWebDeleteRes> {
  return post<ListWebDeleteRes>(token, deletePath, data)
    .then((result) => result)
    .catch(() => {
      return {
        status: '-1',
        message: '',
      }
    })
}