import { Routes, ViewType } from 'common/enums'
import { IStoreCache } from 'common/types/auth_provider'
import { isAdmin } from 'common/utils'
import FullLoadingSpinner from 'components/atoms/FullLoadingSpinner/FullLoadingSpinner'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useMemo } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import Answer from 'template/Admin/Answer'
import AnswerDetail from 'template/Admin/AnswerDetail'
import InspectionCheckSheet from 'template/Admin/InspectionCheckSheet'
import InspectionCheckSheetForm from 'template/Admin/InspectionCheckSheetForm'
import InspectionItem from 'template/Admin/InspectionItem'
import Permission from 'template/Admin/Permission'
import User from 'template/Admin/User'
import Vehicle from 'template/Admin/Vehicle'
import Alcohol from 'template/Admin/Alcohol'

const Admin: React.VFC = () => {
  const { isLoading, token, user }: IStoreCache =
    useContext(AuthContext).storeCache
  const render = useMemo(() => {
    if (isLoading) {
      return <FullLoadingSpinner />
    }
    if (!token) {
      return <Redirect to="/login" />
    }
    return routeSwitch()
  }, [isLoading, token])
  return render
}

const routeSwitch = (): JSX.Element => {
  return (
    <>
      <Switch>
        <Route path={Routes.Answer} component={() => <Answer />} exact />
        <Route
          path={Routes.AnswerDetail}
          component={() => <AnswerDetail />}
          exact
        />
        <Route
          path={Routes.InspectionCheckSheet}
          component={() => <InspectionCheckSheet />}
          exact
        />
        <Route
          path={[
            Routes.InspectionCheckSheetCreate,
            Routes.InspectionCheckSheetEdit,
          ]}
          component={() => <InspectionCheckSheetForm />}
          exact
        />
        <Route
          path={Routes.InspectionItem}
          component={() => <InspectionItem />}
          exact
        />
        <Route path={Routes.User} component={() => <User />} exact />
        <Route path={Routes.Vehicle} component={() => <Vehicle />} exact />
        <Route
          path={Routes.Permission}
          component={() => <Permission />}
          exact
        />
        <Route path={Routes.AlcoholCheck} component={() => <Alcohol />} exact />
      </Switch>
    </>
  )
}

export default Admin
