import { createAchievementsAlc } from 'repositories/achievement-create'
import {
  AchievementAlcRegisterParam,
} from 'common/types/achievement'

export async function createAchievementsAlcohol(
  token: string,
  data: AchievementAlcRegisterParam
): Promise<any> {
  try {
    const res = await createAchievementsAlc(token, data)
    // console.log(res)

    return res
  } catch (error) {
    return error
  }
}
