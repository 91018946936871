import Switch from 'react-switch'
import { ScreenLabels } from 'common/enums'
import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row, Label, Input } from 'reactstrap'
import { AuthContext } from 'providers/AuthProvider'
import { IStoreCache } from 'common/types/auth_provider'
import {
  getAnswerJson,
  saveEdit,
  onRemove,
  approval,
  getOperationLog,
} from 'services/admin/answer'
import { AnswerJson } from 'common/types/answer/answer_json'
import { OperationLog } from 'common/types/answer/operation_log'
import { useParams } from 'react-router-dom'
import Display4 from 'components/atoms/Span/Display4'
import { useHistory } from 'react-router'
import {
  AlcCheckItem,
  BoxState,
  BOX_TYPES,
  getAnswerData,
  InspectionPattern,
} from 'services/admin/answer_detail'
import LogTable from 'components/molecules/Admin/AnswerDetail/LogTable'

const initState = {
  [BOX_TYPES.ALC_START]: false,
  [BOX_TYPES.ALC_END]: false,
  [BOX_TYPES.INSPECTION]: false,
} as BoxState

export const TrashIcon = () => {
  return (
    <img alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAOCAYAAAAbvf3sAAAABHNCSVQICAgIfAhkiAAAAKJJREFUKFNjZEADaWlp/5GFZs2axYjMZwQquAAU0EfXiI3/////CyRpABpyEcU6YmwBa0B3Ny6NIP/ANHwAKuLHZwPQ/Q9nz56tANaQmpp6gJGR0Z6AhoNADQ5ka9gAtMGfgA0bgTYEwPzQAFRcTyCUGoGebqCThpSUFAcmJqb9+Jz0798/xzlz5hwAOykjI0MBKABKU7ji4iPQQIMZM2Y8AAD1x1EPkzl19QAAAABJRU5ErkJggg==" />
  )
}

export const EditIcon = () => {
  return (
    <img alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABHNCSVQICAgIfAhkiAAAAOlJREFUKFOVk7ENgzAQRXNCILp4lLBBsgEbxA3MkQ1CiaAIG4QRMgIbhBFCjQT5J8XIGGOIJYqT7v3/fWfosOMkSSLRdsfX9n1/qarqwxhtsQwS0UP1jePYKAEnLKUUQRBkAK+6CQuUZRmtwiqq53nRMAw3U6AoCrLCelQV00jQARYL2LwjxzUEYtRnxG5msA00BxWGocjzvJ1N2wX+BDrlqAQn5zRNeXfHldUtwMkZYIzi+Q+ow5W5irWougHxQ/B9/41XJAxna9QZbBlUh4Yaw8l4Ha7nS4BrNJwYgPsLy+d619n8MVwqX/WkixBW7lARAAAAAElFTkSuQmCC" />
  )
}

const TableCard: React.VFC = () => {
  const { id } = useParams<{ id: string }>()
  const storeCache: IStoreCache = useContext(AuthContext).storeCache
  const [alcCheckBeforeJson, setAlcCheckBeforeJson] = useState<AnswerJson>()
  const [inspectionJson, setInspectionJson] = useState<AnswerJson>()
  const [alcCheckAfterJson, setAlcCheckAfterJson] = useState<AnswerJson>()
  const { goBack } = useHistory()

  const [approverStatus, setApproverStatus] = useState<BoxState>(initState)
  const [editEnableStatus, setEditEnableStatus] = useState<BoxState>(initState)

  const [changeStartAlcData, setChangeStartAlcData] = useState<AlcCheckItem[]>(
    []
  )
  const [changeInspectionData, setChangeInspectionData] = useState<
    InspectionPattern[]
  >([])
  const [changeEndAlcData, setChangeEndAlcData] = useState<AlcCheckItem[]>([])

  // answer_id(アルコール_始業のID)
  const [startId, setStartId] = useState<number>(0)
  // answer_id(車両点検のID)
  const [inspectionId, setInspectionId] = useState<number>(0)
  // answer_id(アルコール_終業のID)
  const [endId, setEndId] = useState<number>(0)
  // 操作ログ
  const [operationLog, setOperationLog] = useState<OperationLog[]>([])

  useEffect(() => {
    getAnswerJson(
      storeCache,
      id,
      setStartId,
      setInspectionId,
      setEndId,
      setAlcCheckBeforeJson,
      setInspectionJson,
      setAlcCheckAfterJson
    )
    getOperationLog(storeCache.token, setOperationLog, startId, inspectionId, endId)
  }, [
    storeCache,
    id,
    startId,
    inspectionId,
    endId,
    setStartId,
    setInspectionId,
    setEndId,
    setAlcCheckBeforeJson,
    setInspectionJson,
    setAlcCheckAfterJson,
    setOperationLog,
  ])

  // 回答データ
  const { start_alc_check_answers, inspection_answers, end_alc_check_answers } =
    getAnswerData(
      alcCheckBeforeJson,
      inspectionJson,
      alcCheckAfterJson,
      storeCache
    )

  const handleChange = async (e: boolean, key: BOX_TYPES): Promise<void> => {
    await approval(
      storeCache.token,
      id,
      e,
      key,
      startId,
      inspectionId,
      endId,
      setOperationLog)
    if (!editEnableStatus[key])
      setApproverStatus({
        ...approverStatus,
        [key]: !approverStatus[key],
      } as BoxState)
  }

  const onDeleteButton = async (key: BOX_TYPES): Promise<void> => {
    await onRemove(
      storeCache.token,
      id,
      key,
      startId,
      inspectionId,
      endId,
      setOperationLog)
  }

  const editEnable = (key: BOX_TYPES): void => {
    if (!approverStatus[key]) {
      setEditEnableStatus({
        ...editEnableStatus,
        [key]: true,
      } as BoxState)
    }
  }

  const cancelEdit = (key: BOX_TYPES): void => {
    if (editEnableStatus[key]) {
      setEditEnableStatus({
        ...editEnableStatus,
        [key]: false,
      } as BoxState)
    }
  }

  const saveEditButton = (key: BOX_TYPES): void => {
    const save = async (num: number) =>
      await saveEdit(
        storeCache.token,
        changeStartAlcData,
        id,
        num,
        setAlcCheckBeforeJson,
        startId,
        inspectionId,
        endId,
        setOperationLog
      )
    switch (key) {
      case BOX_TYPES.ALC_START:
        save(10)
        setChangeStartAlcData([])
        break
      case BOX_TYPES.INSPECTION:
        save(1)
        setChangeInspectionData([])
        break
      case BOX_TYPES.ALC_END:
        save(11)
        setChangeEndAlcData([])
        break
    }

    if (editEnableStatus[key])
      setEditEnableStatus({
        ...editEnableStatus,
        [key]: false,
      } as BoxState)
  }

  const changeStartAlc = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target
    const index: number = changeStartAlcData.findIndex(({ key }) => key === id)
    if (index === -1) {
      changeStartAlcData.push({ key: id, value: '', result: value })
    } else {
      changeStartAlcData[index].result = value
    }
    setChangeStartAlcData(changeStartAlcData)
  }

  const changeInspection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target
    const index: number = changeInspectionData.findIndex(
      ({ key }) => key === id
    )
    if (index === -1) {
      changeInspectionData.push({
        key: id,
        value: '',
        result: value,
        categorie: '',
        color: '',
      })
    } else {
      changeInspectionData[index].result = value
    }
    setChangeInspectionData(changeInspectionData)
  }

  const changeEndAlc = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target
    const index: number = changeEndAlcData.findIndex(({ key }) => key === id)
    if (index === -1) {
      changeEndAlcData.push({ key: id, value: '', result: value })
    } else {
      changeEndAlcData[index].result = value
    }
    setChangeEndAlcData(changeEndAlcData)
  }

  return (
    <div className="main-content">
      <Container className="px-lg-5 pb-4 mt-3">
        <Row className="justify-content-center">
          <Col lg="12" md="12" className="page-detail-title">
            <span
              onClick={() => goBack()}
              className="left-alt"
              style={{ cursor: 'pointer' }}
            >
              ＜
            </span>
            <Display4 content={ScreenLabels.AnswerDetail} />
            <div>
              <p>
                運転者
                <br />
                車両番号
              </p>
              <p>
                ：山田太郎
                <br />
                ：品川555 あ 88-88
              </p>
            </div>
          </Col>
        </Row>

        {/* 始業時ALCチェック */}
        <Row className="justify-content-center py-1 alc_check_befor">
          <Col lg="12" md="12">
            <div
              className={`mt-2 mb-3 mx-3${
                editEnableStatus[BOX_TYPES.ALC_START] ? ' active' : ''
              }`}
            >
              <Row className="justify-content-between align-items-center row_header">
                <Col className="mt--2">
                  <Row className="justify-content-center">
                    <Col lg="12" md="12">
                      <span className="font_size_10">2022/02/18 08:20</span>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col lg="12" md="12">
                      アルコールチェック-始業
                    </Col>
                  </Row>
                </Col>
                <Col>安全運転管理者：田中正広</Col>
                <Col className="d-flex justify-content-end align-items-center">
                  <Label className="mb-0 mr-3">
                    認証者：
                    <span
                      className={`font-weight-bold${
                        !approverStatus[BOX_TYPES.ALC_START] ? ' opacity-0' : ''
                      }`}
                    >
                      認証者１
                    </span>
                  </Label>
                  <Switch
                    onChange={(e) => handleChange(e, BOX_TYPES.ALC_START)}
                    disabled={editEnableStatus[BOX_TYPES.ALC_START]}
                    checked={approverStatus[BOX_TYPES.ALC_START]}
                    width={70}
                    borderRadius={14}
                    onColor="#28a745"
                    offColor="#cdd6dd"
                    handleDiameter={20}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center py-1">
                <Col className="d-flex align-items-center justify-content-end answer-btn-group">
                  <button
                    type="submit"
                    onClick={() => cancelEdit(BOX_TYPES.ALC_START)}
                    className="btn-cancel"
                  >
                    キャンセル
                  </button>
                  <button
                    type="submit"
                    onClick={() => saveEditButton(BOX_TYPES.ALC_START)}
                    className="btn-save"
                  >
                    保存
                  </button>
                  <span
                    onClick={() => onDeleteButton(BOX_TYPES.ALC_START)}
                    className={
                      approverStatus[BOX_TYPES.ALC_START] ? 'opacity-5' : ''
                    }
                  >
                    <TrashIcon />
                  </span>
                  <span
                    onClick={() => editEnable(BOX_TYPES.ALC_START)}
                    className={
                      approverStatus[BOX_TYPES.ALC_START] ? 'opacity-5' : ''
                    }
                  >
                    <EditIcon />
                  </span>
                </Col>
              </Row>
              <Row className="mt-lg--3 px-lg-2 answer-detail-input">
                <Col lg="7" md="7">
                  {start_alc_check_answers.map((item) => (
                    <Row key={item.key} className="align-items-center">
                      <Label for={item.key} sm={5}>
                        {item.value}
                      </Label>
                      <Col sm={5}>
                        <Input
                          id={item.key}
                          name={item.key}
                          type="text"
                          defaultValue={item.result}
                          onChange={changeStartAlc}
                        />
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        {/* 終業時ALCチェック */}
        <Row className="justify-content-center py-1 alc_check_befor">
          <Col lg="12" md="12">
            <div
              className={`mt-2 mb-3 mx-3${
                editEnableStatus[BOX_TYPES.ALC_END] ? ' active' : ''
              }`}
            >
              <Row className="justify-content-between align-items-center row_header">
                <Col className="mt--2">
                  <Row className="justify-content-center">
                    <Col lg="12" md="12">
                      <span className="font_size_10">2022/02/18 08:20</span>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col lg="12" md="12">
                      アルコールチェック-終業
                    </Col>
                  </Row>
                </Col>
                <Col>安全運転管理者：田中正広</Col>
                <Col className="d-flex justify-content-end align-items-center">
                  <Label className="mb-0 mr-3">
                    認証者：
                    <span
                      className={`font-weight-bold${
                        !approverStatus[BOX_TYPES.ALC_END] ? ' opacity-0' : ''
                      }`}
                    >
                      認証者１
                    </span>
                  </Label>
                  <Switch
                    onChange={(e) => handleChange(e, BOX_TYPES.ALC_END)}
                    disabled={editEnableStatus[BOX_TYPES.ALC_END]}
                    checked={approverStatus[BOX_TYPES.ALC_END]}
                    width={70}
                    borderRadius={14}
                    onColor="#28a745"
                    offColor="#cdd6dd"
                    handleDiameter={20}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center py-1">
                <Col className="d-flex align-items-center justify-content-end answer-btn-group">
                  <button
                    type="submit"
                    onClick={() => cancelEdit(BOX_TYPES.ALC_END)}
                    className="btn-cancel"
                  >
                    キャンセル
                  </button>
                  <button
                    type="submit"
                    onClick={() => saveEditButton(BOX_TYPES.ALC_END)}
                    className="btn-save"
                  >
                    保存
                  </button>
                  <span
                    onClick={() => onDeleteButton(BOX_TYPES.ALC_END)}
                    className={
                      approverStatus[BOX_TYPES.ALC_END] ? 'opacity-5' : ''
                    }
                  >
                    <TrashIcon />
                  </span>
                  <span
                    onClick={() => editEnable(BOX_TYPES.ALC_END)}
                    className={
                      approverStatus[BOX_TYPES.ALC_END] ? 'opacity-5' : ''
                    }
                  >
                    <EditIcon />
                  </span>
                </Col>
              </Row>
              <Row className="mt-lg--3 px-lg-2 answer-detail-input">
                <Col lg="7" md="7">
                  {end_alc_check_answers.map((item) => (
                    <Row key={item.key} className="align-items-center">
                      <Label for={item.key} sm={5}>
                        {item.value}
                      </Label>
                      <Col sm={5}>
                        <Input
                          id={item.key}
                          name={item.key}
                          type="text"
                          defaultValue={item.result}
                          onChange={changeEndAlc}
                        />
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        {/* 車両点検 */}
        <Row className="justify-co*4ntent-center py-1 alc_check_befor">
          <Col lg="12" md="12">
            <div
              className={`mt-2 mb-3 mx-3${
                editEnableStatus[BOX_TYPES.INSPECTION] ? ' active' : ''
              }`}
            >
              <Row className="justify-content-between align-items-center row_header">
                <Col className="mt--2">
                  <Row className="justify-content-center">
                    <Col lg="12" md="12">
                      <span className="font_size_10">2022/02/18 08:20</span>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col lg="12" md="12">
                      点検{' '}
                      <small className="ml-2">
                        パターン：<strong>日常点検</strong>
                      </small>
                    </Col>
                  </Row>
                </Col>
                <Col>安全運転管理者：田中正広</Col>
                <Col className="d-flex justify-content-end align-items-center">
                  <Label className="mb-0 mr-3">
                    認証者：
                    <span
                      className={`font-weight-bold${
                        !approverStatus[BOX_TYPES.INSPECTION]
                          ? ' opacity-0'
                          : ''
                      }`}
                    >
                      認証者１
                    </span>
                  </Label>
                  <Switch
                    onChange={(e) => handleChange(e, BOX_TYPES.INSPECTION)}
                    disabled={editEnableStatus[BOX_TYPES.INSPECTION]}
                    checked={approverStatus[BOX_TYPES.INSPECTION]}
                    width={70}
                    borderRadius={14}
                    onColor="#28a745"
                    offColor="#cdd6dd"
                    handleDiameter={20}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center py-1">
                <Col className="d-flex align-items-center justify-content-end answer-btn-group">
                  <button
                    type="submit"
                    onClick={() => cancelEdit(BOX_TYPES.INSPECTION)}
                    className="btn-cancel"
                  >
                    キャンセル
                  </button>
                  <button
                    type="submit"
                    onClick={() => saveEditButton(BOX_TYPES.INSPECTION)}
                    className="btn-save"
                  >
                    保存
                  </button>
                  <span
                    onClick={() => onDeleteButton(BOX_TYPES.INSPECTION)}
                    className={
                      approverStatus[BOX_TYPES.INSPECTION] ? 'opacity-5' : ''
                    }
                  >
                    <TrashIcon />
                  </span>
                  <span
                    onClick={() => editEnable(BOX_TYPES.INSPECTION)}
                    className={
                      approverStatus[BOX_TYPES.INSPECTION] ? 'opacity-5' : ''
                    }
                  >
                    <EditIcon />
                  </span>
                </Col>
              </Row>
              <Row className="mt-lg--3 px-lg-2 answer-detail-input answer-category-detail">
                <Col lg="8" md="12">
                  {inspection_answers.map((item) => (
                    <Row key={item.key} className="align-items-center">
                      <Col sm={4}>
                        <span
                          className="category-name"
                          style={{ backgroundColor: item.color }}
                        >
                          {item.categorie}
                        </span>
                      </Col>
                      <Label for={item.key} sm={4}>
                        {item.value}
                      </Label>
                      <Col sm={4}>
                        <Input
                          id={item.key}
                          name={item.key}
                          type="text"
                          defaultValue={item.result}
                          onChange={changeInspection}
                        />
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <LogTable logs={operationLog} />
      </Container>
    </div>
  )
}

export default TableCard
