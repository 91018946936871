import { IStoreCache } from 'common/types/auth_provider'
import { Confirm as TConfirm } from 'common/types/confirm'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { logoutUser } from 'services/login'
import { modalService } from 'services/modal'

const Confirm: React.VFC = () => {
  const [modal, setModal] = useState<TConfirm>({
    isShow: false,
    message: '',
    onOk: () => {},
  })
  const history = useHistory()
  const storeCache: IStoreCache = useContext(AuthContext).storeCache

  useEffect(() => {
    const subscription = modalService
      .onModal()
      .subscribe((modalObj: TConfirm) => setModal(modalObj))
    return () => subscription.unsubscribe()
  }, [])

  const close = () => modalService.close(modal)
  const ok = () => {
    close()
    if (modal?.buttonText === 'ログイン画面へ') {
      logout()
    }
    modal.onOk()
  }
  const logout = (): Promise<void> => logoutUser(storeCache, history)

  const modalSecondBtn = (
    <Button color="secondary" onClick={close}>
      キャンセル
    </Button>
  )

  return (
    <Modal isOpen={modal.isShow} centered>
      <ModalBody>{modal.message}</ModalBody>
      <ModalFooter
        style={
          modal.hideSecondBtn
            ? { justifyContent: 'center' }
            : { borderTop: 'unset' }
        }
      >
        {!modal.hideSecondBtn && modalSecondBtn}
        <Button color="primary" onClick={ok}>
          {modal?.buttonText || 'OK'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Confirm
