import Feedback from 'components/atoms/Feedback/Feedback'
import React from 'react'
import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'

type TTextForm = {
  name: string
  label: string
  formCol?: number
  control: Control<any, object>
  rules?: Omit<
    RegisterOptions<FieldValues>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >
  error?: FieldError
}

const TextForm: React.VFC<TTextForm> = (props: TTextForm) => {
  const { name, label, formCol, control, rules, error } = props
  return (
    <div className="mb-4">
      <FormGroup className="d-flex align-items-center mb-0">
        <Label>{label}</Label>
        <Controller
          name={name}
          control={control}
          rules={rules ?? undefined}
          render={({ field }) => (
            <Input type="text" className={error && 'is-invalid'} {...field} />
          )}
        />
      </FormGroup>
      <Row>
        <Col sm={2} />
        <Col>
          <Feedback error={error} />
        </Col>
      </Row>
    </div>
  )
}

export default TextForm
