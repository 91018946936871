import React, { useState, useContext } from 'react'
import { PreworkContext } from 'providers/PreworkProvider'
import { Car, IPreworkProvider } from 'common/types/prework_provider'
import { UncontrolledPopover, PopoverBody } from 'reactstrap'
import { IAuthProvider } from 'common/types/auth_provider'
import { AuthContext } from 'providers/AuthProvider'
import { AchievementDetailModal } from 'common/types/achievement'
import moment from 'moment'
import { getCarList } from 'repositories/vehicle'
import EditableText from '../Form/Text/EditableText'
import LoadingIndicator from '../LoadingIndicator'
import IconTickCheck from '../Img/IconTickCheck'
import IconTickNone from '../Img/IconTickNone'

type Props = {
  data: any
  toggleModalDetail: (achievement_id: AchievementDetailModal) => void
  isModalDetailOpen: boolean
}

const TableRow: React.VFC<Props> = ({ data, toggleModalDetail }) => {
  const { handleEdit }: IPreworkProvider = useContext(PreworkContext)
  const {
    storeCache: { loginInfo, token },
    todayCar,
  }: IAuthProvider = useContext(AuthContext)

  const [carList, setCarList] = useState<Car[]>([])
  const [options, setOptions] = useState<any[]>([])
  const [loadingCar, setLoadingCar] = useState<boolean>(false)

  const getCarListData = async () => {
    const dt = new Date(data?.original?.confirm_date)
    const year = dt.getFullYear().toString()
    const month = ('0' + (dt.getMonth() + 1)).slice(-2)
    const day = dt.getDate().toString().padStart(2, '0')
    const hour = dt.getHours().toString().padStart(2, '0')
    const minutes = dt.getMinutes().toString().padStart(2, '0')
    const seconds = dt.getSeconds().toString().padStart(2, '0')
    const confirmDate = year + '/' + month + '/' + day
    if (confirmDate === moment().format('YYYY/MM/DD')) {
      setCarList(todayCar)
      const option = todayCar.map((e) => ({
        label: e.name,
        value: e.driveRecorderId,
        ...e,
      }))
      setOptions(option)
      return
    }
    if (carList.length > 0) {
      const option = carList.map((e) => ({
        label: e.name,
        value: e.driveRecorderId,
        ...e,
      }))
      setOptions(option)
      return
    }
    setLoadingCar(true)
    const checkDate = year + month + day + 'T' + hour + minutes + seconds + '+0900'
    try {
      const res = await getCarList(token, {
        kaisyaCode: loginInfo?.kaisyaCode || '',
        ncsUserId: loginInfo?.ncsUserId || '',
        keyword: '',
        checkDate: checkDate,
      })
      setCarList(res.carList)
      const option = res.carList.map((e) => ({
        label: e.name,
        value: e.driveRecorderId,
        ...e,
      }))
      setOptions(option)
      setLoadingCar(false)
    } catch (error) {
      setLoadingCar(false)
    }
  }

  const onBlur = () => {
    setOptions([])
  }

  const onOpenDetail = () => {
    toggleModalDetail({
      achievement_id: data.original.achievement_id,
      user_soshiki_name: data.original.user_soshiki_name || '',
      car_soshiki_name: data.original.car_soshiki_name || '',
      user_name: data.original.user_name,
      car_name: data.original.car_name,
      checklogs_user_name: data.original.checklogs_user_name,
      checklogs_kakunin_id: data.original.checklogs_kakunin_id,
    })
  }

  return (
    <>
      {loadingCar && <LoadingIndicator />}
      <div className="wrap-row" style={{ display: 'flex' }}>
        {data.cells.map((cell: any, index: number) => {
          return (
            <div
              className={`table-column`}
              key={`${data.original.achievement_id}-${index}`}
            >
              {(index === 1 &&
                (loginInfo?.permission !== '0' && data.original?.editable ? (
                  <EditableText
                    value={cell.value}
                    options={options}
                    onOpenOptions={getCarListData}
                    onBlur={onBlur}
                    className="edit-high-light"
                    onChange={(value) => {
                      // console.log('onChangeCar:', value)
                      handleEdit(data.original.achievement_id, 'car_name', value)
                    }}
                    isGray={!data.original.car_soshiki_name}
                  />
                ) : (
                  <span
                    className={`table-column-text ${
                      !data.original.car_soshiki_name ? 'gray-text' : ''
                    }`}
                  >
                    {cell.render('Cell')}
                  </span>
                ))) ||
                (index === 2 && (
                  <span
                    className="table-column-text"
                    id={`car-name${cell.row.index}`}
                  >
                    {cell.render('Cell')}
                    <UncontrolledPopover
                      trigger="hover"
                      target={`car-name${cell.row.index}`}
                    >
                      <PopoverBody>{cell.render('Cell')}</PopoverBody>
                    </UncontrolledPopover>
                  </span>
                )) ||
                // No.16対応：indexを燃料、充電量のカラムのindex:8,9に変更
                ((index === 0 || index === 5 || index === 8 || index === 9) && (
                  <span>{cell.render('Cell')}</span>
                )) ||
                ((index === 6) && (
                  <span
                    // No.8対応：点検パターン名が長くなった場合に改行
                    style={{ overflow: 'hidden' }}
                    id={`${cell.column.id}-${cell.row.index}`}
                  >
                    {cell.render('Cell')}
                    <UncontrolledPopover
                      trigger="hover"
                      target={`${cell.column.id}-${cell.row.index}`}
                    >
                      <PopoverBody>{cell.render('Cell')}</PopoverBody>
                    </UncontrolledPopover>
                  </span>
                )) ||
                ((index === 3 || index === 4) && (
                  <span
                    className={`table-column-text  ${
                      cell.column.id === 'user_name' &&
                      !data.original.user_soshiki_name
                        ? 'gray-text'
                        : ''
                    }`}
                    id={`${cell.column.id}-${cell.row.index}`}
                  >
                    {cell.render('Cell')}
                    
                    <UncontrolledPopover
                      trigger="hover"
                      target={`${cell.column.id}-${cell.row.index}`}
                    >
                      <PopoverBody>{cell.render('Cell')}</PopoverBody>
                    </UncontrolledPopover>
                  </span>
                )) ||
                (index === 7 && (
                  <div
                    onClick={onOpenDetail}
                    className={`${
                      cell.value === '画像相違あり'
                        ? 'inspection-result-2'
                        : 'inspection-result'
                    }`}
                  >
                    {cell.render('Cell')}
                  </div>
                )) ||
                // No.16対応：燃料充電量追加に伴い、index:10に変更(確認カラムの順番)
                (index === 10 &&
                  (!data.original.achievement_id ||
                  loginInfo?.permission === '0' ||
                  !data.original.confirm_date ? (
                    <IconTickNone fill="#E9ECEF" color="#E9ECEF" />
                  ) : (
                    <div
                      className={`prework-table-column-checkbox`}
                      style={{ display: 'flex' }}
                      onClick={() => {
                        if (
                          !(
                            data.original.checklogs_status &&
                            data.original?.checklogs_ncs_user_id &&
                            loginInfo?.ncsUserId !==
                              data.original.checklogs_ncs_user_id
                          )
                        ) {
                          handleEdit(
                            data.original.achievement_id,
                            'checklogs_status',
                            !cell.value
                          )
                        }
                      }}
                    >
                      {cell.value ? <IconTickCheck /> : <IconTickNone />}
                    </div>
                  ))) || (
                  <span className="table-column-text">{cell.render('Cell')}</span>
                )}
            </div>
          )
        })}
      </div>
    </>
  )
}
export default TableRow
