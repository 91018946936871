import React, { useContext, useEffect, useState } from 'react'
import TableCard from 'components/organisms/Admin/Alcohol/TableCard'
import Topbar from 'components/molecules/Topbar/Topbar'
import Sidebar from 'components/molecules/Sidebar/Sidebar'
import { ScreenLabels } from 'common/enums'
import { AlcoholContext, AlcoholProvider } from 'providers/AlcoholProvider'
import { IAlcoholProvider } from 'common/types/alcohol_provider'
import LoadingIndicator from 'components/atoms/LoadingIndicator'
import { Prompt, useHistory } from 'react-router-dom'
import { customModalService } from 'services/customModal'

const Alcohol: React.VFC = () => {
  const {
    onApplyFilter,
    isLoading,
    editedData,
    registerAchievements,
    resetRegisterAchievements,
  }: IAlcoholProvider = useContext(AlcoholContext)
  const history = useHistory()
  const [nextPath, setNextPath] = useState<string>('')

  useEffect(() => {
    const hanleClosePage = (e: BeforeUnloadEvent) => {
      e.returnValue = ''
    }
    if (editedData.length) {
      window.addEventListener('beforeunload', hanleClosePage)
    }
    return () => {
      window.removeEventListener('beforeunload', hanleClosePage)
    }
  }, [editedData])

  useEffect(() => {
    if (nextPath) {
      setNextPath('')
      history.push(nextPath)
    }
  }, [nextPath])

  const handleBlockedNavigation = (nextLocation: any) => {
    if (
      (editedData?.length > 0 && !nextPath) ||
      registerAchievements?.length > 0
    ) {
      customModalService.show(
        '変更データが未登録ですがよろしいですか？',
        '',
        'はい',
        'いいえ',
        () => {
          resetRegisterAchievements()
          setNextPath(nextLocation.pathname)
        }
      )
      return false
    }
    return true
  }

  return (
    <div className="page-container">
      {isLoading && <LoadingIndicator />}
      <Topbar title={ScreenLabels.AlcoholCheck} editData={editedData} />
      <div className="table-container">
        <Sidebar onFilter={onApplyFilter} type="2" editData={editedData} />
        <TableCard />
      </div>
      <Prompt message={handleBlockedNavigation} />
    </div>
  )
}

const AlcoholWrap: React.VFC = () => (
  <AlcoholProvider>
    <Alcohol />
  </AlcoholProvider>
)

export default AlcoholWrap
