import { format, isBefore } from 'date-fns'

/**
 * 現在時刻を取得
 *
 * @returns string
 */
export const now = (): string => format(new Date(), 'yyyy-MM-dd HH:mm')

/**
 * 日付をフォーマット
 *
 * @param date string
 * @param date string | undefined
 * @returns string
 */
export function dateFormat(date: string, formatStr?: string): string {
  return format(new Date(date), formatStr ?? 'yyyy-MM-dd HH:mm')
}

/**
 * @param date string
 * @returns now Timestamp
 */
export function isBeforeNow(date: string): boolean {
  return isBefore(new Date(), new Date(date))
}
