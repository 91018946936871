import React from 'react'
import { Table } from 'reactstrap'
import {
  TableBodyProps,
  TableBodyPropGetter,
  TableProps,
  TablePropGetter,
  HeaderGroup,
  Row,
} from 'react-table'

type TStandardTable = {
  getTableProps: (propGetter?: TablePropGetter<any> | undefined) => TableProps
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<any> | undefined
  ) => TableBodyProps
  headerGroups: HeaderGroup<any>[]
  rows: Row<any>[]
  prepareRow: (row: Row<any>) => void
}

const StandardTable: React.VFC<TStandardTable> = (props: TStandardTable) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    props
  return (
    <Table
      className="align-items-center table-bordered table-hover"
      responsive
      {...getTableProps()}
    >
      <thead className="thead-light">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                scope="col"
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                <span>{sortStr(column)}</span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()} key={i}>
              {row.cells.map((cell) => {
                return (
                  <th scope="row" {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </th>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

const sortStr = (column: HeaderGroup<any>): string => {
  if (!column.isSorted) return ''
  return column.isSortedDesc ? ' 🔽' : ' 🔼'
}

export default StandardTable
