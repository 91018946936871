import { post } from 'common/fetch'
import {
  InformationGetRes
} from 'common/types/information'
import { alertService } from 'services/alert'

const fetchPath: string = '/api/information-get'

/**
 * Get All Data.
 */
export async function fetchAll(
  token: string
): Promise<InformationGetRes> {
  return post<InformationGetRes>(token, fetchPath)
    .then((result) => {
      if (result.status == "-1") {
        alertService.show(
          false,
          'システムエラーが発生しました。しばらく時間をおいてから再アクセスするか、お問い合わせください。（お知らせ取得）'
        )
        return {
          status: '-1',
          message: '',
          result: '',
        }
      }
      return result
    })
}