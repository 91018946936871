import React from 'react'
import { NavLink as NavLinkRRD } from 'react-router-dom'
import { NavItem, NavLink } from 'reactstrap'
import 'styles/itemlink.scss'

type TItemLink = {
  setCollapseOpen: React.Dispatch<React.SetStateAction<boolean>>
  nextPage: string
  content: string
  asNewTab?: boolean
  iconType: string
  className?: string
}

const ItemLink: React.VFC<TItemLink> = (props: TItemLink) => {
  const { setCollapseOpen, nextPage, content, asNewTab, iconType, className } =
    props
  return (
    <NavItem>
      <NavLink
        to={nextPage}
        tag={NavLinkRRD}
        target={asNewTab ? '_blank' : undefined}
        onClick={() => setCollapseOpen(false)}
        className={className}
      >
        <img src={iconType} className="menu-icon" alt="" />
        <span>{content}</span>
      </NavLink>
    </NavItem>
  )
}

export default ItemLink
