import React, { useEffect, useRef, useState } from 'react'

interface OptionItem {
  value: string
  label: string
}

interface SProps {
  value: string
  options: OptionItem[]
  onChange: (value: any) => void
  onBlur: () => void
}

const Suggestion: React.VFC<SProps> = (props) => {
  const { options, onChange, onBlur, value } = props
  const ref = useRef<HTMLInputElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const [input, setInput] = useState<string>(value)
  const [dirty, setDirty] = useState<boolean>(input !== 'ダブルクリックで選択')
  const [focused, setFocused] = React.useState(false)
  const onFocusInput = () => setFocused(true)
  const onBlurInput = () => {
    setFocused(false)
    onBlur()
  }

  const onChangeValue = (option: OptionItem) => {
    onChange(option)
    onBlur()
  }

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value)
    if (!dirty) {
      setDirty(true)
    }
  }

  const suggests = dirty
    ? options.filter((option) => option.label.includes(input))
    : options

  useEffect(() => {
    if (options.length > 0) {
      ref.current?.focus()
    }
  }, [options])

  const handleClickOutside = (e: any) => {
    if (wrapperRef.current && !wrapperRef.current?.contains(e.target)) {
      onBlurInput()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  return (
    <div className="input-wrap" ref={wrapperRef}>
      <input
        type="text"
        onChange={onChangeText}
        value={input === 'ダブルクリックで選択' ? '' : input}
        placeholder={input === 'ダブルクリックで選択' ? input : ''}
        onFocus={onFocusInput}
        className={`suggest-input ${focused ? 'input-focus' : ''}`}
        ref={ref}
      />
      <div className="suggestions-dropdown">
        {suggests.length > 0 ? (
          suggests.map((option) => (
            <div
              className="suggest-item"
              onClick={() => {
                onChangeValue(option)
              }}
              key={option.value}
            >
              {option.label}
            </div>
          ))
        ) : (
          <div className="no-suggest">候補なし</div>
        )}
      </div>
    </div>
  )
}

export default Suggestion
