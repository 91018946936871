import React, { useState, useContext, ReactNode } from 'react'
import { Modal, Button, Form, Col, Row } from 'reactstrap'
import { IAlcoholProvider } from 'common/types/alcohol_provider'
import { AlcoholContext } from 'providers/AlcoholProvider'
import { customModalService } from 'services/customModal'
import ConditionItems from 'components/molecules/Admin/AchievementRegister/ConditionItems'
import AchievementItems from 'components/molecules/Admin/AchievementRegister/AchievementItems'
import moment from 'moment'

type TAchievementRegisterModal = {
  onClose: () => void
}

const AlcoholAchievementRegisterModal: React.VFC<TAchievementRegisterModal> = ({
  onClose,
}: TAchievementRegisterModal) => {
  const {
    registerConditions,
    addAchievement,
    registerAlcoholValidate,
    requiredValidation,
  }: IAlcoholProvider = useContext(AlcoholContext)
  const [creatableAchievement, setCreatableAchievement] =
    useState<boolean>(true)
  const [editableCondition, setEditableCondition] = useState<boolean>(false)

  const onSubmit = () => {
    if (!requiredValidation()) return
    customModalService.show(
      '実績を追加しますか？',
      confirmText(),
      'はい',
      'いいえ',
      confirmAddAchievement
    )
  }

  const onClickClose = () => {
    if (registerConditions.carName || registerConditions.ncsUserName) {
      customModalService.show(
        '変更データが未登録ですがよろしいですか？',
        '',
        'はい',
        'いいえ',
        onClose
      )
    } else {
      onClose()
    }
  }

  const confirmAddAchievement = () => {
    addAchievement()
    onClose()
  }

  const confirmText = (): ReactNode => {
    return (
      <div>
        <div className="mb-3">
          追加したアルコールチェック結果は、変更や削除ができませんので、誤りがないか、ご確認をお願いします。
        </div>
        <Row>
          <Col sm={3}>実施日時</Col>
          <Col sm={1}>：</Col>
          <Col sm={8}>{moment(registerConditions.confirmDate).format('YYYY/MM/DD HH:mm')}</Col>
        </Row>
        <Row>
          <Col sm={3}>運転者</Col>
          <Col sm={1}>：</Col>
          <Col sm={8}>{registerConditions.ncsUserName}</Col>
        </Row>
        <Row>
          <Col sm={3}>車両番号</Col>
          <Col sm={1}>：</Col>
          <Col sm={8}>{registerConditions.carName}</Col>
        </Row>
      </div>
    )
  }

  const onChangeCreatableAchievement = () => {
    if (registerConditions.ncsUserName && registerConditions.carName) {
      setCreatableAchievement(!creatableAchievement)
      setEditableCondition(!editableCondition)
    }
  }

  return (
    <>
      <Modal isOpen={true} centered className="inspection-modal">
        <div className="modal-inspection-container">
          <div className="modal-inspection">
            <div className="close-wrap">
              <div className="close" onClick={onClickClose} />
            </div>
            <div className="modal-inspection-header">
              <span>アルコールチェック実績追加</span>
            </div>
            <div className="modal-alcohol-container">
              <div className="modal-alcohol-body">
                <Form>
                  <ConditionItems
                    onChangeCreatableAchievement={onChangeCreatableAchievement}
                    disabled={editableCondition}
                  />
                  <AchievementItems disabled={creatableAchievement} />
                </Form>
              </div>
              <div className="ml-4 mb-1 achievement-register-error-msg">
                {registerAlcoholValidate.errorSubmit}
              </div>
              <div className="modal-alcohol-footer-2">
                <button
                  type="button"
                  className="close-modal-button"
                  onClick={onClickClose}
                >
                  閉じる
                </button>
                <Button
                  color="primary"
                  className="button-register"
                  onClick={onSubmit}
                  disabled={creatableAchievement}
                >
                  入力完了
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AlcoholAchievementRegisterModal
