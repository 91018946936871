import React, { useState, useContext, useEffect } from 'react'
import 'styles/topbar.scss'
import { AuthContext } from 'providers/AuthProvider'
import { IStoreCache } from 'common/types/auth_provider'
import { logoutUser } from 'services/login'
import { useHistory } from 'react-router'
import { IAlcoholProvider } from 'common/types/alcohol_provider'
import { AlcoholContext } from 'providers/AlcoholProvider'

import NCSLOGO from 'assets/img/ncs-logo.svg'
import { customModalService } from 'services/customModal'

interface Props {
  title: string
  editData?: any[]
}

const ArrowImg = () => {
  return (
    <img
      alt=""
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAHCAYAAADebrddAAAABHNCSVQICAgIfAhkiAAAAKhJREFUGFdjXLRppyLjn3+LmJlZQ6L8XV4yoIFlG/eI//37e81/FqY4xiXrth1kYGS0+8/w/xYzE5sdsoaFm/dKM//+uR8orwo04wDj8vXbFf7+/38YKCDD8P//vb+s7Hbxvs5PoQoPAcWVgOIPgeLWjCBb0TQ8/M/EHMX47+8yoEJ5mEKQAWDFGBpgglATQQpBQnDF2GwAWQ1TiKEYScMSoMJwZIUgOQBGMmUrm0QQugAAAABJRU5ErkJggg=="
    />
  )
}

const Topbar: React.VFC<Props> = (props) => {
  const storeCache: IStoreCache = useContext(AuthContext).storeCache

  const [isUserClick, setUserClick] = useState<boolean>(false)

  const { registerAchievements }: IAlcoholProvider = useContext(AlcoholContext)

  const handleChange = () => {
    setUserClick(!isUserClick)
  }
  const history = useHistory()

  const onClickLogout = () => {
    if (
      props.editData &&
      props.editData.length > 0 ||
      registerAchievements?.length > 0
    ) {
      customModalService.show(
        '変更データが未登録ですがログアウトしますか?',
        '',
        'はい',
        'いいえ',
        async () => { await logout() }
      )
    } else {
      logout()
    }
  }

  const logout = (): Promise<void> => logoutUser(storeCache, history)

  return (
    <>
      <div className="topbar">
        <a href="/answer" className="topbar-logo">
          <img src={NCSLOGO} alt="" className="topbar-img" />
        </a>
        <div className="topbar-main d-flex justify-content-between align-items-center">
          <span className="top-title">{props.title}</span>
          <div className="wrap-right-title-answer">
            <div className="menu-header">
              <span>{storeCache.loginInfo?.kaisyaName}</span>
              <span>{storeCache.loginInfo?.soshikiPathName}</span>
              <span>
                {storeCache.loginInfo?.permission === '0'
                  ? '[運転者]'
                  : storeCache.loginInfo?.permission === '1'
                  ? '[管理者]'
                  : storeCache.loginInfo?.permission === '2'
                  ? '[設定変更管理者]'
                  : ''}
              </span>
            </div>
            <div className="btn-user" onClick={handleChange}>
              {storeCache.loginInfo?.ncsUserName}
              <ArrowImg />
              {isUserClick && (
                <div onClick={onClickLogout} className="btn-logout">
                  ログアウト
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Topbar
