import { IStoreCache } from 'common/types/auth_provider'
import { SearchForm } from 'common/types/form/Answer/search'
import { getErrorMessage } from 'common/utils'
import { searchAnswer, pageNateAnswer } from 'providers/AuthProvider'
import {
  fetchGetAnswerJson,
  fetchGetPdfData,
  fetchGetAlcPdfData,
  fetchSaveEdit,
  fetchRemove,
  fetchApproval,
  fetchGetOperationLog,
} from 'repositories/answer'
import { AnswerDetail } from 'common/types/answer/answer_detail'
import { PdfData } from 'common/types/pdf/pdf_data'
import { AnswerAlc } from 'common/types/pdf/answer_alc'
import { OperationLog } from 'common/types/answer/operation_log'
import { AnswerJson } from 'common/types/answer/answer_json'
import { modalService } from 'services/modal'
import { AlcCheckItem, BOX_TYPES } from './answer_detail'

/**
 * SearchForm初期値設定
 */
export function initSearchForm(): SearchForm {
  return {
    select_radio: '0',
    month: {
      label: '全て',
      value: 0,
    },
    from: '',
    to: '',
    unapproved: 'false',
    word: '',
    pages: {
      label: '20',
      value: 20,
    },
  }
}

export async function searchAnswerData(
  storeCache: IStoreCache,
  params: SearchForm
): Promise<void> {
  try {
    // console.log('検索')
    // console.log(params)
    await searchAnswer(storeCache, params)
  } catch (error) {
    getErrorMessage(error)
  }
}

export async function actionPageNate(
  storeCache: IStoreCache,
  url: string | null
): Promise<void> {
  try {
    // console.log('ページネーション')
    const page: string | undefined = url?.split('?')[1].substr(5)
    // console.log(page)
    await pageNateAnswer(storeCache, page)
  } catch (error) {
    getErrorMessage(error)
  }
}

export async function getAnswerJson(
  { token }: IStoreCache,
  id: string,
  setStartId: React.Dispatch<React.SetStateAction<number>>,
  setInspectionId: React.Dispatch<React.SetStateAction<number>>,
  setEndId: React.Dispatch<React.SetStateAction<number>>,
  setAlcCheckBeforeJson: React.Dispatch<
    React.SetStateAction<AnswerJson | undefined>
  >,
  setInspectionJson: React.Dispatch<
    React.SetStateAction<AnswerJson | undefined>
  >,
  setAlcCheckAfterJson: React.Dispatch<
    React.SetStateAction<AnswerJson | undefined>
  >
): Promise<void> {
  try {
    console.log('json検索')
    const jsons: AnswerDetail[] = await fetchGetAnswerJson(token, id)
    jsons.forEach(({ id, type, answers }: AnswerDetail) => {
      const json: AnswerJson = answers
      switch (type) {
        case 10:
          setStartId(id)
          setAlcCheckBeforeJson(json)
          break
        case 1:
          setInspectionId(id)
          setInspectionJson(json)
          break
        case 11:
          setEndId(id)
          setAlcCheckAfterJson(json)
          break
      }
    })
  } catch (error) {
    getErrorMessage(error)
  }
}

export async function getPdfData({
  token,
}: IStoreCache): Promise<PdfData[] | undefined> {
  try {
    console.log('pdfデータ取得')
    const pdfData: PdfData[] = await fetchGetPdfData(token)
    return pdfData
  } catch (error) {
    getErrorMessage(error)
  }
}

export async function getAlcPdfData({
  token,
}: IStoreCache): Promise<AnswerAlc[] | undefined> {
  try {
    console.log('pdfデータ取得')
    const pdfData: AnswerAlc[] = await fetchGetAlcPdfData(token)
    return pdfData
  } catch (error) {
    getErrorMessage(error)
  }
}

export async function saveEdit(
  token: string,
  startAlcCheckAnswers: AlcCheckItem[],
  id: string,
  type: number,
  setAlcCheckBeforeJson: any,
  startId: number,
  inspectionId: number,
  endId: number,
  setOperationLog: React.Dispatch<React.SetStateAction<OperationLog[]>>
): Promise<void> {
  try {
    modalService.show('保存しますか？', async () => {
      const jsons: AnswerDetail = await fetchSaveEdit(
        token,
        startAlcCheckAnswers,
        id,
        type,
        startId
      )
      console.log(jsons)
      setAlcCheckBeforeJson(jsons.answers)
      getOperationLog(token, setOperationLog, startId, inspectionId, endId)
    })
  } catch (error) {
    getErrorMessage(error)
  }
}

export async function onRemove(
  token: string,
  id: string,
  key: BOX_TYPES,
  startId: number,
  inspectionId: number,
  endId: number,
  setOperationLog: React.Dispatch<React.SetStateAction<OperationLog[]>>
): Promise<void> {
  try {
    modalService.show('削除しますか？', async () => {
      switch (key) {
        case BOX_TYPES.ALC_START:
          await fetchRemove(token, id, 10, startId)
          break
        case BOX_TYPES.INSPECTION:
          await fetchRemove(token, id, 1, inspectionId)
          break
        case BOX_TYPES.ALC_END:
          await fetchRemove(token, id, 11, endId)
          break
      }

      getOperationLog(token, setOperationLog, startId, inspectionId, endId)
    })
  } catch (error) {
    getErrorMessage(error)
  }
}

export async function approval(
  token: string,
  id: string,
  approval: boolean,
  key: BOX_TYPES,
  startId: number,
  inspectionId: number,
  endId: number,
  setOperationLog: React.Dispatch<React.SetStateAction<OperationLog[]>>
): Promise<void> {
  try {
    switch (key) {
      case BOX_TYPES.ALC_START:
        await fetchApproval(token, id, 10, approval, startId)
        break
      case BOX_TYPES.INSPECTION:
        await fetchApproval(token, id, 1, approval, startId)
        break
      case BOX_TYPES.ALC_END:
        await fetchApproval(token, id, 11, approval, startId)
        break
    }
    getOperationLog(token, setOperationLog, startId, inspectionId, endId)
  } catch (error) {
    getErrorMessage(error)
  }
}

// 操作ログ取得
export async function getOperationLog(
  token: string,
  setOperationLog: React.Dispatch<React.SetStateAction<OperationLog[]>>,
  startId: number, // answer_id(アルコール_始業のID)
  inspectionId: number, // answer_id(車両点検のID)
  endId: number // answer_id(アルコール_終業のID)
): Promise<void> {
  try {
    setOperationLog(
      await fetchGetOperationLog(token, startId, inspectionId, endId)
    )
  } catch (error) {
    getErrorMessage(error)
  }
}
