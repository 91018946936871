import React from 'react'

type TFeedback = {
  error: any
}

const Feedback: React.VFC<TFeedback> = (props: TFeedback) => {
  const { error } = props
  if (!error) return <></>
  return <div className="error-txt" style={{ color: 'red' }}>※{error.message}</div>
}

export default Feedback
