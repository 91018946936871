import { alertService } from 'services/alert'
import { InputType as InputTypeEnum } from 'common/enums'
import { User } from 'common/types/user'
import { InputType } from 'reactstrap/es/Input'
import { isBeforeNow } from 'common/time'
import { PdfData } from 'common/types/pdf/pdf_data'
import { Item } from 'common/types/pdf/item'
import { Answer } from 'common/types/pdf/answer'

/**
 * 値がnullの場合、空文字に変換する
 */
export function null2str(val: string | number | null | undefined): string {
  return val === null || val === undefined ? '' : String(val)
}

export function nullToStr(val: string | number | null | undefined) {
  return val === null || val === undefined ? '' : val
}
export function nullToNum(val: string | number | null | undefined) {
  return val === null || val === undefined ? 0 : val
}
/**
 * エラーメッセージを識別し返却する
 */
export function getErrorMessage(error: any): void {
  let msg = ''
  if (!error.response) {
    msg = error.message
  } else {
    msg = error.response.data.message
  }
  alertService.show(false, msg)
  console.error(error)
}

/**
 * 必須のフィールドに対するメッセージを生成する
 */
export function createRequiredMessage(field: string): string {
  return `${field}の入力は必須です。`
}

export function createRequiredMessageForSelect(field: string): string {
  return `${field}選択は必須です。`
}

/**
 * 文字数の最大数上限のフィールドに対するメッセージを生成する
 */
export function createMaxLengthMessage(
  field: string,
  maxLength: number
): string {
  return `${field}は最大${maxLength}文字まで入力可能です。`
}

/**
 * 形式が異なる場合に対するメッセージを生成する
 */
export function createRegExpMessage(field: string): string {
  return `${field}の形式で入力してください。`
}

/**
 * 形式が異なる場合に対するメッセージを生成する
 */
export function getInputType(inputType: InputTypeEnum): InputType {
  let type: InputType = 'text'
  switch (inputType) {
    case InputTypeEnum.PASSWORD:
      type = 'password'
      break
    case InputTypeEnum.DATE:
      type = 'date'
      break
    case InputTypeEnum.CHECKBOX:
      type = 'checkbox'
      break
  }
  return type
}

/**
 * 形式が異なる場合に対するメッセージを生成する
 */
export function isAdmin(user: User | null): boolean {
  if (!user) return false

  const { switching_at, old_permission, new_permission } = user
  if (isBeforeNow(switching_at)) {
    return old_permission ? old_permission.flag : false
  }
  return new_permission ? new_permission.flag : false
}

/**
 * 横幅を超えるか判定し、超える場合はデータを分割
 *
 * @param data PDF元データ
 * @returns PDF元データ
 */
export async function orver_items(data: PdfData): Promise<PdfData[] | PdfData> {
  /* 横幅を超えるか判定(最大15項目) */

  // カウンター初期化
  let count = 0

  // 項目取得
  const items: Item[] = data.items

  // 総項目数を取得
  items.forEach(async (item: Item) => {
    count += item.inspection_items.length
  })

  // 表示可能最大項目数
  let max_count = 16

  // 15項目を超えていない場合は処理しない
  if (count <= max_count) return data

  /* データを分割 */

  // 項目データ(一時保存用)
  let item_arr: Item[] = []

  // PDF元データ配列
  let PdfData_arr: PdfData[] = []

  // カウンター初期化
  count = 0

  // 横幅を超える箇所でデータを分割
  items.forEach(async (item: Item) => {
    // 項目数を取得
    const items_count = item.inspection_items.length

    // カウンターに項目数を加算
    count += items_count

    // 横幅を超えているかを判定
    // 超えている場合
    if (count > max_count) {
      // 横幅を超えてしまう項目のインデックスを取得
      const index = items_count - (count - max_count)

      if (index > 0) {
        // データを分割し項目データ作成
        const push_data1: Item = {
          category_name: item.category_name,
          inspection_items: item.inspection_items.slice(0, index),
        }

        // 項目データ(一時保存用)に追加
        item_arr.push(push_data1)

        // PDF元データを作成
        const push_PdfData_data: PdfData = {
          inspection_pattern_name: data.inspection_pattern_name,
          answers: data.answers,
          items: item_arr,
        }

        // PDF元データを追加
        PdfData_arr.push(push_PdfData_data)

        // 項目データ(一時保存用)を初期化
        item_arr = []

        // データを分割し項目データ作成
        const push_data2: Item = {
          category_name: item.category_name,
          inspection_items: item.inspection_items.slice(index),
        }

        // 項目データ(一時保存用)に追加
        item_arr.push(push_data2)
      } else {
        // PDF元データを作成
        const push_PdfData_data0: PdfData = {
          inspection_pattern_name: data.inspection_pattern_name,
          answers: data.answers,
          items: item_arr,
        }

        // PDF元データを追加
        PdfData_arr.push(push_PdfData_data0)

        // 項目データ(一時保存用)を初期化
        item_arr = []

        item_arr.push(item)
      }

      // 最大値を更新
      max_count += 16
    } else {
      // 項目データ(一時保存用)に追加
      item_arr.push(item)
    }
  })

  // PDF元データを作成
  const push_PdfData_data_end = {
    inspection_pattern_name: data.inspection_pattern_name,
    answers: data.answers,
    items: item_arr,
  }

  // PDF元データを追加
  PdfData_arr.push(push_PdfData_data_end)

  // 分割されたPDF元データを返却
  return PdfData_arr
}

/**
 * 行数が超えるか判定し、超える場合はデータを分割
 *
 * @param data PDF元データ
 * @returns PDF元データ
 */
export async function orver_lines(data: PdfData): Promise<PdfData[] | PdfData> {
  /* 縦幅を超えるか判定(最大38行) */

  // 行数を取得
  let count = data.answers.length

  // 表示可能最大行数
  let max_count = 37

  // 55行を超えていない場合は処理しない
  if (count <= max_count) return data

  // 表示ページ数取得
  const pages_count = Math.ceil(count / max_count)
  // 回答データ取得
  const answers: Answer[] = data.answers
  // カウンター初期化
  let answes_count = 0
  // PDF元データ
  let return_data: PdfData[] = []

  // ページ数分ループ
  for (let i = 1; i <= pages_count; i++) {
    // 回答配列からページに表示するデータ取得
    const answers_data =
      count < max_count * i
        ? answers.slice(answes_count)
        : answers.slice(answes_count, max_count * i)

    // PDF元データ作成
    const push_data = {
      inspection_pattern_name: data.inspection_pattern_name,
      answers: answers_data,
      items: data.items,
    }
    // 次の取得開始箇所を指定
    answes_count += max_count
    // PDF元データに追加
    return_data.push(push_data)
  }

  // 分割されたPDF元データを返却
  return return_data
}
