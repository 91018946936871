import React, { useEffect, useState } from 'react'
import { Modal } from 'reactstrap'
import 'styles/customModal.scss'
import { ICustomModal } from '../../../common/types/confirm'
import { customModalService } from '../../../services/customModal'

const CustomModal: React.VFC = () => {
  const [modal, setModal] = useState<ICustomModal>({
    isShow: false,
    title: '',
    content: '',
    confirmText: '',
    cancelText: '',
    onConfirm: () => {},
    onCancel: () => {},
  })

  useEffect(() => {
    const subscription = customModalService
      .onModal()
      .subscribe((modalObj: ICustomModal) => setModal(modalObj))
    return () => subscription.unsubscribe()
  }, [])
  const close = () => customModalService.close(modal)

  const onCancel = () => {
    modal.onCancel && modal.onCancel()
    close()
  }
  const onConfirm = () => {
    modal.onConfirm && modal.onConfirm()
    close()
  }

  return (
    <Modal
      className="modal-dialog-centered modal-custom-content"
      isOpen={modal.isShow}
    >
      <div className="modal-custom">
        <div className="modal-custom-header">
          <span>{modal?.title}</span>
          <span onClick={onCancel}></span>
        </div>
        {modal?.content && (
          <div className="modal-custom-body">{modal.content}</div>
        )}
        <div className="modal-custom-footer">
          <button onClick={onCancel} type="button">
            {modal?.cancelText || 'はい'}
          </button>
          <button
            onClick={onConfirm}
            type="button"
            className={modal?.confirmBtnClass}
          >
            {modal?.confirmText || 'いいえ'}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default CustomModal
