import { Routes } from 'common/enums'
import { IStoreCache } from 'common/types/auth_provider'
import { LoginForm, LoginFromDDReq } from 'common/types/form/login'
import { getErrorMessage } from 'common/utils'
import { History } from 'history'
import { reloadCachedToken } from 'providers/AuthProvider'
import {
  getLoginInfo,
  getToken,
  login,
  loginFromDD,
  oneTimeToken,
  sanctumCSRF,
} from 'repositories/login'
import moment from 'moment'
import { alertService } from './alert'

/**
 * LoginForm初期値設定
 */
export function initLoginForm(): LoginForm {
  return { company_code: '', ncs_user_id: '', password: '' }
}

/**
 * ログインする
 */
export async function loginUser(
  dataLogin: LoginForm,
  storeCache: IStoreCache,
  { push }: History
): Promise<void> {
  try {
    const debug = false
    if (debug) await getToken(dataLogin)

    // await sanctumCSRF()
    const response = await login(dataLogin)
    // console.log('login_response:', response)

    const dt = new Date()
    const year = dt.getFullYear().toString()
    const month = ('0' + (dt.getMonth() + 1)).slice(-2)
    const day = dt.getDate().toString().padStart(2, '0')
    const hour = dt.getHours().toString().padStart(2, '0')
    const minutes = dt.getMinutes().toString().padStart(2, '0')

    if (response.status !== 401) {
      const data = await getLoginInfo({
        kaisyaCode: dataLogin.company_code,
        ncsUserId: dataLogin.ncs_user_id,
        driveRecorderId: 'unknown',
        checkDate: year + month + day + 'T' + hour + minutes + '00+0900',
      })
      if (
        (data?.data?.carChkSvc === '0' && data?.data?.alcoholChkSvc === '0') ||
        data?.data?.error
      ) {
        alertService.show(
          false,
          'サービス対象外のため、日常点検管理Webを利用できません'
        )
      } else {
        await localStorage.setItem('token', data?.data?.token)
        await localStorage.setItem('loginInfo', JSON.stringify(data?.data))
        let path = Routes.Answer
        if (data?.data?.carChkSvc === '1') {
          path = Routes.Answer
        } else if (data?.data?.alcoholChkSvc === '1') {
          path = Routes.AlcoholCheck
        }
        push(path)
        reloadCachedToken(storeCache)
      }
    } else {
      alertService.show(
        false,
        '会社コード・ログインID・パスワードのいずれかが違います'
      )
    }
  } catch (error) {
    alertService.show(false, '認証エラーが発生しました。')
  }
}

// 日常点検画面表示
export async function loginDD(
  dataLogin: any,
  storeCache: IStoreCache,
  { push }: History
): Promise<any> {
  try {
    const dt = new Date()
    const year = dt.getFullYear().toString()
    const month = ('0' + (dt.getMonth() + 1)).slice(-2)
    const day = dt.getDate().toString().padStart(2, '0')
    const hour = dt.getHours().toString().padStart(2, '0')
    const minutes = dt.getMinutes().toString().padStart(2, '0')
    // console.log('取得トークンこちら')
    // console.log(dataLogin.token)

    // OneTimrToken変換
    const tokenResponse = await oneTimeToken({
      token: dataLogin.token,
    })

    // console.log('One-Time-変換後')
    // console.log(tokenResponse.data.kaisyaCode)

    // Token check
    const response = await loginFromDD({
      kaisyaCode: tokenResponse.data.kaisyaCode,
      ncsUserId: tokenResponse.data.ncsUserId,
      token: tokenResponse.data.token,
    })

    // In case of Invalid token
    if (response === undefined) {
      console.log('OneTimeTokenが無効です。')
      alertService.show(false, 'OneTimeTokenが無効です。')
      push(Routes.Login)
    }

    if (response?.data?.status == 1) {
      const data = await getLoginInfo({
        kaisyaCode: tokenResponse.data.kaisyaCode,
        ncsUserId: tokenResponse.data.ncsUserId,
        driveRecorderId: 'unknown',
        checkDate: year + month + day + 'T' + hour + minutes + '00+0900',
      })
      if (
        (data?.data?.carChkSvc === '0' && data?.data?.alcoholChkSvc === '0') ||
        data?.data?.error
      ) {
        alertService.show(
          false,
          'サービス対象外のため、日常点検管理Webを利用できません'
        )
      } else {
        await localStorage.setItem('token', data?.data?.token)
        await localStorage.setItem('loginInfo', JSON.stringify(data?.data))
        let path = Routes.Answer
        if (data?.data?.carChkSvc === '1') {
          path = Routes.Answer
        } else if (data?.data?.alcoholChkSvc === '1') {
          path = Routes.AlcoholCheck
        }
        push(path)
        reloadCachedToken(storeCache)
      }
    } else {
      console.log('ZDC-Tokenが無効です。')
      alertService.show(
        false,
        'システムエラーが発生しました。再度ログインをしてください。'
      )
      push(Routes.Login)
    }
  } catch (err) {
    console.log(err)
  }
}

/**
 * ログアウトする
 */
export async function logoutUser(
  storeCache: IStoreCache,
  { push }: History
): Promise<void> {
  try {
    localStorage.removeItem('token')
    reloadCachedToken(storeCache)
    push(Routes.Login)
  } catch (error) {
    console.log('error' + error)
    getErrorMessage(error)
  }
}
