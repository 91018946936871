import axios, { AxiosResponse } from 'axios'
import { modalService } from 'services/modal'
import { alertService } from 'services/alert'

const client = axios.create({ withCredentials: true })

client.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      modalService.show(
        'セッションが無効化されました。全ての画面を閉じて、ログイン画面からログインしてください。',
        () => {},
        true,
        'ログイン画面へ'
      )
    }
    if (
      error.response.status === 500 ||
      error.response.status === 502 ||
      error.response.status === 503
    ) {
      alertService.show(
        false,
        'システムエラーが発生しました。しばらく時間をおいてから再アクセスするか、お問い合わせください。',
      )
    }
  }
)

/**
 * Request Method: GET
 */
export async function get<T>(
  token: string,
  path: string,
  params: any = {}
): Promise<T> {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }
  return (await client.get<T>(path, { headers, params, withCredentials: true }))
    .data
}

/**
 * Request Method: POST
 */
export async function post<T>(
  token: string,
  path: string,
  data: any = {}
): Promise<T> {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }
  return (await client.post<T>(path, data, { headers, withCredentials: true }))
    .data
}

/**
 * Request Method: PATCH
 */
export async function patch<T>(
  token: string,
  path: string,
  data: any = {}
): Promise<T> {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }
  return (await client.patch<T>(path, data, { headers })).data
}

/**
 * Request Method: DELETE
 */
export async function destroy<T>(
  token: string,
  path: string,
  params: any = {}
): Promise<T> {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }
  return (await client.delete(path, { headers, params })).data
}

/**
 * Request Method: GET
 */
export async function getCsv(
  token: string,
  path: string
): Promise<AxiosResponse<ArrayBuffer>> {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    Accept: 'application/zip',
  }
  return (
    await client.get(path, {
      responseType: 'arraybuffer',
      headers,
      withCredentials: true,
    })
  ).data
}
