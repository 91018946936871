import React, { useEffect } from 'react'
import Suggestion from './Suggestion'

interface Props {
  value: string
  options: OptionItem[]
  onChange: (value: any) => void
  isGray?: boolean
  onOpenOptions: () => void
  onBlur: () => void
  className?: string
}

interface OptionItem {
  value: string
  label: string
}

const EditableText: React.VFC<Props> = (props) => {
  const { value, options, onChange, isGray, onOpenOptions, onBlur, className } =
    props

  const onDoubleClick = () => {
    onOpenOptions()
  }

  return (
    <>
      {options.length === 0 ? (
        <div
          onDoubleClick={onDoubleClick}
          className={`car-name ${isGray ? 'gray-text' : ''} ${className}`}
        >
          {value}
        </div>
      ) : (
        <Suggestion
          value={value}
          options={options}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
    </>
  )
}

export default EditableText
