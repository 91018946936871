import Select from 'react-select'
import { Labels } from 'common/enums'
import PdfImg from 'components/atoms/Img/PdfImg'
import SvgImg from 'components/atoms/Img/SvgImg'
import React, { useState, useContext, useMemo } from 'react'
import { useTable, useSortBy, Column } from 'react-table'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'
import 'styles/alcohol.scss'
import CustomModal from 'components/atoms/Modal/CustomModal'
import {
  AchievementDetailGetRes,
  AchievementDetailModal,
} from 'common/types/achievement'
import AnswerStandardTable from 'components/atoms/Table/AnswerStandardTable'
import { IStoreCache } from 'common/types/auth_provider'
import { AuthContext } from 'providers/AuthProvider'
import { fetchAchievementDetail } from 'repositories/achievenment'
import moment from 'moment'
import { updateArchievement } from 'services/admin/archievement-update'
import { PreworkContext } from 'providers/PreworkProvider'
import { IPreworkProvider } from 'common/types/prework_provider'
import { AchievementUpdate } from 'common/types/archievement-update'
import Pagination from 'components/atoms/Pagination'
import LoadingIndicator from 'components/atoms/LoadingIndicator'
import { alertService } from 'services/alert'
import { customModalService } from 'services/customModal'

const TableCard: React.VFC = () => {
  const { loginInfo, token }: IStoreCache = useContext(AuthContext).storeCache
  const {
    editedData,
    editData,
    toggleSelectAll,
    onUpdateSuccess,
    totalRecord,
    totalPage,
    currentPage,
    limit,
    onChangeLimit,
    onChangePage,
    scopeCar,
    downLoadCsv,
    filters,
    downLoadPdf,
  }: IPreworkProvider = useContext(PreworkContext)

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [dataDetail, setDataDetail] = useState<AchievementDetailGetRes>()
  const [isModalDetailOpen, setIsModalDetailOpen] = useState<boolean>(false)
  const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false)
  const storeCache: IStoreCache = useContext(AuthContext).storeCache

  const pageArr = useMemo(
    () => Array.from(Array(totalPage + 2).keys()),
    [totalPage]
  )

  const onToggleModal = () => {
    if (editedData.length > 0) setIsModalOpen(!isModalOpen)
  }
  const onToggleModalDetail = async (
    achievementDetailModalParams: AchievementDetailModal
  ) => {
    if (!isModalDetailOpen) {
      setIsLoadingDetail(true)
      try {
        const data = (await fetchAchievementDetail(storeCache.token, {
          kaisya_code: loginInfo?.kaisyaCode || '',
          ncs_user_id: loginInfo?.ncsUserId || '',
          achievement_id: achievementDetailModalParams.achievement_id,
          user_soshiki_name: achievementDetailModalParams.user_soshiki_name,
          car_soshiki_name: achievementDetailModalParams.car_soshiki_name || '',
          user_name: achievementDetailModalParams.user_name,
          car_name: achievementDetailModalParams.car_name,
          checklogs_user_name: achievementDetailModalParams.checklogs_user_name,
          checklogs_kakunin_id:
            achievementDetailModalParams.checklogs_kakunin_id,
        })) as AchievementDetailGetRes
        setIsLoadingDetail(false)
        if (String(data.status) === '1') {
          setDataDetail(data)
          setIsModalDetailOpen(true)
        } else {
          alertService.show(
            false,
            String(
              'システムエラーが発生しました。しばらく時間をおいてから再アクセスするか、お問い合わせください。（詳細情報表示）'
            )
          )
        }
      } catch (error) {
        setIsLoadingDetail(false)
        alertService.show(
          false,
          'システムエラーが発生しました。しばらく時間をおいてから再アクセスするか、お問い合わせください。（詳細取得）'
        )
      }
    } else {
      setIsModalDetailOpen(false)
    }
  }

  const pdfdata = async (): Promise<void> => {}

  const oncloseModalDetail = () => {
    setIsModalDetailOpen(false)
  }

  const { headerGroups, rows, prepareRow } = useTable<any>(
    { columns, data: editData },
    useSortBy
  )

  const convertEditData = () => {
    const arr: AchievementUpdate[] = []

    editedData.forEach((item) => {
      if (item.achievement_id && item.confirm_date) {
        const archievement: any = {
          achievement_type: '車両点検',
          achievement_id: String(item.achievement_id),
          car: {
            drive_recorder_id: item.drive_recorder_id,
            car_name: item?.car_name,
          },
          inspection_check_logs: {
            log_type: 0,
            updated_at: moment().format('YYYYMMDDHHmmss'),
            ncs_user_id: loginInfo?.ncsUserId,
            ncs_user_name: loginInfo?.ncsUserName,
            data_from: 'Web',
            check_status: item?.checklogs_status ? 1 : 0,
          },
        }
        if (!item.drive_recorder_id) {
          const cars = scopeCar?.filter((e) => e.name === item?.car_name)
          var result =
            cars?.length > 0
              ? cars?.reduce(function (res, obj) {
                  return moment(obj.startDate).unix() <
                    moment(res.startDate).unix()
                    ? obj
                    : res
                })
              : null
          archievement.car.drive_recorder_id =
            (result && result?.driveRecorderId) || 'unknown'
        }
        arr.push(archievement)
      }
    })
    return arr
  }

  const onClickRegister = () => {
    if (editedData?.length > 0) {
      customModalService.show(
        'このページに対して行った変更を登録しますか？',
        '',
        'はい',
        'いいえ',
        onRegister
      )
    }
  }

  const onRegister = async () => {
    const data = convertEditData()
    setIsModalOpen(false)
    const res = await updateArchievement({
      achievements: data,
      token: token,
      kaisya_code: loginInfo?.kaisyaCode || '',
      ncs_user_id: loginInfo?.ncsUserId || '',
    })
    if (res) {
      if (
        filters?.search_type === 'car_name' ||
        filters?.search_type === 'car_soshiki' ||
        filters.check_log !== '3'
      ) {
        alertService.show(
          true,
          <>
            表示中のデータを登録しました。
            <br />
            検索ボタンを押すと検索条件に従って再表示します。
          </>
        )
      } else {
        alertService.show(true, '表示中のデータを登録しました。')
      }
      onUpdateSuccess()
    }
  }

  const onChangeLimitValue = (value: any) => {
    if (editedData.length > 0) {
      customModalService.show(
        '変更データが未登録ですがよろしいですか？',
        '',
        'はい',
        'いいえ',
        () => {
          value && onChangeLimit(value.value)
        }
      )
    } else {
      value && onChangeLimit(value.value)
    }
  }

  const onChangePageValue = (page: number) => {
    if (page === currentPage || page === 0 || page === pageArr.length - 1) {
      return
    }
    if (editedData.length > 0) {
      customModalService.show(
        '変更データが未登録ですがよろしいですか？',
        '',
        'はい',
        'いいえ',
        () => {
          onChangePage(page)
        }
      )
    } else {
      onChangePage(page)
    }
  }

  const onClickCsv = () => {
    if (editedData.length > 0) {
      customModalService.show(
        '変更データが未登録ですがよろしいですか？',
        '',
        'はい',
        'いいえ',
        downLoadCsv
      )
    } else {
      downLoadCsv()
    }
  }

  const onClickPdf = () => {
    if (editedData.length > 0) {
      customModalService.show(
        '変更データが未登録ですがよろしいですか？',
        '',
        'はい',
        'いいえ',
        downLoadPdf
      )
    } else {
      downLoadPdf()
    }
  }
  return (
    <div className="table-main-content">
      {isLoadingDetail && <LoadingIndicator />}
      <div className="px-lg-3 pb-1">
        <Row className="justify-content-center">
          <Col lg="12" md="12">
            <div className="wrap-title-table">
              <div className="wrap-button-title">
                <div className="button-header-container">
                  {/* <PdfImg
                    content="印刷（PDF）"
                    onClick={onClickPdf}
                    disable={editedData.length > 0}
                  /> */}
                  <SvgImg
                    content="CSVで保存"
                    onClick={onClickCsv}
                    disable={editedData.length > 0}
                  />
                  <Button
                    color="primary"
                    className="button-register"
                    onClick={onClickRegister}
                    disabled={loginInfo?.permission === '0'}
                  >
                    ページ登録
                  </Button>
                </div>
              </div>
            </div>
            <Card className="mt-2 shadow border-0">
              <CardBody className="custom-card-body">
                <AnswerStandardTable
                  dataDetail={dataDetail?.achievements}
                  headerGroups={headerGroups}
                  rows={rows}
                  prepareRow={prepareRow}
                  toggleSelectAll={toggleSelectAll}
                  isModalDetailOpen={isModalDetailOpen}
                  toggleModalDetail={onToggleModalDetail}
                  onclose={oncloseModalDetail}
                />
                <div className="wrap-paginate">
                  <div className="select-page">
                    <label className="mr-4 mb-0">1ページ表示件数</label>
                    <Select
                      options={pagesOptions}
                      className="answer-select-footer"
                      menuPlacement="top"
                      value={{ label: String(limit), value: limit }}
                      onChange={onChangeLimitValue}
                    />
                  </div>
                  <div className="total-item">
                    <span>
                      {`${
                        currentPage === 1
                          ? currentPage
                          : (currentPage - 1) * limit + 1
                      }-${
                        totalPage > currentPage
                          ? currentPage * limit
                          : totalRecord
                      }/
                      ${totalRecord}`}
                    </span>
                    <Pagination
                      currentPage={currentPage}
                      onPageChange={onChangePageValue}
                      pageSize={limit}
                      siblingCount={1}
                      totalCount={totalRecord}
                    />
                  </div>
                </div>
              </CardBody>
              <div className="daily-footer">
                <div className="wrap-text-footer">
                  <div className="text-footer-1">
                    <span
                      className="text-link"
                      onClick={() =>
                        window.open(`${process.env.REACT_APP_ZDC_API_URL}/download/kiyaku`)
                      }
                    >
                      NCSドライブドクター利用規約
                    </span>
                  </div>
                  <div className="text-footer-2">
                    <span
                      className="text-link"
                      onClick={() =>
                        window.open(`${process.env.REACT_APP_ZDC_API_URL}/download/privacy`)
                      }
                    >
                      プライバシーポリシー
                    </span>
                  </div>
                  <div className="text-footer-3">
                    copyright©2022 NIPPON CAR SOLUTIONS CO.,LTD
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default TableCard

const columns: Column<any>[] = [
  {
    Header: Labels.RegistedDate,
    accessor: 'reg_date',
  },
  {
    Header: Labels.VehicleNumber,
    accessor: 'car_name',
  },
  {
    Header: Labels.VehicleOrganization,
    accessor: 'car_soshiki_name',
  },
  {
    Header: Labels.DrivingUser,
    accessor: 'user_name',
  },
  {
    Header: Labels.DriverOrganization,
    accessor: 'user_soshiki_name',
  },
  {
    Header: Labels.ImplementDate,
    accessor: 'confirm_date',
  },
  {
    Header: Labels.InspectionPatternName,
    accessor: 'inspection_pattern_name',
  },
  {
    Header: Labels.ImplementResult,
    accessor: 'check_status',
  },
  {
    // No.16対応：一覧に燃料欄の追加
    Header: Labels.Fuel,
    accessor: 'fuel',
  },
  {
    // No.16対応：一覧に充電量の追加
    Header: Labels.Battery,
    accessor: 'battery',
  },
  {
    Header: Labels.Confirmation,
    accessor: 'checklogs_status',
  },
  {
    Header: Labels.Confirmer,
    accessor: 'checklogs_user_name',
  },
  {
    Header: Labels.DriverComment,
    accessor: 'inspection_description',
  },
]

const pagesOptions = [
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 300, label: '300' },
]
