import { IStoreCache } from 'common/types/auth_provider'
import { InspectionItem } from 'common/types/inspection_item'
import { InspectionPattern } from 'common/types/inspection_patterns'
import { getErrorMessage } from 'common/utils'
import { reloadCachedInspectionPatterns } from 'providers/AuthProvider'
import { remove } from 'repositories/inspection_pattern'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * 点検パターンマトリックスチェックボックス`onClick`イベントハンドラ
 */
export function onClick(
  { id, inspection_items }: InspectionPattern,
  data: InspectionItem[],
  setData: React.Dispatch<React.SetStateAction<InspectionItem[]>>,
  setActiveCard: React.Dispatch<React.SetStateAction<number>>
): void {
  setData(data.filter((d) => inspection_items.includes(d.id)))
  setActiveCard(id)
}

/**
 * 点検パターン削除ボタン`onClick`イベントハンドラ
 */
export function onRemove(
  storeCache: IStoreCache,
  { id, name }: InspectionPattern
): void {
  try {
    modalService.show(`${name}を削除しますか？`, async () => {
      await remove(storeCache.token, id)
      await reloadCachedInspectionPatterns(storeCache)
      alertService.show(true, `${name}の削除が完了しました。`)
    })
  } catch (error) {
    getErrorMessage(error)
  }
}
