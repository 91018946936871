import React from 'react'

type Props = {
  displayAnswer: any
}

const RowTableDetail: React.VFC<Props> = ({ displayAnswer }) => {
  return (
    <div className="wrap-row-detail">
      {displayAnswer.cells.map((cell: any, index: number) => {
        return (
          index >= 0 && (
            <div
              className={`${
                index === 0
                  ? 'detail-inspection-content'
                  : 'detail-inspection-result'
              }`}
              key={index}
            >
              {index === 1 ? (
                <div
                  className={
                    displayAnswer.original.item_result === 'NG'
                      ? 'start-work-check-ng'
                      : 'start-work-check'
                  }
                >
                  {cell.render('Cell')}
                </div>
              ) : (
                cell.render('Cell')
              )}
            </div>
          )
        )
      })}
    </div>
  )
}
export default RowTableDetail
