export interface ListWebGetReq {
  kaisya_code: string
}

export interface InspectionPatternItemsRes {
  status: string
  message: string
  inspection_pattern_items: InspectionPatternItems[]
}

export interface InspectionPatternItems {
  inspection_pattern_id: string
  inspection_pattern_name: string
  category: Category[]
  inspection_items: InspectionItems[]
  disp_order: string // No.9対応
}

interface Category {
  category_id: string
  category_name: string
  category_color: string
}
export interface InspectionItems {
  category_id: string
  item_kind: number
  item_option: string[]
  item_type: number
  hide: boolean
  inspection_item_id: string
  item_content: string
  item_detail: string
  edit_type?: EditType
}

export enum EditType {
  EDIT = 'edit',
  DELETE = 'delete',
  ADD = 'add',
  NEW = 'new',
}

export interface InspectionItemUpdate {
  edit_type: EditType
  hide: boolean | ''
  inspection_item_id: string
  item_content: string
}

export interface InspectionPatternDelete {
  inspection_pattern_id: string
  inspection_pattern_name: string
  have_only_one_child: boolean
}

export interface ListWebUpdateParams {
  kaisya_code: string
  ncs_user_id: string
  ncs_user_name: string
  inspection_pattern_items: {
    inspection_pattern_id: string
    inspection_items: InspectionItemUpdate[]
    inspection_pattern_name: string // No.8対応
    disp_order: string // No.9対応
  }
}

export interface ListWebUpdateRes {
  status: string
  message: string
}

export interface ListWebCheckParams {
  kaisya_code: string
  inspection_pattern_id: string
  inspection_pattern_name: string
}

export interface ListWebCheckRes {
  message: string
}

// No.9対応：点検パターン名新規登録用のデータを定義
export interface ListWebStoreParams {
  kaisya_code: string
  ncs_user_id: string
  ncs_user_name: string
  inspection_pattern_name: string
}

export interface ListWebStoreRes {
  status: string
  message: string
}

// No.9対応：点検パターン削除用のデータを定義
export interface ListWebDeleteParams {
  kaisya_code: string
  ncs_user_id: string
  ncs_user_name: string
  inspection_pattern_id: string
}

export interface ListWebDeleteRes {
  status: string
  message: string
}
