import { ICustomModal } from 'common/types/confirm'
import { ReactNode } from 'react'
import { Subject } from 'rxjs'

const customModalSubject = new Subject<ICustomModal>()

export const customModalService = {
  onModal,
  show,
  close,
}

function onModal() {
  return customModalSubject.asObservable()
}

function show(
  title?: string,
  content?: ReactNode,
  confirmText?: string,
  cancelText?: string,
  onConfirm?: () => void,
  onCancel?: () => void,
  confirmBtnClass?: string
) {
  customModalSubject.next({
    isShow: true,
    title,
    content,
    confirmText,
    cancelText,
    onConfirm,
    onCancel,
    confirmBtnClass,
  })
}

function close(closeModal: ICustomModal) {
  customModalSubject.next({ ...closeModal, isShow: false })
}
