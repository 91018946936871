import { Routes } from 'common/enums'
import { InspectionPattern } from 'common/types/inspection_patterns'
import FormCard from 'components/organisms/Admin/InspectionCheckSheetForm/FormCard'
import React from 'react'
import { useLocation } from 'react-router'

const InspectionCheckSheetForm: React.VFC = () => {
  const { pathname, state } =
    useLocation<{ inspectionPattern?: InspectionPattern }>()
  const isCreate = pathname === Routes.InspectionCheckSheetCreate
  return (
    <FormCard
      isCreate={isCreate}
      inspectionPatterns={isCreate ? undefined : state.inspectionPattern}
    />
  )
}

export default InspectionCheckSheetForm
