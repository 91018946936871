import { AlertType } from 'common/enums'
import { Alert } from 'common/types/alert'
import { Subject } from 'rxjs'

const alertSubject = new Subject<Alert>()

export const alertService = {
  onAlert,
  show,
  close,
}

function onAlert() {
  return alertSubject.asObservable()
}

function show(isSuccess: boolean, message: string | any) {
  const type = isSuccess ? AlertType.SUCCESS : AlertType.DANGER
  alertSubject.next({
    isShow: true,
    type,
    message,
  })
}

function close(closeAlert: Alert) {
  alertSubject.next({ ...closeAlert, isShow: false })
}
