import { destroy, get, post } from 'common/fetch'
import { InspectionCheckSheetForm } from 'common/types/form/inspection_check_sheet'
import { InspectionPattern } from 'common/types/inspection_patterns'

const path = (): string => '/api/inspection_pattern'

/**
 * Get All Data.
 */
export async function fetch(
  token: string,
  client_id: number
): Promise<InspectionPattern[]> {
  return (
    await get<{
      inspectionPatterns: InspectionPattern[]
    }>(token, path(), { client_id })
  ).inspectionPatterns
}

/**
 * Save Record.
 */
export async function save(
  token: string,
  data: InspectionCheckSheetForm
): Promise<void> {
  return await post<void>(token, `${path()}/save`, data)
}

/**
 * Remove Record.
 */
export async function remove(token: string, id: number): Promise<void> {
  return await destroy<void>(token, `${path()}/remove`, { id })
}
