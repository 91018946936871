import { get, post } from 'common/fetch'
import { InspectionItemFormArray } from 'common/types/form/inspection_item'
import { InspectionItem } from 'common/types/inspection_item'

const path = (): string => '/api/inspection_item'

/**
 * Get All Data.
 */
export async function fetch(
  token: string,
  client_id: number
): Promise<InspectionItem[]> {
  return (
    await get<{
      inspectionItems: InspectionItem[]
    }>(token, path(), { client_id })
  ).inspectionItems
}

/**
 * Save Data.
 */
export async function save(
  token: string,
  data: InspectionItemFormArray
): Promise<void> {
  return await post<void>(token, `${path()}/save`, data)
}
