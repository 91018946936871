import React, { useContext } from 'react'
import { HeaderGroup, Row } from 'react-table'
import { Input } from 'reactstrap'

import TableRows from 'components/atoms/Table/DailyTableRow'
import {
  AchievementDetailModal,
  AchievementDetail,
} from 'common/types/achievement'
import { IPreworkProvider } from 'common/types/prework_provider'
import { PreworkContext } from 'providers/PreworkProvider'
import { IStoreCache } from 'common/types/auth_provider'
import { AuthContext } from 'providers/AuthProvider'
import UpArrow from 'assets/img/UpArow.svg'
import DownArrow from 'assets/img/DownArrow.svg'
import { Labels } from 'common/enums'
import DailyDetail from '../../../template/Admin/DailyDetail'
import TableDetail from '../../../template/Admin/TableDetail'
import IconTickLine from '../Img/IconTickLine'
import IconTickCheck from '../Img/IconTickCheck'
import IconTickNone from '../Img/IconTickNone'

type TStandardTable = {
  headerGroups: HeaderGroup<any>[]
  rows: Row<any>[]
  prepareRow: (row: Row<any>) => void
  toggleSelectAll: (key: string) => void
  isModalDetailOpen: boolean
  toggleModalDetail: (achievement_id: AchievementDetailModal) => void
  onclose: () => void
  dataDetail?: AchievementDetail
}

const AnswerStandardTable: React.VFC<TStandardTable> = ({
  headerGroups,
  rows,
  prepareRow,
  isModalDetailOpen,
  toggleModalDetail,
  onclose,
  dataDetail,
}: TStandardTable) => {
  const {
    toggleSelectAll,
    editedData,
    handleSort,
    sortBy,
    checkAll,
  }: IPreworkProvider = useContext(PreworkContext)
  const { loginInfo }: IStoreCache = useContext(AuthContext).storeCache
  const achievements = dataDetail

  const onClickSort = (id: string) => {
    if (editedData.length === 0) {
      handleSort(id)
    }
  }
  return (
    <>
      <DailyDetail
        isOpen={isModalDetailOpen}
        onAction={() => {}}
        onToggle={() => {}}
        onClose={onclose}
        dataDetail={achievements}
      >
        <div>
          <div className="base-infomation-detail">
            <div className="wrap-base-info">
              <div className="base-left">
                <div className="base-label">登録日時</div>
                <div className="base-content">
                  : {achievements?.reg_date || ''}
                </div>
              </div>
              <div className="base-right">
                <div className="base-right-2">
                  <div className="base-label-2">実施日時</div>
                  <div className="base-content">
                    : {achievements?.confirm_date || ''}
                  </div>
                </div>
                <div className="base-right-2 check_status">
                  <div className="base-label-3">点検結果 </div>
                  <div className="base-content-3">
                    : {achievements?.check_status || ''}
                  </div>
                </div>
              </div>
            </div>
            <div className="wrap-base-info">
              <div className="base-left">
                <div className="base-label">車両番号</div>
                <div className="base-content">
                  : {achievements?.car_name || ''}
                </div>
              </div>
              <div className="base-right">
                <div className="base-label-2">車両組織</div>
                <div className="base-content-2 ">
                  : {achievements?.car_soshiki_name || ''}
                </div>
              </div>
            </div>
            <div className="wrap-base-info">
              <div className="base-left">
                <div className="base-label">運転者</div>
                <div className="base-content">
                  : {achievements?.user_name || ''}
                </div>
              </div>
              <div className="base-right">
                <div className="base-label-2">運転者組織</div>
                <div className="base-content-2 ">
                  : {achievements?.user_soshiki_name || ''}
                </div>
              </div>
            </div>
            <div className="wrap-base-info">
              <div className="base-left">
                <div className="base-label">燃料</div>
                <div className="base-content">: {`${achievements?.fuel}`}</div>
              </div>
              <div className="base-right">
                <div className="base-right-2">
                  <div className="base-label-2">充電量</div>
                  <div className="base-content">
                    : {`${achievements?.battery}`}
                  </div>
                </div>
                <div className="base-right-2 check_status">
                  <div className="base-label-3">総走行距離 </div>
                  <div className="base-content-3">
                    : {`${Number(achievements?.mileage).toLocaleString()} km`}
                  </div>
                </div>
              </div>
            </div>
            <div className="wrap-textarea">
              <div className="textarea-title">運転者コメント: </div>
              <Input
                type="textarea"
                name="text"
                className="disable-resize"
                value={achievements?.inspection_description || ''}
                disabled
              />
            </div>
          </div>
          {achievements?.inspection_result?.inspection_items &&
            achievements?.inspection_result?.inspection_items?.length > 0 && (
              <div className="detail-pattern-name">{`${Labels.InspectionPattern}: ${achievements?.inspection_result?.inspection_items[0].inspection_pattern_name}`}</div>
            )}
          <div className="table-detail">
            <TableDetail
              inspectionItems={
                achievements?.inspection_result?.inspection_items
              }
            />
          </div>
        </div>
      </DailyDetail>
      <div className="custom-table">
        <div className="wrap-header-table">
          {headerGroups.map((headerGroup) =>
            headerGroup.headers.map(
              (header, index) =>
                index >= 0 && (
                  <div key={index} className={`wrap-column-table`}>
                    <div
                      className="prework-header-title"
                      onClick={() => onClickSort(header.id)}
                    >
                      {header.render('Header')}
                      <div className={`'header-sort'`}>
                        {sortBy[0] === header.id && (
                          <img
                            src={UpArrow}
                            alt=""
                            className="custom-sort-arrow"
                          />
                        )}
                        {sortBy[0] === `-${header.id}` && (
                          <img
                            src={DownArrow}
                            alt=""
                            className="custom-sort-arrow"
                          />
                        )}
                      </div>
                    </div>
                    {/* No.16対応：燃料、充電量カラムの追加に伴い、indexの番号を10に変更(確認カラムの順番) */}
                    {index === 10 &&
                      (loginInfo?.permission === '0' ? (
                        <div className="wrap-checkbox-table">
                          <IconTickNone fill="#E9ECEF" color="#E9ECEF" />
                        </div>
                      ) : (
                        <div
                          className="wrap-checkbox-table"
                          onClick={toggleSelectAll}
                        >
                          {checkAll === null && <IconTickLine />}
                          {checkAll === true && <IconTickCheck />}
                          {checkAll === false && <IconTickNone />}
                        </div>
                      ))}
                  </div>
                )
            )
          )}
        </div>
        <div className="table-row custom-scrollbar">
          {rows.map((row, index) => {
            prepareRow(row)
            return (
              row && (
                <TableRows
                  data={row}
                  isModalDetailOpen={isModalDetailOpen}
                  toggleModalDetail={toggleModalDetail}
                  key={index}
                />
              )
            )
          })}
        </div>
      </div>
    </>
  )
}

export default AnswerStandardTable
