import EditableText from 'components/atoms/Form/Text/EditableText'
import React, { useState, useContext, useCallback, useEffect } from 'react'
import { AlcoholContext } from 'providers/AlcoholProvider'
import { IAlcoholProvider } from 'common/types/alcohol_provider'
import { IAuthProvider } from 'common/types/auth_provider'
import { Car } from 'common/types/prework_provider'
import { AuthContext } from 'providers/AuthProvider'
import { Button, Col, Input, Row } from 'reactstrap'
import { getCarList } from 'repositories/vehicle'
import moment from 'moment'
import { fetchAchievementAlcohol } from 'repositories/achievenment'
import { AchievementGetReq } from 'common/types/achievement'
import { alertService } from 'services/alert'
import LoadingIndicator from 'components/atoms/LoadingIndicator'

type TConditionItems = {
  onChangeCreatableAchievement: () => void
  disabled: boolean
}

const ConditionItems: React.VFC<TConditionItems> = ({
  onChangeCreatableAchievement,
  disabled,
}: TConditionItems) => {
  const {
    scopeUser,
    registerConditions,
    onChangeScopeUser,
    onChangeRegisterAlcoholItems,
    onChangeRegisterUserCarItems,
    handleRegisterUserName,
    handleRegisterCarName,
  }: IAlcoholProvider = useContext(AlcoholContext)
  const [date, setDate] = useState<string>(
    moment(registerConditions.confirmDate).format('YYYYMMDD')
  )
  const [dateCarList, setDateCarList] = useState<string>(
    moment(registerConditions.confirmDate).format('YYYYMMDD')
  )
  const [errorUserSoshikiName, setErrorUserSoshikiName] = useState<string>('')
  const [errorCarSoshikiName, setErrorCarSoshikiName] = useState<string>('')
  const [errorConfirmDate, setErrorConfirmDate] = useState<string>('')
  const [userOptions, setUserOptions] = useState<any[]>([])
  const [carOptions, setCarOptions] = useState<any[]>([])
  const [carList, setCarList] = useState<Car[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [isDirtUserName, setIsDirtUserName] = useState<boolean>(false)
  const [isDirtCarName, setIsDirtCarName] = useState<boolean>(false)
  const {
    storeCache: { loginInfo, token },
  }: IAuthProvider = useContext(AuthContext)

  useEffect(() => {
    if (!isDirtUserName) {
      setIsDirtUserName(true)
      return
    }
    handleUserNameError()
  }, [registerConditions.ncsUserName])

  useEffect(() => {
    if (!isDirtCarName) {
      setIsDirtCarName(true)
      return
    }
    handleCarNameError()
  }, [registerConditions.carName])

  useEffect(() => {
    handleConfirmDateError()
  }, [registerConditions.confirmDate])

  const getCarListData = async () => {
    if (
      carList.length > 0 &&
      moment(registerConditions.confirmDate).format('YYYY/MM/DD') ===
        moment(dateCarList).format('YYYY/MM/DD')
    ) {
      const option = carList.map((e) => ({
        label: e.name,
        value: e.driveRecorderId,
        ...e,
      }))
      setCarOptions(option)
      return
    }
    setLoading(true)
    setDateCarList(registerConditions.confirmDate)
    try {
      const res = await getCarList(token, {
        kaisyaCode: loginInfo?.kaisyaCode || '',
        ncsUserId: loginInfo?.ncsUserId || '',
        keyword: '',
        checkDate: moment(registerConditions.confirmDate).format(
          'YYYYMMDDTHHmmss+0900'
        ),
      })
      setCarList(res.carList)
      const option = res.carList.map((e) => ({
        label: e.name,
        value: e.driveRecorderId,
        ...e,
      }))
      setLoading(false)
      setCarOptions(option)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const getUserListData = async () => {
    let option: any[] = []
    if (
      moment(registerConditions.confirmDate).format('YYYYMMDD') !==
      moment(date).format('YYYYMMDD')
    ) {
      setLoading(true)
      setDate(registerConditions.confirmDate)
      const startDate = moment(
        moment(registerConditions.confirmDate).format('YYYYMMDD')
      ).format('YYYYMMDDHHmmss')
      const endDate = moment(
        moment(
          moment(moment(registerConditions.confirmDate).format('YYYYMMDD')).add(
            1,
            'd'
          )
        ).subtract(1, 's')
      ).format('YYYYMMDDHHmmss')
      const body: AchievementGetReq = {
        kaisya_code: loginInfo?.kaisyaCode || '',
        ncs_user_id: loginInfo?.ncsUserId || '',
        achievement_type: '2',
        start_date: startDate,
        end_date: endDate,
        page: '1',
        limit: '20',
      }
      try {
        const res = await fetchAchievementAlcohol(token, body)
        if (res.status == '1') {
          let userList: any[] = []
          if (Array.isArray(res?.scope_data)) {
            userList = Array.from(
              new Map(res.scope_data[0]?.userList.map((user) => [user.ncsUserId, user])).values()
            );
            onChangeScopeUser(userList)
          } else {
            userList = Array.from(
              new Map(res.scope_data.userList.map((user) => [user.ncsUserId, user])).values()
            );
            onChangeScopeUser(userList)
          }
          option = userList.map((e) => ({
            label: e.name,
            value: e.ncsUserId,
            ...e,
          }))
        } else {
          alertService.show(
            false,
            'システムエラーが発生しました。しばらく時間をおいてから再アクセスするか、お問い合わせください。（運転者取得）'
          )
          option = []
        }
        setLoading(false)
      } catch (error) {
        alertService.show(
          false,
          'システムエラーが発生しました。しばらく時間をおいてから再アクセスするか、お問い合わせください。（運転者取得）'
        )
        setLoading(false)
      }
    } else {
      option = scopeUser.map((e) => ({
        label: e.name,
        value: e.ncsUserId,
        ...e,
      }))
    }
    // console.log(option)
    setUserOptions(option)
  }

  const onClickCreatableAchievement = () => {
    onChangeCreatableAchievement()
    handleUserNameError()
    handleCarNameError()
  }

  const handleUserNameError = () => {
    if (!registerConditions.confirmDate || errorConfirmDate === '実施日時が不正です。') return
    setErrorUserSoshikiName(
      registerConditions.ncsUserName ? '' : '選択してください'
    )
  }

  const handleCarNameError = () => {
    if (!registerConditions.confirmDate || errorConfirmDate === '実施日時が不正です。') return
    setErrorCarSoshikiName(
      registerConditions.carName ? '' : '選択してください'
    )
  }

  const handleConfirmDateError = () => {
    setErrorConfirmDate(registerConditions.confirmDate ? '' : '選択してください')
    if (
      moment(registerConditions.confirmDate).format('YYYYMMDD HH:mm') <
      moment().subtract(13, 'month').format('YYYYMMDD')
    ) {
      setErrorConfirmDate('実施日時が不正です。')
    }
    setErrorCarSoshikiName('')
    setErrorUserSoshikiName('')
  }

  const onBlur = () => setCarOptions([])

  const onBlurUser = () => setUserOptions([])

  return (
    <div className="gray-form p-3">
      {loading && <LoadingIndicator />}
      <fieldset
        className={disabled ? 'fieldset-disable' : ''}
        disabled={disabled}
      >
        <div className="modal-alcohol-form form-confirm-date">
          <div className="modal-alcohol-form-col-3">実施日時</div>
          <div className="modal-alcohol-form-col-1">:</div>
          <div className="modal-alcohol-form-col-8">
            <Input
              type="datetime-local"
              style={{ height: 10, width: 220 }}
              value={registerConditions.confirmDate}
              name="confirmDate"
              step="60"
              onChange={(e) => onChangeRegisterAlcoholItems(e)}
              min={moment()
                .subtract(13, 'month')
                .format('YYYY-MM-DDTHH:mm')}
              max={moment().format('YYYY-MM-DDTHH:mm')}
            />
            <span className="achievement-register-error-msg">
              {errorConfirmDate}
            </span>
          </div>
        </div>
        <hr className="m-2" />
        <div className="alcohol-row modal-alcohol-form">
          <div className="modal-alcohol-form-col-3">運転者</div>
          <div className="modal-alcohol-form-col-1">:</div>
          <div className="modal-alcohol-form-col-8">
            {registerConditions.confirmDate ?
              (errorConfirmDate !== '実施日時が不正です。' ?
              <EditableText
                value={
                  registerConditions.ncsUserName
                    ? registerConditions.ncsUserName
                    : 'ダブルクリックで選択'
                }
                options={userOptions}
                onOpenOptions={getUserListData}
                onBlur={onBlurUser}
                className={
                  'edit-high-light' + (disabled ? ' disable-condition-items' : '')
                }
                onChange={(e) => handleRegisterUserName(e)}
              /> : <span className="achievement-register-error-msg">正しい実施日時を選択してください</span>
              ) : <span className="achievement-register-error-msg">実施日時を選択してください</span>
            }
            <span className="achievement-register-error-msg">
              {errorUserSoshikiName}
            </span>
          </div>
        </div>
        <div className="alcohol-row modal-alcohol-form">
          <div className="modal-alcohol-form-col-3">運転者組織</div>
          <div className="modal-alcohol-form-col-1">:</div>
          <div className="modal-alcohol-form-col-8">
            {registerConditions.ncsUserSoshikiName}
          </div>
        </div>
        <hr className="m-2" />
        <div className="alcohol-row modal-alcohol-form">
          <div className="modal-alcohol-form-col-3">車両番号</div>
          <div className="modal-alcohol-form-col-1">:</div>
          <div className="modal-alcohol-form-col-8">
            {registerConditions.confirmDate ?
              (errorConfirmDate !== '実施日時が不正です。' ?
              <EditableText
                value={
                  registerConditions.carName
                    ? registerConditions.carName
                    : 'ダブルクリックで選択'
                }
                options={carOptions}
                onOpenOptions={getCarListData}
                onBlur={onBlur}
                className={
                  'edit-high-light' + (disabled ? ' disable-condition-items' : '')
                }
                onChange={(v) => handleRegisterCarName(v)}
              /> : <span className="achievement-register-error-msg">正しい実施日時を選択してください</span>
              ) : <span className="achievement-register-error-msg">実施日時を選択してください</span>
            }
            <span className="achievement-register-error-msg">
              {errorCarSoshikiName}
            </span>
          </div>
        </div>
        <div className="alcohol-row modal-alcohol-form">
          <div className="modal-alcohol-form-col-3">車両組織</div>
          <div className="modal-alcohol-form-col-1">:</div>
          <div className="modal-alcohol-form-col-8">
            {registerConditions.carSoshikiName}
          </div>
        </div>
      </fieldset>
      <div className="text-center mt-2">
        <Button
          color="primary"
          size="sm"
          className="px-5"
          onClick={onClickCreatableAchievement}
        >
          {disabled ? '条件を変更する' : 'この実績を作成する'}
        </Button>
      </div>
    </div>
  )
}

export default ConditionItems
