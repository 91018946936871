import StandardTable from 'components/atoms/Table/PatternStandardTable'
import React, { useMemo } from 'react'
import { useTable, useSortBy, Column } from 'react-table'
import { InspectionItemTable } from 'common/types/table/inspection_item'
import {
  inspectionItemTableBody,
  inspectionItemTableHeader,
} from 'services/admin/inspection_item'
import { Col } from 'reactstrap'
import { InspectionItem } from 'common/types/inspection_item'
import { Category } from 'common/types/category'

type TTableBody = {
  categories: Category[]
  inspectionItems: InspectionItem[]
}

const TableBody: React.VFC<TTableBody> = ({
  categories,
  inspectionItems,
}: TTableBody) => {
  const columns: Column<InspectionItemTable>[] = useMemo(
    () => inspectionItemTableHeader(),
    []
  )
  const data: InspectionItemTable[] = useMemo(
    () => inspectionItemTableBody(categories, inspectionItems),
    [categories, inspectionItems]
  )
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<InspectionItemTable>({ columns, data }, useSortBy)

  return (
    <Col className="check-table pl-0 ml--1">
      <StandardTable
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      />
    </Col>
  )
}

export default TableBody
