import { post } from 'common/fetch'
import { UpdateParams, UpdateResponse } from 'common/types/archievement-update'

const path = (): string => '/api/achievement-web-update'

export async function updateArchievementWeb(
  params: UpdateParams
): Promise<UpdateResponse> {
  return await post<UpdateResponse>(params.token, path(), {
    kaisya_code: params.kaisya_code,
    ncs_user_id: params.ncs_user_id,
    achievements: params.achievements,
  })
}

export async function updateArchievementAlcohol(
  params: UpdateParams
): Promise<UpdateResponse> {
  return await post<UpdateResponse>(
    params.token,
    path(),
    {
      kaisya_code: params.kaisya_code,
      ncs_user_id: params.ncs_user_id,
      achievements: params.achievements,
    }
  )
}
