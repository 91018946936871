import React from 'react'
import { Modal } from 'reactstrap'

type TConfirmModal = {
  isOpen: boolean
  onAction: () => void
  onToggle: () => void
  onClose: () => void
}

const ConfirmModal: React.VFC<TConfirmModal> = ({
  isOpen,
  onAction,
  onToggle,
  onClose,
}: TConfirmModal) => {
  return (
    <Modal className="modal-dialog-centered modal-confirm-content" isOpen={isOpen}>
      <div className="modal-body modal-confirm">
        <div className="modal-confirm-header">
          <span>変更を保存しますか？</span>
          <span onClick={onClose}></span>
        </div>
        <div className="modal-confirm-body">
          保存して画面を閉じる場合は「保存して閉じる」をクリックしてください。<br />
          保存せずにこの画面を閉じると、変更は破棄されます。
        </div>
        <div className="modal-confirm-footer">
          <button onClick={onToggle} type="button">
            変更せずに閉じる
          </button>
          <button onClick={onAction} type="button">
            保存して閉じる
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmModal
