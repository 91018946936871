import { Routes } from 'common/enums'
import Alert from 'components/atoms/Alert/Alert'
import Confirm from 'components/atoms/Alert/Confirm'
import ScrollToTop from 'components/atoms/Scroll/ScrollToTop'
import { AuthProvider } from 'providers/AuthProvider'
import React from 'react'
import { Route, Switch } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import Admin from 'routes/Admin'
import 'styles/base.scss'
import 'styles/main.scss'
import 'styles/answerDetail/answer_detail.scss'
import Login from 'template/Login'
import NotFound from 'template/NotFound'
import WaitingLoginDD from './template/WaitingLoginDD'
import CustomModal from './components/atoms/Alert/CustomModal'

const App: React.VFC = () => {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Route path={Routes.Login} component={() => <Login />} exact />
          <Route
            path={Routes.WaitingLoginDD}
            component={() => <WaitingLoginDD />}
          />
          <AuthProvider>
            <Switch>
              <Route path={adminPathList()} component={() => <Admin />} />
              <Route component={() => <NotFound />} />
            </Switch>
            <Confirm />
            <CustomModal />
          </AuthProvider>
        </Switch>
      </BrowserRouter>
      <Alert />
    </>
  )
}

const adminPathList = (): string[] => {
  return [
    Routes.Answer,
    Routes.AnswerDetail,
    Routes.InspectionCheckSheet,
    Routes.InspectionCheckSheetCreate,
    Routes.InspectionCheckSheetEdit,
    Routes.InspectionItem,
    Routes.User,
    Routes.Vehicle,
    Routes.Permission,
    Routes.AlcoholCheck,
  ]
}

export default App
