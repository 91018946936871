import { get, post, destroy } from 'common/fetch'
import { Answers } from 'common/types/answer/answers'
import { AnswerDetail } from 'common/types/answer/answer_detail'
import { PdfData } from 'common/types/pdf/pdf_data'
import { SearchForm } from 'common/types/form/Answer/search'
import { AnswerAlc } from 'common/types/pdf/answer_alc'
import { OperationLog } from 'common/types/answer/operation_log'
import { AlcCheckItem } from 'services/admin/answer_detail'
import { AchievementGetReq } from 'common/types/achievement'

const path = (): string => '/api/answer'

/**
 * Get All Data.
 */
export async function fetch(
  token: string,
  client_id: number
): Promise<Answers> {
  return (
    await get<{
      answers: Answers
    }>(token, path(), { client_id })
  ).answers
}

export async function fetchSearchAnswer(
  token: string,
  params: SearchForm
): Promise<Answers> {
  return (
    await get<{
      answers: Answers
    }>(token, `${path()}/search`, params)
  ).answers
}

export async function fetchPageNateAnswer(
  token: string,
  page: string | undefined,
  client_id: number
): Promise<Answers> {
  return (
    await get<{
      answers: Answers
    }>(token, path(), { page, client_id })
  ).answers
}

export async function fetchGetAnswerJson(
  token: string,
  id: string
): Promise<AnswerDetail[]> {
  return (
    await get<{
      answer_detail: AnswerDetail[]
    }>(token, `${path()}/getDetail`, { id })
  ).answer_detail
}

export async function fetchGetPdfData(token: string): Promise<PdfData[]> {
  let url = 'getPdfDataBase'
  return (
    await get<{
      pdf_data: PdfData[]
    }>(token, `${path()}/${url}`)
  ).pdf_data
}

export async function fetchGetAlcPdfData(token: string): Promise<AnswerAlc[]> {
  let url = 'getAlcPdfDataBase'
  return (
    await get<{
      pdf_data: AnswerAlc[]
    }>(token, `${path()}/${url}`)
  ).pdf_data
}

export async function fetchSaveEdit(
  token: string,
  params: AlcCheckItem[],
  id: string,
  type: number,
  answerId: number
): Promise<AnswerDetail> {
  return (
    await post<{
      answer_detail: AnswerDetail
    }>(token, `${path()}/saveEdit`, { params, id, type, answerId })
  ).answer_detail
}

export async function fetchRemove(
  token: string,
  id: string,
  type: number,
  answerId: number
): Promise<void> {
  return await destroy<void>(token, `${path()}/remove`, { id, type, answerId })
}

export async function fetchApproval(
  token: string,
  id: string,
  type: number,
  approval: boolean,
  answerId: number
): Promise<void> {
  return await get<void>(token, `${path()}/approval`, {
    id,
    type,
    approval,
    answerId,
  })
}

// 操作ログ取得
export async function fetchGetOperationLog(
  token: string,
  startId: number, // answer_id(アルコール_始業のID)
  inspectionId: number, // answer_id(車両点検のID)
  endId: number // answer_id(アルコール_終業のID)
): Promise<OperationLog[]> {
  return (
    await get<{
      log: OperationLog[]
    }>(token, `${path()}/getOperationLog`, { startId, inspectionId, endId })
  ).log
}
