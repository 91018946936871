import { dateFormat } from 'common/time'
import { OperationLog } from 'common/types/answer/operation_log'
import React from 'react'
import { Row, Table } from 'reactstrap'

type TLogTable = {
  logs: OperationLog[]
}

const LogTable: React.VFC<TLogTable> = ({ logs }: TLogTable) => {
  if (logs.length < 0) return <></>

  return (
    <Row className="mt-3">
      <Table
        className="answer-detail-table align-items-center table-bordered table-hover answer-table"
        responsive
      >
        <thead className="thead-light">
          <tr>
            <th scope="col" className="text-left">
              操作日時
            </th>
            <th scope="col" className="text-left">
              操作内容
            </th>
            <th scope="col" className="text-left">
              操作者
            </th>
          </tr>
        </thead>
        <tbody>
          {logs.map((l, i) => {
            return (
              <tr key={i}>
                <th scope="row" className="text-left">
                  {dateFormat(l.created_at, 'yyyy-MM-dd hh:mm:ss')}
                </th>
                <th scope="row" className="text-left">
                  {l.detail}
                </th>
                <th scope="row" className="text-left">
                  {l.user.name}
                </th>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Row>
  )
}

export default LogTable
