import IconAdd from 'components/atoms/Img/IconAdd'
import React, { useContext, useEffect, useState, useRef } from 'react'
import { Modal } from 'reactstrap'
import 'styles/preworkInspectionPattern.scss'
import Switch from 'react-switch'
import Select from 'react-select'
import {
  InspectionPatternItems,
  InspectionItems,
  EditType,
  InspectionItemUpdate,
  InspectionPatternDelete,
} from 'common/types/list_web'
import LoadingIndicator from 'components/atoms/LoadingIndicator'
import { AuthContext } from 'providers/AuthProvider'
import { fetch, postListWebUpdate, postListWebStore, postListWebDelete, postListWebCheckInspectionName } from 'repositories/list-web'
import { customModalService } from 'services/customModal'
import { alertService } from 'services/alert'
import { lengthChar } from 'common/eastasianwidth'

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    background: '#fff',
    borderColor: '#B6B6B7',
    minHeight: '25px',
    height: '25px',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    height: '25px',
    padding: '0 6px',
  }),
  input: (provided: any) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: '25px',
  }),
}

interface Option {
  value: string
  label: string
}
interface Props {
  onClose: () => void
}

const PreworkInspectionPattern: React.VFC<Props> = (props) => {
  const { onClose } = props
  const {
    storeCache: { token, loginInfo },
  } = useContext(AuthContext)
  const [selectPattern, setSelectPattern] = useState<string>('')
  const [items, setItems] = useState<InspectionItems[]>([])
  const [inspectionItems, setInspectionItems] = useState<InspectionItems[]>([])
  const [editedItems, setEditedItems] = useState<InspectionItemUpdate[]>([])
  const [suggestions, setSuggestions] = useState<Option[]>([])
  const [loadingInpsection, setLoadingInpsection] = useState<boolean>(false)
  const [dataInpsection, setDataInpsection] =
    useState<InspectionPatternItems[]>()
  const [errors, setErrors] = useState<string[]>([])
  const [errors2, setErrors2] = useState<string[]>([])
  // No.8対応：選択した点検パターン名
  const [selectPatternName, setSelectPatternName] = useState<string>('')
  const [isEditPatternName, setIsEditPatternName] = useState<boolean>(false)
  const [orgPatternName, setOrgPatternName] = useState<string>('')
  const [errors3, setErrors3] = useState<string[]>([])
  // No.9対応：モーダルオープンフラグ
  const [isOpenAddInspection, setIsOpenAddInspection] = useState<boolean>(false)
  const [additionalInspectionName, setAdditionalInspectionName] = useState<string>('')
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false)
  const [deletePattern, setDeletePattern] = useState<InspectionPatternDelete>()
  const [isErrorWithDelete, setIsErrorWithDelete] = useState<boolean>(false)
  const [dispOrder, setDispOrder] = useState<string>('')
  const [isEditDispOrder, setIsEditDispOrder] = useState<boolean>(false)
  const [isOverDispOrder, setIsOverDispOrder] = useState<boolean>(false)
  const [isValidateDispOrder, setIsValidateDispOrder] = useState<boolean>(false)
  const [isOverAndIsValidateDispOrder, setIsOverAndIsValidateDispOrder] = useState<boolean>(false)
  const [orgDispOrder, setOrgDispOrder] = useState<string>('')
  const [nameValidationString, setNameValidationString] = useState<string>('')
  const [addNameValidationString, setAddNameValidationString] = useState<string>('')
  const [isValidating1, setIsValidating1] = useState<boolean>(false)
  const [isValidating2, setIsValidating2] = useState<boolean>(false)

  useEffect(() => {
    loadData()
  }, [])

  const bottomRef = useRef<HTMLDivElement>(null)
  const bottomScroll = () => {
    bottomRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }

  const topRef = useRef<HTMLDivElement>(null)
  const topScroll = () => {
    topRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
      const arr: InspectionItemUpdate[] = []
      const arr1 = [...items]
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i].edit_type) {
          arr.push({
            edit_type: arr1[i].edit_type!,
            hide: arr1[i].edit_type === EditType.DELETE ? '' : arr1[i].hide,
            inspection_item_id:
              arr1[i].edit_type === EditType.NEW
                ? ''
                : arr1[i].inspection_item_id,
            item_content:
              arr1[i].edit_type === EditType.DELETE ||
              arr1[i].edit_type === EditType.ADD
                ? ''
                : arr1[i].item_content,
          })
        }
      }
      setEditedItems(arr)
  }, [items, inspectionItems, setEditedItems])

  // Param@ addFlg: 点検パターン新規追加の場合のみtrueになる
  const loadData = async (addFlg = false, delFlag = false) => {
    setLoadingInpsection(true)
    const { inspection_pattern_items, status } = await fetch(token, {
      kaisya_code: loginInfo?.kaisyaCode || '',
    })
    if (status === '1') {
      setDataInpsection(inspection_pattern_items)
      if (!selectPattern) {
        setSelectPattern(inspection_pattern_items[0]?.inspection_pattern_id)
        if (!delFlag) {
          setInspectionItems(
            JSON.parse(
              JSON.stringify(inspection_pattern_items[0]?.inspection_items)
            )
          )
          setItems(
            JSON.parse(
              JSON.stringify(inspection_pattern_items[0]?.inspection_items)
            )
          )
        }
        // No.8対応
        setSelectPatternName(inspection_pattern_items[0]?.inspection_pattern_name)
        setOrgPatternName(inspection_pattern_items[0]?.inspection_pattern_name)
        setDispOrder(inspection_pattern_items[0]?.disp_order)
        setOrgDispOrder(inspection_pattern_items[0]?.disp_order)
        topScroll()
      } else {
        const inspection_pattern = inspection_pattern_items.find(
          (e) => e.inspection_pattern_id === selectPattern
        )
        if (inspection_pattern !== undefined) {
          if (!delFlag) {
            setInspectionItems(
              JSON.parse(JSON.stringify(inspection_pattern?.inspection_items))
            )
            setItems(
              JSON.parse(JSON.stringify(inspection_pattern?.inspection_items))
            )
            setIsEditDispOrder(false)
            setIsOverDispOrder(false)
            setIsValidateDispOrder(false)
            setIsOverAndIsValidateDispOrder(false)
            setNameValidationString('')
          }
          const elem = document.getElementById('scroll-' + inspection_pattern.inspection_pattern_id)
          elem?.scrollIntoView({ behavior: 'smooth' })
        } else {
          setIsEditPatternName(false)
          setIsEditDispOrder(false)
          setIsOverDispOrder(false)
          setIsValidateDispOrder(false)
          setIsOverAndIsValidateDispOrder(false)
          setNameValidationString('')
          setErrors([])
          setErrors2([])
          setErrors3([])
          setSelectPattern(inspection_pattern_items[0]?.inspection_pattern_id)
          setInspectionItems(
            JSON.parse(
              JSON.stringify(inspection_pattern_items[0]?.inspection_items)
            )
          )
          setItems(
            JSON.parse(
              JSON.stringify(inspection_pattern_items[0]?.inspection_items)
            )
          )
          // No.8対応
          setSelectPatternName(inspection_pattern_items[0]?.inspection_pattern_name)
          setOrgPatternName(inspection_pattern_items[0]?.inspection_pattern_name)
          setDispOrder(inspection_pattern_items[0]?.disp_order)
          setOrgDispOrder(inspection_pattern_items[0]?.disp_order)
          topScroll()
        }

        if (addFlg) {
          let additionalPattern = inspection_pattern_items[inspection_pattern_items.length -1]
          setSelectPattern(additionalPattern?.inspection_pattern_id)
          setSelectPatternName(additionalPattern?.inspection_pattern_name)
          setOrgPatternName(additionalPattern?.inspection_pattern_name)
          setDispOrder(additionalPattern?.disp_order)
          setOrgDispOrder(inspection_pattern_items[0]?.disp_order)
          setIsOverDispOrder(false)
          setIsValidateDispOrder(false)
          setIsOverAndIsValidateDispOrder(false)
          setNameValidationString('')
          setInspectionItems(
            JSON.parse(
              JSON.stringify(additionalPattern?.inspection_items)
            )
          )
          setItems(
            JSON.parse(
              JSON.stringify(additionalPattern?.inspection_items)
            )
          )
          bottomScroll()
        }
      }
    }
    setLoadingInpsection(false)
    // 登録後に点検パターン名の空白を削除
    if (!addFlg && !delFlag) {
      if (selectPatternName != ''){
        setSelectPatternName(selectPatternName.trim())
      }
    }
  }

  useEffect(() => {
    const suggest: Option[] = []
    if (dataInpsection && dataInpsection?.length > 0) {
      dataInpsection.forEach((inspection) => {
        inspection.inspection_items.forEach((inspection_item) => {
          if (inspection_item.item_type === 1) {
            const data = items.find(
              (item) => item.item_content === inspection_item.item_content
            )
            const find = suggest.find(
              (e) => e.label === inspection_item.item_content
            )
            if (
              (!data || (data && data?.edit_type === EditType.DELETE)) &&
              !find
            ) {
              suggest.push({
                label: inspection_item.item_content,
                value: inspection_item.inspection_item_id,
              })
            }
          }
        })
      })
      setSuggestions(suggest)
    }
  }, [dataInpsection, items, setSuggestions])

  const onClickClose = () => {
    if (editedItems.length > 0 || isEditPatternName || isEditDispOrder) {
      customModalService.show(
        '変更データが未登録ですがよろしいですか',
        '',
        'はい',
        'いいえ',
        onClose
      )
    } else {
      onClose()
    }
  }

  const onClickPattern = (id: string) => {
    if (editedItems.length > 0 || isEditPatternName || isEditDispOrder) {
      customModalService.show(
        '変更データが未登録ですがよろしいですか？',
        '',
        'はい',
        'いいえ',
        () => {
          handleSelectPattern(id)
        }
      )
    } else {
      handleSelectPattern(id)
    }
  }

  const handleSelectPattern = (id: string) => {
    const pattern = dataInpsection?.find((e) => e.inspection_pattern_id === id)
    if (pattern) {
      setInspectionItems(JSON.parse(JSON.stringify(pattern?.inspection_items)))
      setItems(JSON.parse(JSON.stringify(pattern.inspection_items)))
      setSelectPattern(pattern.inspection_pattern_id)
      // No.8対応
      setSelectPatternName(pattern?.inspection_pattern_name)
      setOrgPatternName(pattern?.inspection_pattern_name)
      setIsEditPatternName(false)
      // No.9対応
      setDispOrder(pattern?.disp_order)
      setIsEditDispOrder(false)
      setIsOverDispOrder(false)
      setIsValidateDispOrder(false)
      setIsOverAndIsValidateDispOrder(false)
      setOrgDispOrder(pattern?.disp_order)
      setNameValidationString('')
    }
    setErrors([])
    setErrors2([])
    setErrors3([])
  }
  // No.8対応
  const onChangeConfirmPatternName = (value: any) => {
    setSelectPatternName(value)
    if (value !== orgPatternName) {
      setIsEditPatternName(true)
    } else {
      setIsEditPatternName(false)
    }
    setNameValidationString('')
  }

  const reg = new RegExp(/^[0-9]*$/);
  // No.9対応
  const onChangeDispOrder = (value: any) => {
    setIsOverDispOrder(false)
    setIsValidateDispOrder(false)
    setIsOverAndIsValidateDispOrder(false)
    setDispOrder(value)
    if (value != orgDispOrder) {
      setIsEditDispOrder(true)
    } else {
      setIsEditDispOrder(false)
    }
  }

  // No.9対応
  const onChangeConfirmAdditionalPatternName = (value: any) => {
    setAddNameValidationString('')
    setAdditionalInspectionName(value)
  }

  const onClickAdd = () => {
    const arr = JSON.parse(JSON.stringify(items))
    arr.push({
      category_id: '',
      hide: false,
      inspection_item_id: `new-${items.length}`,
      item_content: '',
      item_detail: '',
      item_kind: 1,
      item_option: [],
      item_type: 1,
      edit_type: EditType.NEW,
    })
    setItems(arr)
  }

  const editItem = (id: string, index: number, key: string, value: any) => {
    const arr = JSON.parse(JSON.stringify(items))
    if (!arr[index].edit_type) {
      arr[index].edit_type = EditType.EDIT
    }
    const inspectionItem = inspectionItems.find(
      (item) => item.inspection_item_id === id
    )
    if (arr[index].inspection_item_id === id) {
      if (key === 'hide') {
        if (
          inspectionItem &&
          inspectionItem?.hide === value &&
          inspectionItem?.item_type === 0
        ) {
          arr[index].edit_type = undefined
        }
        arr[index].hide = value
      }
      if (key === 'item_content') {
        arr[index].item_content = value.label
        arr[index].inspection_item_id = value.value
        if (inspectionItem && inspectionItem?.item_content === value.label) {
          arr[index].edit_type = undefined
        }
        const find = suggestions.find(
          (suggest) => suggest.label === value.label
        )
        if (find && arr[index].edit_type === EditType.NEW) {
          arr[index].edit_type = EditType.ADD
          arr[index].inspection_item_id = find.value
          const suggest = suggestions.filter((e) => e.value !== value.value)
          setSuggestions(suggest)
        }
      }
      setItems(arr)
      setErrors([])
      setErrors2([])
      setErrors3([])
    }
  }

  const renderItem = (item: InspectionItems, index: number) => {
    if (item?.edit_type && item.edit_type === EditType.DELETE) {
      return null
    }
    return (
      <div
        className="form-row nowrap"
        key={`item-${item.inspection_item_id}-${index}`}
      >
        <Switch
          checked={!item.hide}
          onChange={(checked) =>
            editItem(item.inspection_item_id, index, 'hide', !checked)
          }
          width={56}
          height={21}
          borderRadius={14}
          onColor="#28a745"
          offColor="#cdd6dd"
          handleDiameter={13}
        />
        {item.item_type === 0 && (
          <span className="form-row-info">{item.item_content}</span>
        )}
        {item.item_type === 1 && (
          <div className="item_content">
            <Select
              autoFocus={item.edit_type === 'new' && index === items.length - 1}
              className={'pattern-input'}
              styles={customStyles}
              menuPlacement="top"
              options={suggestions}
              value={{
                label: item.item_content,
                value: item.inspection_item_id,
              }}
              onChange={(value) => {
                editItem(item.inspection_item_id, index, 'item_content', value)
              }}
              inputValue={item.item_content}
              onInputChange={(value, action) => {
                if (
                  action.action !== 'menu-close' &&
                  action.action !== 'input-blur' /* &&
                  lengthChar(value) <= 70 */
                ) {
                  editItem(item.inspection_item_id, index, 'item_content', {
                    label: value,
                    value: item.inspection_item_id,
                  })
                }
              }}
              noOptionsMessage={() => '候補なし'}
              onKeyDown={(e) => {
                if (e.key === ' ' && !item.item_content) {
                  e.preventDefault()
                  editItem(item.inspection_item_id, index, 'item_content', {
                    label: ' ',
                    value: item.inspection_item_id,
                  })
                }
              }}
            />
            {errors.includes(`${item.inspection_item_id}_${index}`) && (
              <div className="setting-error-txt">必須入力項目です</div>
            )}
            {errors2.includes(`${item.inspection_item_id}_${index}`) && (
              <div className="setting-error-txt">項目名が重複しています</div>
            )}
            {errors3.includes(`${item.inspection_item_id}_${index}`) && (
              <div className="setting-error-txt">文字数が超えています</div> // No.8対応：バリデーションメッセージ
            )}
          </div>
        )}
        {item.item_type === 1 && (
          <button
            type="button"
            className="delete-button"
            onClick={() => {
              customModalService.show(
                'この項目をこの点検パターンから削除しますか？',
                '',
                'はい',
                'いいえ',
                () => {
                  const { inspection_item_id } = item
                  const arr = JSON.parse(JSON.stringify(items))
                  if (arr[index].inspection_item_id === inspection_item_id) {
                    if (!arr[index].edit_type) {
                      arr[index].edit_type = EditType.DELETE
                      arr[index].item_content = ''
                    } else {
                      arr.splice(index, 1)
                    }
                  }
                  setItems(arr)
                  setErrors([])
                  setErrors2([])
                  setErrors3([])
                },
                undefined,
                'delete-modal-btn'
              )
            }}
          >
            削除
          </button>
        )}
      </div>
    )
  }


  const openDeleteModal = (pattern: any) => {
    setDeletePattern({
      inspection_pattern_id: pattern?.inspection_pattern_id,
      inspection_pattern_name: pattern?.inspection_pattern_name,
      have_only_one_child: dataInpsection?.length === 1 ? true : false,
    })
    setIsOpenDeleteModal(true)
  }

  const closeDeleteModal = () => {
    setIsOpenDeleteModal(false)
    setIsErrorWithDelete(false)
  }

  const confirmBeforeDelete = () => {
    if (deletePattern?.have_only_one_child) {
      setIsErrorWithDelete(true)
      return
    }
    deleteInspectionPattern()
  }

  const inspectionItemDeleteBtn = (pattern: any) => {
    return (
      <button
        type="button"
        className="delete-button__items"
        onClick={() => openDeleteModal(pattern)}
      >
        削除
      </button>
    )
  }

  const deleteInspectionPattern = async () => {
    const res = await postListWebDelete(token, {
      kaisya_code: loginInfo?.kaisyaCode || '',
      ncs_user_id: loginInfo?.ncsUserId || '',
      ncs_user_name: loginInfo?.ncsUserName || '',
      inspection_pattern_id: deletePattern?.inspection_pattern_id || '',
    })
    setLoadingInpsection(false)
    if (res.status === '1') {
      loadData(false,true)
      setIsOpenDeleteModal(false)
      setIsErrorWithDelete(false)
    } else {
      alertService.show(
        false,
        'システムエラーが発生しました。しばらく時間をおいてから再アクセスするか、お問い合わせください。（点検パターン削除）'
      )
    }
    setIsErrorWithDelete(false)
  }

  const onClickOpenAddInspection = () => {
    setAdditionalInspectionName('')
    if (editedItems.length > 0 || isEditPatternName || isEditDispOrder) {
      customModalService.show(
        '変更データが未登録ですがよろしいですか？',
        '',
        'はい',
        'いいえ',
        () => {
          setIsOpenAddInspection(true)
        }
      )
    } else {
      setIsOpenAddInspection(true)
    }
  }

  const onClickCloseAddInspection = () => {
    setAddNameValidationString('')
    setAdditionalInspectionName('')
    setIsOpenAddInspection(false)
  }

  const reOpenAdditionalInspection = () => {
    setIsOpenAddInspection(true)
  }
  // 「保存」ボタンをクリックした時の操作
  const onClickRegisterAdditionalInspection = async () => {
    setIsValidating2(true)
    // 点検パターン名のバリデーションチェック
    let inspectionNameValidationMsg = ''
    const res = await postListWebCheckInspectionName(token, {
      kaisya_code: loginInfo?.kaisyaCode || '',
      inspection_pattern_id: '',
      inspection_pattern_name: additionalInspectionName.trim()
    })
    setIsValidating2(false)
    inspectionNameValidationMsg = res.message
    setAddNameValidationString(inspectionNameValidationMsg)
    if(!inspectionNameValidationMsg){
      customModalService.show(
        '点検パターンを追加しますか？',
        '追加点検パターン名：'+additionalInspectionName.trim(),
        '保存',
        'キャンセル',
        onRegisterAdditionalInspection,
        reOpenAdditionalInspection
      )
      setIsOpenAddInspection(false)
    }
  }
  // 点検パターン名の新規登録処理
  const onRegisterAdditionalInspection = async () => {
    let tmpAdditionalInspectionName = additionalInspectionName
    const res = await postListWebStore(token, {
      kaisya_code: loginInfo?.kaisyaCode || '',
      ncs_user_id: loginInfo?.ncsUserId || '',
      ncs_user_name: loginInfo?.ncsUserName || '',
      inspection_pattern_name: tmpAdditionalInspectionName.trim()
    })
    setLoadingInpsection(false)
    if (res.status == '2') {
      setIsOpenAddInspection(true)
      setAddNameValidationString(res.message)
    } else if (res.status === '1') {
      setAdditionalInspectionName('')
      setIsEditPatternName(false)
      setIsEditDispOrder(false)
      loadData(true)
    } else {
      alertService.show(
        false,
        'システムエラーが発生しました。しばらく時間をおいてから再アクセスするか、お問い合わせください。（点検パターン新規追加）'
      )
    }
  }

  const onClickRegister = async () => {
    let tmpPatternName = orgPatternName
    tmpPatternName = selectPatternName
    let inspectionNameValidationMsg = ''
    setIsValidating1(true)
    // 点検パターン名のバリデーションチェック
    if(isEditPatternName){
      const res = await postListWebCheckInspectionName(token, {
        kaisya_code: loginInfo?.kaisyaCode || '',
        inspection_pattern_id: selectPattern,
        inspection_pattern_name: tmpPatternName.trim()
      })
      inspectionNameValidationMsg = res.message
      setNameValidationString(inspectionNameValidationMsg)
    }
    setIsValidating1(false)
    let isOverDispOrder = false
    let isValidateDispOrder = false
    if (dispOrder.length > 5) {
      isOverDispOrder = true
      setIsOverDispOrder(true)
    }
    if (!reg.test(dispOrder)) {
      isValidateDispOrder = true
      setIsValidateDispOrder(true)
    }
    if (isOverDispOrder && isValidateDispOrder) {
      setIsOverAndIsValidateDispOrder(true)
    }
    if (editedItems.length > 0 || isEditPatternName || isEditDispOrder) {
      const error: string[] = []
      const error2: string[] = []
      const error3: string[] = []
      items.forEach((item, index) => {
        if (
          item?.edit_type !== EditType.DELETE &&
          item?.edit_type !== EditType.ADD &&
          !item.item_content.trim()
        ) {
          error.push(`${item.inspection_item_id}_${index}`)
        }
        const find = items.find(
          (e) =>
            e.item_content.trim() === item.item_content.trim() &&
            e?.edit_type !== EditType.DELETE &&
            e.inspection_item_id !== item.inspection_item_id
        )
        if (find) {
          error2.push(`${item.inspection_item_id}_${index}`)
        }
        if(lengthChar(item.item_content) > 70) { // No.8対応：70byteの制限
            error3.push(`${item.inspection_item_id}_${index}`)
        }
      })
      if (error.length > 0 || error2.length > 0 || error3.length > 0) {
        setErrors(error)
        setErrors2(error2)
        setErrors3(error3)
      } else if (!isOverDispOrder && !isValidateDispOrder && !inspectionNameValidationMsg){
        customModalService.show(
          '変更を保存しますか？',
          '',
          '保存',
          'キャンセル',
          onRegister
        )
      }
    }
  }

  const onRegister = async () => {
    setLoadingInpsection(true)
    const inspection_items = editedItems.map((item) =>
      item?.edit_type === EditType.NEW
        ? {
            ...item,
            inspection_item_id: '',
            item_content: item.item_content.trim(),
          }
        : {
            ...item,
            item_content: item.item_content.trim(),
          }
    )
    let tmpPatternName = orgPatternName;
    if (isEditPatternName) {
      tmpPatternName = selectPatternName
    }
    const res = await postListWebUpdate(token, {
      inspection_pattern_items: {
        inspection_pattern_id: selectPattern,
        inspection_items: inspection_items,
        inspection_pattern_name: tmpPatternName.trim(),
        disp_order: dispOrder,
      },
      kaisya_code: loginInfo?.kaisyaCode || '',
      ncs_user_id: loginInfo?.ncsUserId || '',
      ncs_user_name: loginInfo?.ncsUserName || '',
    })
    setLoadingInpsection(false)
    setIsEditPatternName(false)
    setIsOverDispOrder(false)
    setIsValidateDispOrder(false)
    setIsOverAndIsValidateDispOrder(false)
    dispOrder != "" ? setDispOrder(dispOrder) : ""
    dispOrder != "" ? setOrgDispOrder(dispOrder) : ""
    if (res.status === '1') {
      setOrgPatternName(selectPatternName.trim())
      setNameValidationString('')
      loadData()
    } else {
      alertService.show(
        false,
        'システムエラーが発生しました。しばらく時間をおいてから再アクセスするか、お問い合わせください。（点検項目更新）'
      )
    }
  }

  const deleteModal = () => {
    return (
      <Modal isOpen={isOpenDeleteModal} centered className="inspection-modal__delete modal-lg">
        <div className="close-wrap">
          <div className="close" onClick={closeDeleteModal} />
        </div>
        <div className="contents">
          {
            deletePattern?.inspection_pattern_name ?
            <div>[{deletePattern?.inspection_pattern_name}]<p>を削除します。よろしいですか？</p></div> : ''
          }
          {
            isErrorWithDelete ?
            <span style={{ color: 'red', fontSize: '14px', display: 'block', textAlign: 'left' }}>点検パターン数が1件のため削除できません</span> : ''
          }
        </div>
        <div className="confirmbtns">
          <button
            type="button"
            className="pattern-form-button deny"
            onClick={closeDeleteModal}
          >
            いいえ
          </button>
          <button
            type="button"
            className="pattern-form-button accept"
            onClick={confirmBeforeDelete}
          >
            はい
          </button>
        </div>
      </Modal>
    )
  }

  return (
    <Modal isOpen={true} centered className="inspection-modal">
      {/* No.9対応：モーダル追加 */}
      { deleteModal() }
      <Modal isOpen={isOpenAddInspection} centered className="inspection-modal__add modal-lg" style={{ position: 'relative' }}>
        <div className="close-wrap">
          <div className="close" onClick={onClickCloseAddInspection} />
        </div>
        <div className="input-additional-inspection">
          <label htmlFor="additional-pattern-name" style={{ fontSize: '14px', textAlign: 'left' }}>新規点検パターン名</label>
            <input
              type='text'
              style={{ width: '100%' }}
              value={additionalInspectionName}
              onChange={(e: any) => onChangeConfirmAdditionalPatternName(e.target.value)}
              id="additional-pattern-name"
            />
          { addNameValidationString ?
            <span style={{ color: 'red', fontSize: '14px' }}>{addNameValidationString}<br /></span> : ''
          }
        </div>
        <button
          type="button"
          className="pattern-form-button inspection-add-btn"
          onClick={onClickRegisterAdditionalInspection}
        >
          保存
        </button>
        { isValidating2 ?
          <div className="loading-indicator-container" style = {{ height: '100%' ,width: '100%', margin: 'auto', zIndex: 999, position: 'absolute', top: '0', left: '0', backgroundColor: '#f8f8f8ad' }}>
            <div className="loading-indicator" />
          </div> : ''
        }
      </Modal>
      <div className="modal-inspection-container">
        <div className="modal-inspection">
          <div className="close-wrap">
            <div className="close" onClick={onClickClose} />
          </div>
          <div className="modal-inspection-header">
            <span>始業前点検項目設定</span>
          </div>
          <div style={{ position: 'absolute', top: '90px' }}>
            <button
              type="button"
              className="pattern-form-button button-add"
              onClick={onClickOpenAddInspection}
            >
              <IconAdd />
              <span className="add-title">点検パターン追加</span>
            </button>
          </div>
          <div className="modal-inspection-body">
            <div className="pattern-list">
              <div ref={topRef}></div>
              {dataInpsection &&
                dataInpsection?.map((pattern) => (
                  <div className="innerbox__item">
                    <div
                      className={`pattern-item ${
                        selectPattern === pattern.inspection_pattern_id
                          ? 'pattern-active'
                          : ''
                      }
                      ${pattern.disp_order ? '' : 'without-disp_order'}`}
                      id={`scroll-${pattern.inspection_pattern_id}`}
                      key={`pattern-item-${pattern.inspection_pattern_id}`}
                      onClick={() =>
                        onClickPattern(pattern.inspection_pattern_id)
                      }
                    >
                      { pattern.disp_order ? 
                        <div className="pattern-item-name"> { pattern.disp_order }：</div> : ''
                      }
                      <div className="pattern-item-name-value">
                        {pattern.inspection_pattern_name}
                      </div>
                      <span className="number-inspection ">{`点検項目数: ${pattern.inspection_items.length} 件`}</span>
                    </div>
                    { inspectionItemDeleteBtn(pattern) }
                  </div>
                ))}
                <div ref={bottomRef}></div>
            </div>
            <div className="pattern-form">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* No.9対応 */}
                <div style={{ width: '10%', marginLeft: '0.5px' }}>
                  <span style={{ fontSize: '14px' }}>表示順</span>
                  <input
                    type='text'
                    style={{ width: '100%' }}
                    value={ dispOrder }
                    onChange={(e: any) => onChangeDispOrder(e.target.value)}
                  />
                </div>
                {/* No.8対応 */}
                <div style={{ width: '53%' }}>
                  <span style={{ fontSize: '14px' }}>点検パターン名</span>
                  <input
                    type='text'
                    style={{ width: '100%' }}
                    value={selectPatternName}
                    onChange={(e: any) => onChangeConfirmPatternName(e.target.value)}
                  />
                </div>
                <div className="buttons">
                  <button
                    type="button"
                    className="pattern-form-button button-add"
                    onClick={onClickAdd}
                  >
                    <IconAdd />
                    <span className="add-title">新規点検項目追加</span>
                  </button>
                  <button
                    type="button"
                    className="pattern-form-button"
                    onClick={onClickRegister}
                  >
                    保存
                  </button>
                </div>
              </div>
              { nameValidationString ?
                <span style={{ color: 'red', fontSize: '14px' }}>{nameValidationString}<br /></span> : ''
              }
              { !isOverAndIsValidateDispOrder && isOverDispOrder ?
                <span style={{ color: 'red', fontSize: '14px' }}>表示順は5桁以内で入力してください<br /></span> : ''
              }
              { !isOverAndIsValidateDispOrder && isValidateDispOrder ?
                <span style={{ color: 'red', fontSize: '14px' }}>表示順は半角数字のみで入力してください<br /></span> : ''
              }
              { isOverAndIsValidateDispOrder ?
                <span style={{ color: 'red', fontSize: '14px' }}>表示順は半角数字のみ(5桁以内)で入力してください</span> : ''
              }
              <div className="form-title" style={{ marginTop: nameValidationString || isOverDispOrder || isValidateDispOrder || isOverAndIsValidateDispOrder ? '5px' : '20px' }}>
                <span>表示／非表示</span>
                <span>点検項目</span>
                <span>削除</span>
              </div>
              <div className="form-items">
                {items?.map((item, index) => renderItem(item, index))}
              </div>
            </div>
          </div>
          <div className="modal-inspection-footer">
            <button
              type="button"
              className="close-modal-button"
              onClick={onClickClose}
            >
              閉じる
            </button>
          </div>
        </div>
      </div>
      { isValidating1 ?
        <LoadingIndicator /> : ''
      }
      {loadingInpsection && <LoadingIndicator />}
    </Modal>
  )
}

export default PreworkInspectionPattern
