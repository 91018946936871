import { get, post } from 'common/fetch'
import { Category } from 'common/types/category'
import { CategoryFormArray } from 'common/types/form/category'

const path = (): string => '/api/category'

/**
 * Get All Data.
 */
export async function fetch(
  token: string,
  client_id: number
): Promise<Category[]> {
  return (
    await get<{
      categories: Category[]
    }>(token, path(), { client_id })
  ).categories
}

/**
 * Save All Data.
 */
export async function save(
  token: string,
  data: CategoryFormArray
): Promise<void> {
  return await post<void>(token, `${path()}/save`, data)
}
