import React, { useState, useContext, useEffect } from 'react'
import { Button, Input } from 'reactstrap'
import { AlcoholContext } from 'providers/AlcoholProvider'
import { AuthContext } from 'providers/AuthProvider'
import { IAlcoholProvider } from 'common/types/alcohol_provider'
import { IAuthProvider } from 'common/types/auth_provider'
import { getUserInfo } from 'repositories/user'
import { GetUserInfo } from 'common/types/user'
import moment from 'moment'

type TAchievementItems = {
  disabled: boolean
}

const AchievementItems: React.VFC<TAchievementItems> = ({
  disabled,
}: TAchievementItems) => {
  const {
    onChangeAlcoholValue,
    onChangeWitnessUserId,
    onChangeWitnessUserNameById,
    onChangeRegisterAlcoholItems,
    resetValidateErrorMessage,
    onBlurChangeWitnessUserId,
    registerAlcoholValidate,
    registerAlcoholCheck,
    registerAlcoholRadio,
  }: IAlcoholProvider = useContext(AlcoholContext)
  const {
    storeCache: { loginInfo, token },
  }: IAuthProvider = useContext(AuthContext)
  const [searchLoading, setSearchLoading] = useState<boolean>(false)
  const [errorSearch, setErrorSearch] = useState<string>('')

  useEffect(() => {
    if (!registerAlcoholRadio.isId && errorSearch) setErrorSearch('')
  }, [registerAlcoholRadio.isId])

  const changeId = (e: any) => {
    onBlurChangeWitnessUserId(e.target.value)
  }

  const onChangeId = () => {
    resetValidateErrorMessage('errorWittness')
    resetValidateErrorMessage('errorWittnessUserByText')
  }

  const changeAlcoholValue = (e: any) => {
    resetValidateErrorMessage('errorAlcoholValue')
    let { value } = e.target
    if (value < 0) value = 0
    if (value.indexOf('.') == -1) {
      if (value >= 1 && value <= 9999) value = Math.floor(value * 100) / 100000
      if (value >= 10000) value = Math.floor(value * 100) / 100000
    }
    value = Number(value).toFixed(3)
    if (value >= 10) value = 9.999
    onChangeAlcoholValue(value)
  }

  const searchUser = async () => {
    onChangeWitnessUserId(registerAlcoholCheck.witnessNcsUserIDBeforeSearch)
    const data: GetUserInfo = {
      kaisyaCode: loginInfo?.kaisyaCode || '',
      ncsUserId: registerAlcoholCheck.witnessNcsUserIDBeforeSearch,
      checkDate: moment().format('YYYYMMDDTHHmmss+0900'),
    }
    try {
      setSearchLoading(true)
      setErrorSearch('')
      resetValidateErrorMessage('errorWittnessUserByText')
      resetValidateErrorMessage('errorWittnessUserById')
      onChangeWitnessUserNameById('')
      const res = await getUserInfo(token, data)
      // console.log(res)
      if (res.result == 0) {
        setSearchLoading(false)
        setErrorSearch('')
        onChangeWitnessUserNameById(res.ncsUserName)
      } else {
        setSearchLoading(false)
        onChangeWitnessUserNameById('')
        setErrorSearch('ユーザーが見つかりません')
      }
    } catch (error) {
      console.log(error)
      setSearchLoading(false)
      setErrorSearch('検索中にエラーが発生しました')
    }
  }

  const onChangeWitnessUserNameText = (e: any) => {
    resetValidateErrorMessage('errorWittness')
    resetValidateErrorMessage('errorWittnessUserById')
    resetValidateErrorMessage('errorWittnessUserByText')
    onChangeRegisterAlcoholItems(e)
  }

  const customRadio = (
    name: string,
    value: string,
    label: string,
    colNum: number,
    disabled: boolean,
    onChange: (
      e: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLSpanElement>
    ) => void,
    checked?: boolean
  ) => (
    <div
      className={`d-flex align-items-center modal-alcohol-form-col-${colNum}`}
    >
      <label className="form-custom-radio">
        <input
          type="radio"
          checked={checked}
          name={name}
          value={value || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
        />
        <span
          className={'checkmark ' + (disabled ? 'checkmark-disabled' : '')}
        ></span>
      </label>
      <span
        className={'mb--1 mr-4' + (disabled ? '' : ' label-pointer')}
        onChange={(e: React.FormEvent<HTMLSpanElement>) => onChange(e)}
      >
        {label}
      </span>
    </div>
  )

  return (
    <fieldset
      className={'mt-3' + (disabled ? ' fieldset-disable' : '')}
      disabled={disabled}
    >
      <div className="modal-alcohol-form">
        <div className="modal-alcohol-form-col-3"></div>
        <div className="modal-alcohol-form-col-9">
          <span className="mb-1 achievement-register-error-msg">
            {registerAlcoholValidate.errorWittness}
          </span>
        </div>
      </div>
      <div className="modal-alcohol-form">
        <div className="modal-alcohol-form-col-2">立会者</div>
        <div className="modal-alcohol-form-col-1">:</div>
        <div className="modal-alcohol-form-col-9">
          <div className="d-flex mr-3">
            <label className="form-custom-radio">
              <input
                type="radio"
                checked={registerAlcoholRadio.isId}
                value="ID"
                name="witnessUserKind"
                onChange={(e) => onChangeRegisterAlcoholItems(e)}
              />
              <span
                className={
                  'checkmark ' + (disabled ? 'checkmark-disabled' : '')
                }
              ></span>
            </label>
            <span
              className={
                'radio-label mb--1 pleft-8' + (disabled ? '' : ' label-pointer')
              }
            >
              <Input
                type="text"
                style={{ height: 10, width: 162 }}
                disabled={!registerAlcoholRadio.isId || searchLoading}
                name="witnessNcsUserID"
                placeholder="NCSUserIDを入力"
                onChange={() => onChangeId()}
                onBlur={(e: any) => changeId(e)}
              />
            </span>
            <Button
              color="primary"
              className="button-register"
              onClick={searchUser}
              disabled={!registerAlcoholRadio.isId || searchLoading}
            >
              {searchLoading ? '検索中...' : '検索'}
            </Button>
            <span className="mt-1">
              {registerAlcoholCheck.witnessNcsUserNameById}
            </span>
            <span className="mt-1 achievement-register-error-msg">
              {errorSearch}
            </span>
            <span className="mt-1 achievement-register-error-msg">
              {registerAlcoholValidate.errorWittnessUserById}
            </span>
          </div>
          <div className="d-flex mr-3">
            <label className="form-custom-radio">
              <input
                type="radio"
                checked={!registerAlcoholRadio.isId}
                value="直接入力"
                name="witnessUserKind"
                disabled={searchLoading}
                onChange={(e) => onChangeRegisterAlcoholItems(e)}
              />
              <span
                className={
                  'checkmark ' + (disabled ? 'checkmark-disabled' : '')
                }
              ></span>
            </label>
            <span
              className={
                'radio-label mb--1 pleft-8' + (disabled ? '' : ' label-pointer')
              }
            >
              <Input
                type="text"
                style={{ height: 10, width: 162 }}
                disabled={registerAlcoholRadio.isId}
                placeholder="立会者名を直接入力"
                name="witnessNcsUserNameByText"
                onChange={(e: any) => onChangeWitnessUserNameText(e)}
              />
            </span>
            <span className="mt-1 ml-2 achievement-register-error-msg">
              {registerAlcoholValidate.errorWittnessUserByText}
            </span>
          </div>
        </div>
      </div>
      <hr className="m-3" />
      <div className="alcohol-row modal-alcohol-form mb-2">
        <div className="modal-alcohol-form-col-2">実施方法</div>
        <div className="modal-alcohol-form-col-1">:</div>
        {customRadio(
          'witnessKind',
          '対面',
          '対面',
          2,
          disabled,
          (e) => onChangeRegisterAlcoholItems(e),
          registerAlcoholRadio.isLocal
        )}
        {customRadio(
          'witnessKind',
          'リモート',
          'リモート',
          7,
          disabled,
          (e) => onChangeRegisterAlcoholItems(e),
          !registerAlcoholRadio.isLocal
        )}
      </div>
      <div className="modal-alcohol-form">
        <div className="modal-alcohol-form-col-2">実施方法コメント</div>
        <div className="modal-alcohol-form-col-1">:</div>
        <div className="modal-alcohol-form-col-9">
          <Input
            className="disable-resize"
            name="witnessText"
            type="textarea"
            placeholder="自由入力"
            onChange={(e) => onChangeRegisterAlcoholItems(e)}
          />
        </div>
      </div>
      <hr className="m-3" />
      <div className="modal-alcohol-form">
        <div className="modal-alcohol-form-col-2">実施区分</div>
        <div className="modal-alcohol-form-col-1">:</div>
        {customRadio(
          'implementation',
          '運行前',
          '運行前',
          2,
          disabled,
          (e) => onChangeRegisterAlcoholItems(e),
          registerAlcoholRadio.isBefore
        )}
        {customRadio(
          'implementation',
          '運行後',
          '運行後',
          7,
          disabled,
          (e) => onChangeRegisterAlcoholItems(e),
          !registerAlcoholRadio.isBefore
        )}
      </div>
      <hr className="m-3" />
      <div className="modal-alcohol-form">
        <div className="modal-alcohol-form-col-2">アルコール検知器</div>
        <div className="modal-alcohol-form-col-1">:</div>
        <div className="modal-alcohol-form-col-9">画像認識なし</div>
      </div>
      <hr className="m-3" />
      <div className="modal-alcohol-form">
        <div className="modal-alcohol-form-col-2">測定値入力(mg/L)</div>
        <div className="modal-alcohol-form-col-1">:</div>
        <div className="modal-alcohol-form-col-9">
          <Input
            type="number"
            style={{ height: 10, width: 100 }}
            name="alcoholValueManual"
            placeholder="数値を入力"
            className="form-alcohol-value-manual"
            step={0.001}
            value={registerAlcoholCheck.alcoholValueManual}
            onChange={(e: any) => onChangeRegisterAlcoholItems(e)}
            onBlur={(e: any) => changeAlcoholValue(e)}
          />
          <span className="mt-1 ml-2 achievement-register-error-msg">
            {registerAlcoholValidate.errorAlcoholValue}
          </span>
        </div>
      </div>
      <hr className="m-3" />
      <div className="modal-alcohol-form">
        <div className="modal-alcohol-form-col-2">発熱</div>
        <div className="modal-alcohol-form-col-1">:</div>
        {customRadio(
          'fever',
          'なし',
          'なし',
          2,
          disabled,
          (e) => onChangeRegisterAlcoholItems(e),
          registerAlcoholRadio.isFever
        )}
        {customRadio(
          'fever',
          'あり',
          'あり',
          7,
          disabled,
          (e) => onChangeRegisterAlcoholItems(e),
          !registerAlcoholRadio.isFever
        )}
      </div>
      <hr className="m-3" />
      <div className="modal-alcohol-form">
        <div className="modal-alcohol-form-col-2">体調</div>
        <div className="modal-alcohol-form-col-1">:</div>
        {customRadio(
          'health',
          '良好',
          '良好',
          2,
          disabled,
          (e) => onChangeRegisterAlcoholItems(e),
          registerAlcoholRadio.isGood
        )}
        {customRadio(
          'health',
          '不良',
          '不良',
          7,
          disabled,
          (e) => onChangeRegisterAlcoholItems(e),
          !registerAlcoholRadio.isGood
        )}
      </div>
      <hr className="m-3" />
      <div className="modal-alcohol-form">
        <div className="modal-alcohol-form-col-2">運転者コメント</div>
        <div className="modal-alcohol-form-col-1">:</div>
        <div className="modal-alcohol-form-col-9">
          <Input
            className="disable-resize"
            type="textarea"
            placeholder="自由入力"
            name="description"
            onChange={(e) => onChangeRegisterAlcoholItems(e)}
          />
        </div>
      </div>
      <hr className="m-3" />
    </fieldset>
  )
}

export default AchievementItems
