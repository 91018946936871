import React from 'react'
import IconSvg from './IconSvg'

type SvgImgProps = {
  content: string
  onClick: () => void
  disable?: boolean
}

const SvgImg: React.VFC<SvgImgProps> = ({ content, onClick, disable }) => {
  return (
    <div
      className={`pdf-btn ml-2 ${disable ? 'disableSvgBtn' : ''}`}
      onClick={disable ? () => null : onClick}
    >
      <IconSvg />
      {content}
    </div>
  )
}

export default SvgImg
