import React from 'react'
import 'styles/fullloadingspinner.scss'

const LoadingIndicator: React.VFC = () => {
  return (
    <div className="loading-indicator-container">
      <div className="loading-indicator" />
    </div>
  )
}

export default LoadingIndicator
