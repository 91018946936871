import { Routes, Labels } from 'common/enums'
import {
  InspectionCheckSheetForm,
  SelectedInspectionItem,
} from 'common/types/form/inspection_check_sheet'
import { InspectionPattern } from 'common/types/inspection_patterns'
import { createRequiredMessage } from 'common/utils'
import OnSubmitButton from 'components/atoms/Button/OnSubmitButton'
import TextForm from 'components/atoms/Form/Text/CustomTextForm'
import ConfirmModal from 'components/atoms/Modal/ConfirmModal'
import Display4 from 'components/atoms/Span/Display4'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Row,
  Table,
} from 'reactstrap'
import {
  initInspectionCheckSheetForm,
  initInspectionItems,
  onChange as serviceOnChange,
  saveInspectionPattern,
} from 'services/admin/inspection_check_sheet_form'
import {
  inspectionItemTableBody,
  inspectionItemTableHeader,
} from 'services/admin/inspection_item'

type TFormCard = {
  isCreate: boolean
  inspectionPatterns: InspectionPattern | undefined
}

const FormCard: React.FC<TFormCard> = ({
  isCreate,
  inspectionPatterns,
}: TFormCard) => {
  const { storeCache } = useContext(AuthContext)
  const { user, categories, inspectionItems } = useMemo(
    () => storeCache,
    [storeCache]
  )
  const history = useHistory()

  const defaultValues = initInspectionCheckSheetForm(
    isCreate,
    user!.client_id,
    inspectionPatterns,
    inspectionItems
  )
  const defaultInspectionItems = initInspectionItems(
    inspectionPatterns,
    inspectionItems,
    false
  )

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<InspectionCheckSheetForm>({ defaultValues })
  const [selectedInspectionItemHeader, setSelectedInspectionItemHeader] =
    useState<boolean>(false)
  const [selectedInspectionItems, setSelectedInspectionItems] = useState<
    SelectedInspectionItem[]
  >(defaultInspectionItems)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const headers = inspectionItemTableHeader()
  const datas = inspectionItemTableBody(categories, inspectionItems)

  const onToggle = () => setIsModalOpen(!isModalOpen)
  const checkDiff = () => {
    if (
      JSON.stringify(watch()) === JSON.stringify(defaultValues) &&
      JSON.stringify(selectedInspectionItems) ===
        JSON.stringify(defaultInspectionItems)
    )
      history.push(Routes.InspectionCheckSheet)
    else setIsModalOpen(!isModalOpen)
  }
  const closeAllModal = () => {
    onToggle()
    history.push(Routes.InspectionCheckSheet)
  }
  const onChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number | null
  ) =>
    serviceOnChange(
      e,
      index,
      inspectionPatterns,
      inspectionItems,
      selectedInspectionItems,
      setSelectedInspectionItems,
      selectedInspectionItemHeader,
      setSelectedInspectionItemHeader
    )
  const save = (data: InspectionCheckSheetForm) =>
    saveInspectionPattern(storeCache, history, data, selectedInspectionItems)

  return (
    <>
      <ConfirmModal
        isOpen={isModalOpen}
        onAction={() => {
          onToggle()
          handleSubmit(save)()
        }}
        onToggle={closeAllModal}
        onClose={onToggle}
      />
      <div className="main-content">
        <Container className="mt-3">
          <Row className="justify-content-center">
            <Col lg="12" md="12">
              <Card className="border-0">
                <CardBody className="px-lg-4 py-lg-0">
                  <div className="mb-3 d-flex align-items-center">
                    <span
                      onClick={checkDiff}
                      className="left-alt"
                      style={{ cursor: 'pointer' }}
                    >
                      ＜
                    </span>
                    <Display4
                      content={`点検パターン${isCreate ? '新規登録' : '編集'}`}
                    />
                  </div>
                  <Form
                    role="form"
                    className="check-form"
                    onSubmit={handleSubmit(save)}
                  >
                    <TextForm
                      name="name"
                      label={Labels.InspectionPatternName}
                      control={control}
                      rules={{
                        required: createRequiredMessage(
                          Labels.InspectionPatternName
                        ),
                      }}
                      error={errors.name}
                    />
                    <p>
                      このパターンで実施する点検項目（実施する項目をチェックで選択）
                    </p>
                    <Table className="align-items-center" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col-1">
                            {renderCheckbox(
                              'col-checkbox',
                              selectedInspectionItemHeader,
                              (e: React.ChangeEvent<HTMLInputElement>) =>
                                onChange(e, null)
                            )}
                          </th>
                          {headers.map(({ Header }, i) => {
                            return (
                              i > 0 && 
                              <th scope="col" key={i}>
                                {Header}
                              </th>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody className="custom-scrollbar">
                        {datas.map((d, i) => {
                          return (
                            <tr key={i}>
                              <th scope="row">
                                {renderCheckbox(
                                  `row-checkbox-${i}`,
                                  Object.values(selectedInspectionItems[i])[0],
                                  (e: React.ChangeEvent<HTMLInputElement>) =>
                                    onChange(e, i)
                                )}
                              </th>
                              <th scope="row">
                                <span
                                  className="category-name"
                                  style={{ background: d.category_color }}
                                >
                                  {d.category_name}
                                </span>
                              </th>
                              <th scope="row">{d.content}</th>
                              <th scope="row">{d.detail}</th>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                    <div className="mt-3 d-flex justify-content-end btn-oper-wrap">
                      <Button color="primary" type="button" onClick={checkDiff}>
                        一覧に戻る
                      </Button>
                      <OnSubmitButton content={Labels.Save} />
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

const renderCheckbox = (
  id: string,
  checked: boolean,
  onChange: React.ChangeEventHandler<HTMLInputElement>
): JSX.Element => {
  return (
    <div className="custom-control custom-checkbox">
      <input
        className="custom-control-input"
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <label className="custom-control-label" htmlFor={id}>
        &nbsp;
      </label>
    </div>
  )
}

export default FormCard
