import {
  AchievementAlc,
  CarScope,
  UserScope,
  FilterParams,
  Pagination,
  AchievementAlcRegisterParam,
  AchievementAlcRegister,
  AchievementRegisterCar,
  AchievementRegisterNcsUser,
  AchievementRegisterWitnessUser,
  AchievementRegisterWitnessMethod,
  AchievementRegisterAuth,
} from 'common/types/achievement'
import { IStoreCache } from 'common/types/auth_provider'
import { IAlcoholProvider } from 'common/types/alcohol_provider'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import {
  fetchAchievementAlcohol,
  fetchAchievementCsv,
  fetchAchievementPdf,
  fetchCsvDownLoad,
} from 'repositories/achievenment'
import { alertService } from 'services/alert'
import { now } from 'common/time'
import { nullToNum, nullToStr } from 'common/utils'
import { AuthContext } from './AuthProvider'

export const AlcoholContext = React.createContext<IAlcoholProvider>({
  loadData: async (confirmDate?: any) => undefined,
  editData: [],
  editedData: [],
  toggleSelectAll: (
    key: 'alc_checklogs_status_shuki' | 'alc_checklogs_status_shonin'
  ) => undefined,
  handleEdit: (id, key, value) => undefined,
  checkAll: {
    alc_checklogs_status_shuki: false,
    alc_checklogs_status_shonin: false,
  },
  onApplyFilter: () => undefined,
  onUpdateSuccess: () => {},
  isLoading: false,
  currentPage: 1,
  totalPage: 1,
  totalRecord: 0,
  limit: 20,
  onChangeLimit: (num: number) => undefined,
  onChangePage: (num: number) => undefined,
  handleSort: (sortBy: string) => undefined,
  sortBy: [],
  scopeCar: [],
  scopeUser: [],
  downLoadCsv: () => undefined,
  filters: {},
  downLoadPdf: () => undefined,
  registerAchievements: [],
  registerAchievementRows: [],
  handleRegisterCarName: (value: any) => undefined,
  handleRegisterUserName: (value: any) => undefined,
  registerConditions: {},
  registerAlcoholCheck: {},
  registerAlcoholRadio: {},
  registerAlcoholValidate: {},
  onChangeScopeUser: (userList: any[]) => undefined,
  requiredValidation: () => false,
  successRegisteredAchievementAlcRows: (rows: AchievementAlc[]) => undefined,
  failedRegisteredAchievementAlcRows: (rows: AchievementAlcRegisterParam[]) => undefined,
  resetRegisterAchievements: () => undefined,
  changeIsAllFailed: (value: boolean) => false,
  resetRegisterItems: () => undefined,
  resetValidateErrorMessage: () => undefined,
  onBlurChangeWitnessUserId: (value: any) => undefined,
  onChangeAlcoholValue: (value: string) => undefined,
  onChangeWitnessUserId: (id: string) => undefined,
  onChangeWitnessUserNameById: (name: string) => undefined,
  onChangeRegisterAlcoholItems: () => undefined,
  onChangeRegisterUserCarItems: () => undefined,
  addAchievement: () => undefined,
})

export const AlcoholProvider = ({
  children,
}: {
  children: any
}): JSX.Element => {
  const { loginInfo }: IStoreCache = useContext(AuthContext).storeCache
  const token = localStorage.getItem('token') || ''
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<Pagination>({
    currentPage: 1,
    totalPage: 1,
    totalRecord: 0,
  })
  const [achievements, setAchievements] = useState<AchievementAlc[]>([])
  const [editData, setEditData] = useState<AchievementAlc[]>([])
  const [editedData, setEditedData] = useState<AchievementAlc[]>([])
  const [sortBy, setSortBy] = useState<string[]>(['-reg_date'])
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [filters, setFilters] = useState({
    kaisya_code: loginInfo?.kaisyaCode || '',
    ncs_user_id: loginInfo?.ncsUserId || '',
    start_date: moment().startOf('date').format('YYYYMMDDHHmmss'),
    end_date: moment().endOf('date').format('YYYYMMDDHHmmss'),
    search_type: '',
    search_text: '',
    achievement_type: '2',
    check_data: '3',
    check_status: '3',
    check_log: '3',
    sort_by: '-reg_date',
  })
  const [scopeCar, setScopeCar] = useState<CarScope[]>([])
  const [scopeUser, setScopeUser] = useState<UserScope[]>([])
  const [checkAll, setCheckAll] = useState<{
    alc_checklogs_status_shuki: boolean | null
    alc_checklogs_status_shonin: boolean | null
  }>({
    alc_checklogs_status_shuki: false,
    alc_checklogs_status_shonin: false,
  })
  const [registerConditions, setRegisterConditions] = useState({
    confirmDate: moment().format('YYYY-MM-DDTHH:mm'),
    driveRecorderId: '',
    carName: '',
    carSoshikiName: '',
    ncsUserId: '',
    ncsUserName: '',
    ncsUserSoshikiName: '',
    alcoholChkSvc: false,
  })
  const [registerAlcoholCheck, setRegisterAlcoholCheck] = useState({
    witnessUserKind: 'ID',
    witnessNcsUserIDBeforeSearch: '',
    witnessNcsUserID: '',
    witnessNcsUserNameById: '',
    witnessNcsUserNameByText: '',
    witnessKind: '対面',
    witnessText: '',
    implementation: '運行前',
    alcoholValueManual: '',
    fever: 'なし',
    health: '良好',
    description: '',
  })
  const [registerAlcoholRadio, setRegisterAlcoholRadio] = useState({
    isId: true,
    isLocal: true,
    isBefore: true,
    isFever: true,
    isGood: true,
  })
  const [registerAlcoholValidate, setRegisterAlcoholValidate] = useState({
    errorWittness: '',
    errorWittnessUserById: '',
    errorWittnessUserByText: '',
    errorAlcoholValue: '',
    errorSubmit: '',
  })
  const [registerAchievements, setRegisterAchievements] = useState<
    AchievementAlcRegisterParam[]
  >([])
  const [registerAchievementRows, setRegisterAchievementRows] = useState<
    AchievementAlc[]
  >([])
  const [registeredAchievementRows, setRegisteredAchievementRows] = useState<
    AchievementAlc[]
  >([])
  const [isAllFailed, setIsAllFailed] = useState<boolean>(false)

  useEffect(() => {
    const arr: AchievementAlc[] = []
    for (let index = 0; index < editData.length; index++) {
      if (editData[index].achievement_id) {
        const object1 = editData[index]
        const object2 = achievements.find(
          (e) => object1.achievement_id === e.achievement_id
        )
        if (
          object2 &&
          (nullToStr(object1.car_name) !== nullToStr(object2.car_name) ||
            nullToStr(object1.car_soshiki_name) !==
              nullToStr(object2.car_soshiki_name) ||
            nullToNum(object1.alc_checklogs_status_shuki) !==
              nullToNum(object2.alc_checklogs_status_shuki) ||
            nullToNum(object1.alc_checklogs_status_shonin) !==
              nullToNum(object2.alc_checklogs_status_shonin) ||
            nullToStr(object1.alc_checklogs_shonin_user_name) !==
              nullToStr(object2.alc_checklogs_shonin_user_name) ||
            nullToStr(object1.alc_description) !==
              nullToStr(object2.alc_description))
        ) {
          arr.push(object1)
        }
      }
    }
    setEditedData(arr)
  }, [achievements, editData, setEditedData])

  const handleSort = (sortName: string) => {
    const sort_by = [...sortBy]
    if (sortName === 'reg_date') {
      if (sort_by.length === 1) {
        sort_by[0] = sort_by[0] === 'reg_date' ? '-reg_date' : 'reg_date'
      } else {
        sort_by[0] = 'reg_date'
        sort_by.length = 1
      }
    } else if (sort_by.length === 1) {
      sort_by[0] = sortName
      sort_by[1] = '-reg_date'
    } else if (sortName === sort_by[0]) {
      sort_by[0] = `-${sortName}`
    } else {
      sort_by[0] = sortName
    }
    setSortBy(sort_by)
  }

  useEffect(() => {
    loadData()
  }, [filters, currentPage, limit, sortBy])

  useEffect(() => {
    const data = editData
    for (let i = 0; i < registerAchievementRows.length; i++) {
      data.shift()
    }
    registerAchievementRows.map((achievement: any) => {
      return data.unshift(achievement)
    })
    setEditData(JSON.parse(JSON.stringify(data)))
    let rows: AchievementAlc[] = []
    registerAchievementRows.map((row: AchievementAlc) => {
      if (!row.achievement_id) {
        rows.push(row)
      }
      return true
    })
    setRegisterAchievementRows(rows)
    if (rows.length <= 0) {
      resetRegisterAchievements()
    }
  }, [registeredAchievementRows])

  useEffect(() => {
    if (
      registerAchievementRows.length > 0 &&
      registerAchievements.length === registerAchievementRows.length &&
      !isAllFailed
    ) {
      const data = editData
      data.unshift(
        JSON.parse(
          JSON.stringify(
            registerAchievementRows[registerAchievementRows.length - 1]
          )
        )
      )
      if (data.length > limit) {
        data.pop()
      }
      setEditData(JSON.parse(JSON.stringify(data)))
    }
  }, [registerAchievements])

  const onApplyFilter = (params: FilterParams) => {
    setFilters((prev) => ({
      ...prev,
      ...params,
    }))
    if (currentPage > 1) {
      setCurrentPage(1)
    }
  }

  const loadData = async (confirmDate?: any) => {
    setIsLoading(true)
    try {
      const sorts = sortBy.map((sort) => {
        if (sort.includes('_shonin')) {
          return sort.replace('_shonin', '')
        }
        return sort
      })

      if (confirmDate) {
        const newFilters = filters
        newFilters.start_date = moment(confirmDate)
          .startOf('date')
          .format('YYYYMMDDHHmmss')
        newFilters.end_date = moment(confirmDate)
          .endOf('date')
          .format('YYYYMMDDHHmmss')
        setFilters((prev) => ({ ...prev, ...newFilters }))
      }

      const res = await fetchAchievementAlcohol(token, {
        ...filters,
        page: currentPage.toString(),
        limit: limit.toString(),
        sort_by: sorts.join(','),
      })
      setData({
        currentPage: res.currentPage || 1,
        totalPage: res.totalPage || 1,
        totalRecord: res.totalRecord || 0,
      })
      setAchievements(JSON.parse(JSON.stringify(res.achievements)))
      const data = res?.achievements?.map((e) => {
        if (
          !e.achievement_id ||
          !e.confirm_date ||
          !!e.alc_checklogs_status_shonin
        ) {
          return {
            ...e,
            editable: false,
          }
        }
        return {
          ...e,
          editable: true,
        }
      })

      setEditData(JSON.parse(JSON.stringify(data)))

      setEditedData([])
      if (Array.isArray(res?.scope_data)) {
        setScopeCar(res.scope_data[0]?.carList)
      } else {
        setScopeCar(res.scope_data.carList)
      }
      if (Array.isArray(res?.scope_data)) {
        setScopeUser(res.scope_data[0]?.userList)
      } else {
        setScopeUser(res.scope_data.userList)
      }
      if (res.achievements && res.achievements.length > 0) {
        const shukiUntick = res.achievements.some(
          (e) => e.alc_checklogs_status_shuki !== 1
        )
        const shukiTick = res.achievements.some(
          (e) => e.alc_checklogs_status_shuki === 1
        )
        const shoninUntick = res.achievements.some(
          (e) => e.alc_checklogs_status_shonin !== 1
        )
        const shoninTick = res.achievements.some(
          (e) => e.alc_checklogs_status_shonin === 1
        )

        if (!shukiUntick) {
          setCheckAll((prev) => ({
            ...prev,
            alc_checklogs_status_shuki: true,
          }))
        } else if (!shukiTick) {
          setCheckAll((prev) => ({
            ...prev,
            alc_checklogs_status_shuki: false,
          }))
        }
        if (shukiUntick && shukiTick) {
          setCheckAll((prev) => ({
            ...prev,
            alc_checklogs_status_shuki: null,
          }))
        }
        if (!shoninUntick) {
          setCheckAll((prev) => ({
            ...prev,
            alc_checklogs_status_shonin: true,
          }))
        } else if (!shoninTick) {
          setCheckAll((prev) => ({
            ...prev,
            alc_checklogs_status_shonin: false,
          }))
        }
        if (shoninUntick && shoninTick) {
          setCheckAll((prev) => ({
            ...prev,
            alc_checklogs_status_shonin: null,
          }))
        }
      }
      setIsLoading(false)
    } catch (error) {
      alertService.show(
        false,
        'システムエラーが発生しました。しばらく時間をおいてから再アクセスするか、お問い合わせください。（実績取得）'
      )
      setIsLoading(false)
    }
  }

  const handleEdit = (id: string, key: string, value: any) => {
    // console.log(`handleEdit-${id}-${key}`, value)

    const dataEdit = [...editData]
    const index = dataEdit.findIndex((e) => e.achievement_id === id)
    if (!dataEdit[index]?.editable && key !== 'alc_checklogs_status_shonin') {
      return
    }
    if (
      key === 'alc_checklogs_status_shonin' &&
      !dataEdit[index].alc_checklogs_status_shuki
    ) {
      alertService.show(false, '先に酒気帯びなしの確認が必要です')
      return
    }

    if (key === 'car_name') {
      const cars = scopeCar.filter((e) => e.driveRecorderId === value.value)
      var result =
        cars?.length > 0
          ? cars?.reduce(function (res, obj) {
              return moment(obj.startDate).unix() < moment(res.startDate).unix()
                ? obj
                : res
            })
          : null
      dataEdit[index]['drive_recorder_id'] = value?.value
      dataEdit[index]['car_name'] = value?.label
      dataEdit[index]['car_soshiki_name'] = (result && result?.pathName) || null
    }
    if (key === 'alc_checklogs_status_shuki') {
      dataEdit[index]['alc_checklogs_status_shuki'] = value ? 1 : 0
    }
    if (key === 'alc_checklogs_status_shonin') {
      dataEdit[index]['alc_checklogs_shonin_user_name'] =
        value && loginInfo?.ncsUserName ? loginInfo?.ncsUserName : null
      dataEdit[index]['alc_checklogs_status_shonin'] = value ? 1 : 0
      if (value) {
        dataEdit[index]['alc_checklogs_shonin_ncs_user_id'] =
          loginInfo?.ncsUserId || null
      }
    }
    if (key === 'alc_description') {
      dataEdit[index]['alc_description'] = value
    }
    setEditData([...dataEdit])
  }

  const handleRegisterCarName = (value: any) => {
    const cars = scopeCar.filter((e) => {
      return e.driveRecorderId === value?.value
    })
    var result =
      cars?.length > 0
        ? cars?.reduce(function (res, obj) {
            return moment(obj.startDate).unix() < moment(res.startDate).unix()
              ? obj
              : res
          })
        : null
    const carName = String(value?.label)
    const pathName = result?.pathName ? String(result?.pathName) : ''
    setRegisterConditions((prev) => ({
      ...prev,
      driveRecorderId: String(value.driveRecorderId),
      carName: carName,
      carSoshikiName: pathName,
    }))
  }

  const handleRegisterUserName = (value: any) => {
    const userId = String(value?.ncsUserId)
    const userName = String(value?.name)
    const userSoshikiName = String(value?.listPath ? value?.listPath[0] : value.soshikiName)
    setRegisterConditions((prev) => ({
      ...prev,
      ncsUserId: userId,
      ncsUserName: userName,
      ncsUserSoshikiName: userSoshikiName,
    }))
  }

  const onChangeAlcoholValue = (value: string) => {
    setRegisterAlcoholCheck((prev) => ({
      ...prev,
      alcoholValueManual: value,
    }))
  }

  const onChangeWitnessUserId = (id: string) => {
    setRegisterAlcoholCheck((prev) => ({
      ...prev,
      witnessNcsUserID: id,
    }))
  }

  const onChangeWitnessUserNameById = (name: string) => {
    setRegisterAlcoholCheck((prev) => ({
      ...prev,
      witnessNcsUserNameById: name,
    }))
  }

  const onBlurChangeWitnessUserId = (value: any) => {
    setRegisterAlcoholCheck((prev) => ({
      ...prev,
      witnessNcsUserIDBeforeSearch: value,
    }))
  }

  const onChangeRegisterAlcoholItems = (e: any) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'confirmDate') {
      if (!value) {
        setRegisterConditions((prev) => ({
          ...prev,
          confirmDate: '',
          ncsUserId: '',
          ncsUserName: '',
          ncsUserSoshikiName: '',
          driveRecorderId: '',
          carName: '',
          carSoshikiName: '',
        }))
        return
      }
      if (moment(value).format('YYYYMMDD HH:mm') > moment().format('YYYYMMDD HH:mm')) {
        return
      }
      if (
        moment(value).format('YYYYMMDD') !==
        moment(registerConditions.confirmDate).format('YYYYMMDD')
      ) {
        setRegisterConditions((prev) => ({
          ...prev,
          ncsUserId: '',
          ncsUserName: '',
          ncsUserSoshikiName: '',
          driveRecorderId: '',
          carName: '',
          carSoshikiName: '',
        }))
      }
      let confirmDate = value.split('-')
      if (confirmDate[0].length > 4)
        confirmDate[0] = confirmDate[0].slice(0, -1)
      confirmDate = confirmDate.join('-')
      setRegisterConditions((prev) => ({ ...prev, confirmDate }))
    }
    if (name === 'witnessUserKind') {
      setRegisterAlcoholCheck((prev) => ({
        ...prev,
        witnessUserKind: value === 'ID' ? 'ID' : '直接入力',
      }))
      setRegisterAlcoholRadio((prev) => ({
        ...prev,
        isId: !registerAlcoholRadio.isId,
      }))
    }
    if (name === 'witnessKind') {
      setRegisterAlcoholCheck((prev) => ({
        ...prev,
        witnessKind: value === '対面' ? '対面' : 'リモート',
      }))
      setRegisterAlcoholRadio((prev) => ({
        ...prev,
        isLocal: !registerAlcoholRadio.isLocal,
      }))
    }
    if (name === 'implementation') {
      setRegisterAlcoholCheck((prev) => ({
        ...prev,
        implementation: value === '運行前' ? '運行前' : '運行後',
      }))
      setRegisterAlcoholRadio((prev) => ({
        ...prev,
        isBefore: !registerAlcoholRadio.isBefore,
      }))
    }
    if (name === 'fever') {
      setRegisterAlcoholCheck((prev) => ({
        ...prev,
        fever: value === 'なし' ? 'なし' : 'あり',
      }))
      setRegisterAlcoholRadio((prev) => ({
        ...prev,
        isFever: !registerAlcoholRadio.isFever,
      }))
    }
    if (name === 'health') {
      setRegisterAlcoholCheck((prev) => ({
        ...prev,
        health: value === '良好' ? '良好' : '不良',
      }))
      setRegisterAlcoholRadio((prev) => ({
        ...prev,
        isGood: !registerAlcoholRadio.isGood,
      }))
    }
    if (name === 'alcoholValueManual') {
      let alcoholValue = value
      if (value.length > 5) alcoholValue = value.slice(0, -1)
      if (alcoholValue.indexOf('-') !== -1) return
      setRegisterAlcoholCheck((prev) => ({
        ...prev,
        alcoholValueManual: alcoholValue,
      }))
    }
    if (name === 'witnessNcsUserNameByText') {
      setRegisterAlcoholCheck((prev) => ({
        ...prev,
        witnessNcsUserNameByText: value,
      }))
    }
    if (name === 'witnessText') {
      setRegisterAlcoholCheck((prev) => ({
        ...prev,
        witnessText: value,
      }))
    }
    if (name === 'description') {
      setRegisterAlcoholCheck((prev) => ({
        ...prev,
        description: value,
      }))
    }
  }

  const onChangeRegisterUserCarItems = (e: any) => {
    setRegisterConditions((prev) => ({
      ...prev,
      ncsUserId: '',
      ncsUserName: '',
      ncsUserSoshikiName: '',
      driveRecorderId: '',
      carName: '',
      carSoshikiName: '',
    }))
  }

  const onChangeScopeUser = (userList: any[]) => {
    setScopeUser(userList)
  }

  const requiredValidation = (): boolean => {
    const errorWittness =
      registerAlcoholRadio.isId &&
      registerAlcoholCheck.witnessNcsUserIDBeforeSearch === '' &&
      registerAlcoholCheck.witnessNcsUserNameById === ''
        ? '立会者を選択又は直接入力してください'
        : ''
    setRegisterAlcoholValidate((prev) => ({
      ...prev,
      errorWittness: errorWittness,
    }))
    const errorWittnessUserById =
      registerAlcoholRadio.isId &&
      registerAlcoholCheck.witnessNcsUserIDBeforeSearch !== '' &&
      registerAlcoholCheck.witnessNcsUserNameById === ''
        ? '立会者を選択してください'
        : ''
    setRegisterAlcoholValidate((prev) => ({
      ...prev,
      errorWittnessUserById: errorWittnessUserById,
    }))
    const errorWittnessUserByText =
      !registerAlcoholRadio.isId &&
      registerAlcoholCheck.witnessNcsUserNameByText === ''
        ? '立会者名を入力してください'
        : ''
    setRegisterAlcoholValidate((prev) => ({
      ...prev,
      errorWittnessUserByText: errorWittnessUserByText,
    }))
    const errorAlcoholValue =
      registerAlcoholCheck.alcoholValueManual === ''
        ? '測定値を入力してください'
        : ''
    setRegisterAlcoholValidate((prev) => ({
      ...prev,
      errorAlcoholValue: errorAlcoholValue,
    }))
    const errorSubmit =
      errorWittness ||
      errorWittnessUserById ||
      errorWittnessUserByText ||
      errorAlcoholValue
        ? '入力に不備があります'
        : ''
    setRegisterAlcoholValidate((prev) => ({
      ...prev,
      errorSubmit: errorSubmit,
    }))

    return errorSubmit === ''
  }

  const resetValidateErrorMessage = (value: string) => {
    if (value === 'errorWittness') {
      setRegisterAlcoholValidate((prev) => ({
        ...prev,
        errorWittness: '',
      }))
    }
    if (value === 'errorWittnessUserById') {
      setRegisterAlcoholValidate((prev) => ({
        ...prev,
        errorWittnessUserById: '',
      }))
    }
    if (value === 'errorAlcoholValue') {
      setRegisterAlcoholValidate((prev) => ({
        ...prev,
        errorAlcoholValue: '',
      }))
    }
    if (value === 'errorWittnessUserByText') {
      setRegisterAlcoholValidate((prev) => ({
        ...prev,
        errorWittnessUserByText: '',
      }))
    }
    if (value === 'errorSubmit') {
      setRegisterAlcoholValidate((prev) => ({
        ...prev,
        errorSubmit: '',
      }))
    }
  }

  const successRegisteredAchievementAlcRows = (rows: AchievementAlc[]) => {
    setRegisteredAchievementRows(rows)
    const data = editData
    const mergedRows = rows.concat(data)
    setAchievements(mergedRows)
  }

  const failedRegisteredAchievementAlcRows = (rows: AchievementAlcRegisterParam[]) => {
    setRegisterAchievements(rows)
  }

  const resetRegisterAchievements = () => {
    // if (registerAchievementRows.length > 0) {
    //   const data = editData
    //   for (let i = 0; i < registerAchievementRows.length; i++) {
    //     data.shift()
    //   }
    //   setEditData(JSON.parse(JSON.stringify(data)))
    // }
    setRegisterAchievements([])
    setRegisterAchievementRows([])
  }

  const changeIsAllFailed = (value: boolean) => {
    setIsAllFailed(value)
  }

  const resetRegisterItems = () => {
    setRegisterConditions({
      confirmDate: moment().format('YYYY-MM-DDTHH:mm'),
      driveRecorderId: '',
      carName: '',
      carSoshikiName: '',
      ncsUserId: '',
      ncsUserName: '',
      ncsUserSoshikiName: '',
      alcoholChkSvc: false,
    })
    setRegisterAlcoholCheck({
      witnessUserKind: 'ID',
      witnessNcsUserIDBeforeSearch: '',
      witnessNcsUserNameById: '',
      witnessNcsUserNameByText: '',
      witnessNcsUserID: '',
      witnessKind: '対面',
      witnessText: '',
      implementation: '運行前',
      alcoholValueManual: '',
      fever: 'なし',
      health: '良好',
      description: '',
    })
    setRegisterAlcoholRadio({
      isId: true,
      isLocal: true,
      isBefore: true,
      isFever: true,
      isGood: true,
    })
    resetValidateErrorMessage('errorWittness')
    resetValidateErrorMessage('errorWittnessUserById')
    resetValidateErrorMessage('errorAlcoholValue')
    resetValidateErrorMessage('errorWittnessUserByText')
    resetValidateErrorMessage('errorSubmit')
  }

  const addAchievement = () => {
    const kaisyaCode: string = loginInfo?.kaisyaCode || ''
    const ncsUserId: string = loginInfo?.ncsUserId || ''
    const carSoshikiName = registerConditions.carSoshikiName
    const ncsUserSoshikiName = registerConditions.ncsUserSoshikiName
    const car: AchievementRegisterCar = {
      drive_recorder_id: registerConditions.driveRecorderId,
      car_name: registerConditions.carName,
    }
    const ncsUser: AchievementRegisterNcsUser = {
      ncs_user_id: registerConditions.ncsUserId,
      ncs_user_name: registerConditions.ncsUserName,
    }
    const witnessUserId =
      registerAlcoholCheck.witnessUserKind === 'ID'
        ? registerAlcoholCheck.witnessNcsUserID
        : ''
    const witnessUserName =
      registerAlcoholCheck.witnessUserKind === 'ID'
        ? registerAlcoholCheck.witnessNcsUserNameById
        : registerAlcoholCheck.witnessNcsUserNameByText
    const witnessUser: AchievementRegisterWitnessUser = {
      ncs_user_id: witnessUserId,
      ncs_user_name: witnessUserName,
    }
    const witnessMethod: AchievementRegisterWitnessMethod = {
      witness_kind: registerAlcoholCheck.witnessKind,
      witness_text: registerAlcoholCheck.witnessText,
    }
    const achievement: AchievementAlcRegister = {
      achievement_id: null,
      achievement_type: 'アルコールチェック',
      confirm_date: moment(registerConditions.confirmDate).format(
        'YYYY-MM-DD HH:mm:ss'
      ),
      reg_date: moment().format('YYYY-MM-DD HH:mm:ss'),
      ncs_user: ncsUser,
      witness_ncs_user: witnessUser,
      witness_method: witnessMethod,
      implementation: registerAlcoholCheck.implementation,
      alcohol_detector: '画像認識なし',
      assets_id: '',
      alcohol_value_manual: registerAlcoholCheck.alcoholValueManual,
      alcohol_value_detector: '',
      fever: registerAlcoholCheck.fever,
      health: registerAlcoholCheck.health,
      description: registerAlcoholCheck.description,
    }
    const achievementRow: AchievementAlcRegister = {
      achievement_id: null,
      achievement_type: 'アルコールチェック',
      confirm_date: moment(registerConditions.confirmDate).format(
        'YYYY/MM/DD HH:mm'
      ),
      reg_date: moment().format('YYYY/MM/DD HH:mm'),
      ncs_user: ncsUser,
      witness_ncs_user: witnessUser,
      witness_method: witnessMethod,
      implementation: registerAlcoholCheck.implementation,
      alcohol_detector: '画像認識なし',
      assets_id: '',
      alcohol_value_manual: registerAlcoholCheck.alcoholValueManual,
      alcohol_value_detector: '',
      fever: registerAlcoholCheck.fever,
      health: registerAlcoholCheck.health,
      description: registerAlcoholCheck.description,
    }
    const acihevementRegisterAuth: AchievementRegisterAuth = {
      kaisya_code: kaisyaCode,
      ncs_user_id: ncsUserId,
    }
    const achievementParams: AchievementAlcRegisterParam = {
      kaisya_code: kaisyaCode,
      car: car,
      achievement: achievement,
      achievement_register_auth: acihevementRegisterAuth
    }
    const achievementRows: AchievementAlcRegisterParam = {
      kaisya_code: kaisyaCode,
      car: car,
      achievement: achievementRow,
      achievement_register_auth: acihevementRegisterAuth
    }
    createAchievementRow(achievementRows, carSoshikiName, ncsUserSoshikiName)
    setRegisterAchievements((prev) => [...prev, achievementParams])
  }

  const createAchievementRow = (
    achievementParams: AchievementAlcRegisterParam,
    carSoshikiName: string,
    ncsUserSoshikiName: string
  ) => {
    const alcoholValueManual =
      achievementParams.achievement.alcohol_value_manual
    let alcCheckStatus = '○'
    let alcCheckConditionStatus = '×'
    if (parseFloat(alcoholValueManual) * 100 !== 0) {
      alcCheckStatus = '×'
    }
    if (
      achievementParams.achievement.fever === 'なし' &&
      achievementParams.achievement.health === '良好'
    ) {
      alcCheckConditionStatus = '○'
    }
    const achievementRow: AchievementAlc = {
      achievement_id: null,
      achievement_type: achievementParams.achievement.achievement_type,
      alc_check_condition_status: alcCheckConditionStatus,
      alc_check_status: alcCheckStatus,
      alc_checklogs_id: null,
      alc_checklogs_shonin_ncs_user_id: null,
      alc_checklogs_shonin_user_name: null,
      alc_checklogs_status_shonin: 0,
      alc_checklogs_status_shuki: 0,
      alc_description: '',
      assets_id: null,
      car_name: achievementParams.car.car_name,
      car_soshiki_name: carSoshikiName,
      confirm_date: achievementParams.achievement.confirm_date,
      description: achievementParams.achievement.description,
      editable: true,
      implementation: achievementParams.achievement.implementation,
      reg_date: '',
      user_name: achievementParams.achievement.ncs_user.ncs_user_name,
      user_soshiki_name: ncsUserSoshikiName,
      witness_kind: achievementParams.achievement.witness_method.witness_kind,
    }
    setRegisterAchievementRows((prev) => [...prev, achievementRow])
  }

  const toggleSelectAll = (
    key: 'alc_checklogs_status_shuki' | 'alc_checklogs_status_shonin'
  ) => {
    const dataEdit = editData.map((item) => {
      if (!item?.editable) {
        return item
      }
      if (key === 'alc_checklogs_status_shuki') {
        if (
          !item.alc_checklogs_status_shuki &&
          !checkAll.alc_checklogs_status_shuki
        ) {
          item.alc_checklogs_status_shuki = 1
        }
        if (
          checkAll.alc_checklogs_status_shuki &&
          !item.alc_checklogs_status_shonin &&
          item.alc_checklogs_status_shuki
        ) {
          item.alc_checklogs_status_shuki = 0
        }
      }
      if (key === 'alc_checklogs_status_shonin') {
        if (
          checkAll.alc_checklogs_status_shonin &&
          item.alc_checklogs_status_shonin &&
          (!item?.alc_checklogs_shonin_ncs_user_id ||
            (item?.alc_checklogs_shonin_ncs_user_id &&
              loginInfo?.ncsUserId === item.alc_checklogs_shonin_ncs_user_id))
        ) {
          item.alc_checklogs_status_shonin = 0
          item.alc_checklogs_shonin_user_name = null
        }
        if (
          !checkAll.alc_checklogs_status_shonin &&
          item.alc_checklogs_status_shuki &&
          !item.alc_checklogs_status_shonin
        ) {
          item.alc_checklogs_status_shonin = 1
          item.alc_checklogs_shonin_user_name = loginInfo?.ncsUserName || null
          item.alc_checklogs_shonin_ncs_user_id = loginInfo?.ncsUserId || null
        }
      }
      return item
    })
    setCheckAll((prev) => ({
      ...prev,
      [key]: !prev[key],
    }))
    setEditData([...dataEdit])
  }

  const onUpdateSuccess = () => {
    const data = editData?.map((e) => {
      if (
        !e.achievement_id ||
        !e.confirm_date ||
        !!e.alc_checklogs_status_shonin
      ) {
        return {
          ...e,
          editable: false,
        }
      }
      return {
        ...e,
        editable: true,
      }
    })
    setAchievements(JSON.parse(JSON.stringify(data)))
    setEditData(JSON.parse(JSON.stringify(data)))
  }

  const onChangeLimit = (num: number) => {
    setLimit(num)
    if (currentPage > 1) {
      setCurrentPage(1)
    }
  }
  const onChangePage = (num: number) => setCurrentPage(num)

  const downLoadCsv = async () => {
    resetRegisterAchievements()
    try {
      setIsLoading(true)
      const data = await fetchAchievementCsv(token, {
        kaisya_code: loginInfo?.kaisyaCode || '',
        ncs_user_id: loginInfo?.ncsUserId || '',
        ncs_user_name: loginInfo?.ncsUserName || '',
        search_type: filters.search_type,
        search_text: filters.search_text,
        achievement_type: 2,
        check_data: filters.check_data,
        check_status: filters.check_status,
        check_log: filters.check_log,
        sort_by: filters.sort_by,
        date_range: {
          from: filters.start_date,
          to: filters.end_date,
        },
      })
      if (data.status == '1') {
        const csvData = await fetchCsvDownLoad(token, data.resulturl)
        const link = document.createElement('a')
        const blob = new Blob([csvData as any], { type: 'application/csv' })
        link.href = URL.createObjectURL(blob)
        const userName = loginInfo?.ncsUserName || 'non_user'
        link.download =
          'アルコールチェック_' +
          userName +
          '_' +
          moment().format('YYYYMMDD_HHmmss') +
          '.csv'
        link.click()
      } else {
        alertService.show(false, data.message)
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      alertService.show(
        false,
        'システムエラーが発生しました。しばらく時間をおいてから再アクセスするか、お問い合わせください。（CSV出力）'
      )
      setIsLoading(false)
    }
  }

  const downLoadPdf = async () => {
    resetRegisterAchievements()
    try {
      setIsLoading(true)
      const data = await fetchAchievementPdf(token, {
        kaisya_code: loginInfo?.kaisyaCode || '',
        ncs_user_id: loginInfo?.ncsUserId || '',
        ncs_user_name: loginInfo?.ncsUserName || '',
        search_type: filters.search_type,
        search_text: filters.search_text,
        achievement_type: 2,
        check_data: filters.check_data,
        check_status: filters.check_status,
        check_log: filters.check_log,
        sort_by: filters.sort_by,
        date_range: {
          from: filters.start_date,
          to: filters.end_date,
        },
      })
      if (data.status == '1') {
        const csvData = await fetchCsvDownLoad(token, data.resulturl)
        const link = document.createElement('a')
        const blob = new Blob([csvData as any], { type: 'application/pdf' })
        link.href = URL.createObjectURL(blob)
        const userName = loginInfo?.ncsUserName || 'non_user'
        link.download =
          'アルコールチェック_' +
          userName +
          '_' +
          moment().format('YYYYMMDD_HHmmss') +
          '.pdf'
        link.click()
      } else {
        alertService.show(false, data.message)
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  return (
    <AlcoholContext.Provider
      value={{
        loadData,
        editData,
        handleEdit,
        toggleSelectAll,
        checkAll: checkAll,
        onApplyFilter,
        onUpdateSuccess,
        isLoading,
        currentPage,
        totalPage: data.totalPage,
        totalRecord: data.totalRecord,
        limit,
        onChangeLimit,
        onChangePage,
        handleSort,
        sortBy: sortBy,
        editedData,
        scopeCar,
        scopeUser,
        downLoadCsv,
        filters,
        downLoadPdf,
        registerAchievements,
        registerAchievementRows,
        handleRegisterCarName,
        handleRegisterUserName,
        onChangeAlcoholValue,
        onChangeWitnessUserId,
        onChangeWitnessUserNameById,
        resetValidateErrorMessage,
        onBlurChangeWitnessUserId,
        onChangeRegisterAlcoholItems,
        onChangeRegisterUserCarItems,
        registerConditions,
        registerAlcoholCheck,
        registerAlcoholRadio,
        registerAlcoholValidate,
        onChangeScopeUser,
        requiredValidation,
        successRegisteredAchievementAlcRows,
        failedRegisteredAchievementAlcRows,
        resetRegisterAchievements,
        changeIsAllFailed,
        resetRegisterItems,
        addAchievement,
      }}
    >
      {children}
    </AlcoholContext.Provider>
  )
}
