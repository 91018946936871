import axios from 'axios'
import { get, post } from 'common/fetch'
import { Car } from 'common/types/prework_provider'
import { Vehicle } from 'common/types/vehicle'
import { alertService } from 'services/alert'

const path = (): string => '/api/vehicle'

/**
 * 車両を全て取得する
 */
export async function fetch(
  token: string,
  client_id: number
): Promise<Vehicle[]> {
  return (await get<{ vehicles: Vehicle[] }>(token, path(), { client_id }))
    .vehicles
}

export const getCarList = async (
  token: string,
  body: any
): Promise<{ carList: Car[] }> => {
  return axios
    .post(`${process.env.REACT_APP_CAR_LIST}`, body, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((result) => result.data)
    .catch((error) => {
      console.log('error', error)
      alertService.show(
        false,
        'システムエラーが発生しました。しばらく時間をおいてから再アクセスするか、お問い合わせください。（車両番号取得）'
      )
      return { carList: [] }
    })
}
