import React, { ReactNode, useState } from 'react'
import { Input, Modal, Button, PopoverBody, UncontrolledPopover } from 'reactstrap'
import 'styles/alcoholCheckModal.scss'
import { AchievementAlcDetail } from 'common/types/achievement'
import IconCircle from '../Img/IconCircle'

interface Props {
  open: boolean
  onClose: () => void
  detailData?: AchievementAlcDetail
}

const AlcoholCheckDetailModal: React.VFC<Props> = (props) => {
  const { open, onClose, detailData } = props
  const [showImage, setShowImage] = useState<boolean>(false)
  const [isFlip, setFlipImage] = useState<boolean>(false)
  const [isDefault, setIsDefault] = useState<boolean>(true)
  const [isAsset, setIsAsset] = useState<boolean>(true)

  const toggle = () => setShowImage(!showImage)

  const renderRowInfo = (
    label: string,
    value: ReactNode,
    flex: number[],
    labelClass?: string
  ) => (
    <div className="row-info">
      <div className={`label-wrap flex-${flex[0]}`}>
        <div className={`${labelClass || ''}`}>{label}</div>
        <div>:</div>
      </div>
      <div className={`row-value flex-${flex[1]}`}>{value || ''}</div>
    </div>
  )

  // Modalで表示する画像
  const renderImageModal = (
    imageUrl: string,
    isDefault: boolean,
    isFlip: boolean
  ) => (
    imageUrl ? (
      <img
        src={`data:image/jpeg;base64,${imageUrl || ''}`}
        alt=""
        className={`image-lightbox ${!isDefault ? isFlip ? 'flip' : 'reverse' : ''}`}
      />
    ) : (
      <img src={''} alt="" className="image-lightbox" />
    )
  )

  // 画像とラベル表示
  const renderImage = (
    imageUrl: string,
    targetId: string,
    labelText: string,
    latitude: string,
    longitude: string
  ) => (
    <>
      <div className="flex-1">
        <span>{labelText}</span>
        <div
          className={`image-container ${imageUrl ? 'image-hover' : ''}`}
          onClick={() => onClickImage(imageUrl)}
        >
          {imageUrl ? (
            <img
              src={`data:image/jpeg;base64,${imageUrl}`}
              alt=""
              className="image"
              id={targetId}
            />
          ) : (
            <img src={''} alt="" className="image" />
          )}
          <p className="alcohol-detect-no-image">
            {imageUrl ? '' : '画像なし'}
          </p>
        </div>
      </div>
      {imageUrl &&
        <UncontrolledPopover
          trigger="hover"
          target={targetId}
        >
          <PopoverBody>
            {`撮影場所緯度経度`}
            <br />
            {`${latitude || ''}${latitude && longitude ? ',' : ''}${longitude || ''}`}
          </PopoverBody>
        </UncontrolledPopover>
      }
    </>
  )

  const onClickImage = (clickUrl: string) => {
    if (clickUrl === detailData?.asset_url || clickUrl === detailData?.optional_asset_url) {
      setShowImage(true)
      setFlipImage(false)
      setIsDefault(true)
      setIsAsset(clickUrl === detailData?.asset_url ? true : false)
    }
  }

  // 画像反転function
  const flip = () => {
    setIsDefault(false)
    setFlipImage(!isFlip)
  }

  return (
    <Modal
      className="modal-dialog-centered modal-alcohol-content"
      isOpen={open}
    >
      <Modal
        isOpen={showImage}
        toggle={toggle}
        backdrop
        backdropClassName="lightbox"
        className="lightbox-modal"
        centered
      >
        <div style={{backgroundColor: 'white', margin: 'auto', padding: '3px'}}>
          <div style={{overflow: 'hidden'}}>
            <Button color="primary" style={{float: 'right', marginBottom: '3px', display: 'flex', padding: '0.5rem 0.6rem 0.4rem'}} onClick={flip}>
              <div style={{display: 'inline-block', lineHeight: '13px', marginRight: '3px'}}>
                <img src="/assets/icons/icon_flip.png" alt="" />
              </div>
              <span>左右反転</span>
            </Button>
          </div>
          {isAsset ? (
            renderImageModal(
              detailData?.asset_url ?? '',
              isDefault,
              isFlip
            )
          ) : (
            renderImageModal(
              detailData?.optional_asset_url ?? '',
              isDefault,
              isFlip
            )
          )}
        </div>
      </Modal>
      <div className="modal-body modal-alcohol">
        <div className="close-wrap">
          <div className="close" onClick={onClose} />
        </div>
        <div className="modal-alcohol-header">
          <div className="modal-alcohol-header-title">
            アルコールチェック実績詳細
          </div>
          <div className="modal-alcohol-header-right">
            <div className="header-small">
              承認者：{detailData?.alc_checklogs_shonin_user_name || ''}
            </div>
            <div className="approval header-small flex-row">
              <span>承認：</span>
              <div
                className={
                  detailData?.alc_checklogs_status_shonin === 1
                    ? 'confirmation-icon'
                    : ''
                }
              >
                {detailData?.alc_checklogs_status_shonin === 1 ? '済' : '未'}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-alcohol-container">
          <div className="modal-alcohol-body">
            <div className="general-info">
              <div className="flex-1 column-info-left">
                {renderRowInfo(
                  '実施区分',
                  `${detailData?.implementation}`,
                  [1, 2]
                )}
                {renderRowInfo('登録日時', `${detailData?.reg_date}`, [1, 2])}
                {renderRowInfo('運転者', `${detailData?.user_name}`, [1, 2])}
                {renderRowInfo('車両番号', `${detailData?.car_name}`, [1, 2])}
                {renderRowInfo(
                  '立会者',
                  `${detailData?.witness_ncs_user_name || ''}`,
                  [1, 2]
                )}
              </div>
              <div className="flex-2 column-info">
                {renderRowInfo(
                  'チェック結果',
                  `${detailData?.alc_check_status || ''}`,
                  [1, 2]
                )}
                {renderRowInfo(
                  '実施日時',
                  `${detailData?.confirm_date || ''}`,
                  [1, 2]
                )}
                {renderRowInfo(
                  '運転者組織',
                  `${detailData?.user_soshiki_name || ''}`,
                  [1, 2]
                )}
                {renderRowInfo(
                  '車両組織',
                  `${detailData?.car_soshiki_name || ''}`,
                  [1, 2]
                )}
                {renderRowInfo(
                  '実施方法',
                  <div className="flex-row">
                    <span>{detailData?.witness_kind || ''}</span>
                  </div>,
                  [1, 2]
                )}
                {renderRowInfo(
                  '実施方法コメント',
                  <Input
                    type="textarea"
                    value={detailData?.witness_text || ''}
                    disabled
                    className="disable-resize"
                  />,
                  [1, 2],
                  'witness_text'
                )}
              </div>
            </div>

            <div className="alcohol-info">
              <div className="flex-1 column-info-left">
                {renderRowInfo('発熱', `${detailData?.fever}`, [2.8, 2])}
                {renderRowInfo('体調', `${detailData?.health}`, [2.8, 2])}
                {renderRowInfo(
                  '酒気帯び確認',
                  `${detailData?.alc_checklogs_status_shuki ? '済' : '未'}`,
                  [2.8, 2]
                )}
                {renderRowInfo(
                  '手入力測定値',
                  `${
                    detailData?.alcohol_value_manual
                      ? detailData?.alcohol_value_manual + ' mg/L'
                      : ' '
                  }`,
                  [2.8, 2]
                )}
                {renderRowInfo(
                  '画像認識測定値',
                  `${
                    detailData?.alcohol_value_detector
                      ? detailData?.alcohol_value_detector + ' mg/L'
                      : ' '
                  }`,
                  [2.8, 2]
                )}
              </div>
              <div className="flex-2 column-info">
                {renderRowInfo(
                  '検知器種別',
                  `${detailData?.alcohol_detector || ''}`,
                  [1, 2]
                )}
                <div className="row-info">
                  {renderImage(
                    detailData?.asset_url ?? '',
                    'image1',
                    'アルコール検知器画像',
                    detailData?.latitude ?? '',
                    detailData?.longitude ?? ''
                  )}
                  {renderImage(
                    detailData?.optional_asset_url ?? '',
                    'image2',
                    '追加画像（任意）',
                    detailData?.optional_latitude ?? '',
                    detailData?.optional_longitude ?? ''
                  )}
                </div>
              </div>
            </div>
            <div className="alcohol-info">
              <div className="flex-1 column-info-left">
                <span>運転者コメント：</span>
                <Input
                  className="comment-bottom disable-resize"
                  type="textarea"
                  value={detailData?.description || ''}
                  disabled
                />
              </div>
              <div className="flex-1 column column-info">
                <span>指示事項等：</span>
                <Input
                  type="textarea"
                  className="comment-bottom disable-resize"
                  value={detailData?.alc_description || ''}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="modal-alcohol-footer">
            <button
              type="button"
              className="close-modal-button"
              onClick={onClose}
            >
              閉じる
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AlcoholCheckDetailModal
