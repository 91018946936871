import React from 'react'

/**
 * selectのoptionを生成する
 */
const generateOptions = (op: any[], optionTitle?: string): JSX.Element[] => {
  const options: JSX.Element[] = [
    <option selected style={{ display: 'none' }}>
      {optionTitle}
    </option>,
  ]
  op.forEach(({ id, name }) =>
    options.push(
      <option key={id} value={id}>
        {name}
      </option>
    )
  )
  return options
}

/**
 * selectのoptionを生成する
 */
export const selectCategoriesOptions = (op: any[]): JSX.Element[] =>
  generateOptions(op, 'カテゴリを選択')
