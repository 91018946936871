import { IStoreCache } from 'common/types/auth_provider'
import { InspectionItemFormArray } from 'common/types/form/inspection_item'
import ConfirmModal from 'components/atoms/Modal/ConfirmModal'
import InspectionItemListItem from 'components/molecules/Admin/InspectionItem/InspectionItemListItem'
import React, { useState, useEffect, useRef } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import {
  Button,
  Form,
  ListGroup,
  ListGroupItem,
  Modal,
  Row,
  Col,
} from 'reactstrap'
import {
  initInspectionItem,
  initInspectionItemArray,
  saveInspectionItem,
} from 'services/admin/inspection_item'

type TInspectionItemModal = {
  isOpen: boolean
  storeCache: IStoreCache
  onToggle: () => void
}

const InspectionItemModal: React.VFC<TInspectionItemModal> = ({
  isOpen,
  storeCache,
  onToggle,
}: TInspectionItemModal) => {
  const { user, inspectionItems, categories } = storeCache
  const { client_id } = user!
  const defaultValues = initInspectionItemArray(client_id, inspectionItems)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<InspectionItemFormArray>({ defaultValues })
  const { fields, append, remove } = useFieldArray({ name: 'items', control })
  const watchFieldArray = watch('items')
  const controlledFields = fields.map((field, index) => {
    return { ...field, ...watchFieldArray[index] }
  })

  const onToggleConfirmModal = () => {
    if (JSON.stringify(watchFieldArray) === JSON.stringify(defaultValues.items))
      onToggle()
    else setIsConfirmModalOpen(!isConfirmModalOpen)
  }
  const closeAllModal = () => {
    setIsConfirmModalOpen(false)
    onToggle()
    reset()
  }
  const save = (data: InspectionItemFormArray) =>
    saveInspectionItem(storeCache, data)

  const messagesEndRef = useRef<null | HTMLDivElement>(null)

  const scrollToBottom = () =>
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })

  useEffect(scrollToBottom, [controlledFields])

  return (
    <>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onAction={() => {
          setIsConfirmModalOpen(false)
          handleSubmit(save)()
        }}
        onToggle={closeAllModal}
        onClose={() => setIsConfirmModalOpen(!isConfirmModalOpen)}
      />
      <Modal
        className="modal-dialog-centered inspection-item-modal"
        size="lg"
        isOpen={isOpen}
        toggle={onToggleConfirmModal}
      >
        <div className="modal-body modal-body-inspection">
          <div className="text-right btn-close" onClick={onToggleConfirmModal}>
            &#x2715;
          </div>
          <div className="modal-body-header">
            <div className="modal-body-header-left">
              <span className="modal-body-header-title">
                点検項目追加・編集
              </span>
              <Button
                className="modal-body-header-add px-2 py-2 ml-4"
                type="button"
                onClick={() => append(initInspectionItem(client_id))}
              >
                <span>+</span>新規点検項目追加
              </Button>
            </div>
          </div>
          <Form className="modal-form">
            <ListGroup className="list" flush>
              <ListGroupItem className="px-0 pr-3 list-header mt-4 mb-1">
                <Row className="align-items-center">
                  <Col
                    className="text-left pl-5 list-header-item"
                    sm={2}
                    md={3}
                    lg={2}
                  >
                    カテゴリ
                  </Col>
                  <Col
                    className="text-center list-header-item"
                    sm={4}
                    md={4}
                    lg={4}
                  >
                    内容
                  </Col>
                  <Col
                    className="text-center list-header-item prev-delete-col"
                    sm={5}
                    md={4}
                    lg={5}
                  >
                    詳細
                  </Col>
                  <Col
                    className="text-center list-header-item px-0 delete-col"
                    sm={1}
                  >
                    削除
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
            <ListGroup className="list inspection-modal-list custom-scrollbar" flush>
              {controlledFields.map((field, index) => {
                return (
                  <InspectionItemListItem
                    index={index}
                    categories={categories}
                    control={control}
                    errors={errors}
                    onRemove={() => remove(index)}
                    key={field.id}
                  />
                )
              })}
              <div ref={messagesEndRef} />
            </ListGroup>
            <div className="mt-4 d-flex justify-content-end btn-oper-wrap">
              <Button
                color="primary"
                type="button"
                onClick={onToggleConfirmModal}
              >
                閉じる
              </Button>
              <Button onClick={handleSubmit(save)} color="success">
                保存
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  )
}

export default InspectionItemModal
