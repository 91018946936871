import { AnswerJson } from 'common/types/answer/answer_json'
import { IStoreCache } from 'common/types/auth_provider'
import {
  InspectionItem,
  InspectionPatternItem,
  InspectionSnapshot,
} from 'common/types/inspection_snapshot'

export type AlcCheckItem = {
  key: string
  value: string
  result: string | number
}

export type InspectionPattern = {
  key: string
  value: string
  result: string
  categorie: string
  color: string
}

export enum BOX_TYPES {
  ALC_START,
  ALC_END,
  INSPECTION,
}

export type BoxState = {
  [BOX_TYPES.ALC_START]: boolean
  [BOX_TYPES.ALC_END]: boolean
  [BOX_TYPES.INSPECTION]: boolean
}

const initAnswer = (): AlcCheckItem[] => {
  return [
    { key: 'use_machine', value: 'ALCチェッカー使用', result: '' },
    { key: 'breath', value: 'ALC検出濃度', result: '' },
    { key: 'method', value: '確認手順', result: '' },
    { key: 'drunk', value: '酒気帯び有無', result: '' },
  ]
}

export const getAnswerData = (
  alcCheckBeforeJson: AnswerJson | undefined,
  inspectionJson: AnswerJson | undefined,
  alcCheckAfterJson: AnswerJson | undefined,
  storeCache: IStoreCache
): {
  start_alc_check_answers: AlcCheckItem[]
  inspection_answers: InspectionPattern[]
  end_alc_check_answers: AlcCheckItem[]
} => {
  let start_alc_check_answers: AlcCheckItem[] = initAnswer()
  let inspection_answers: InspectionPattern[] = []
  let end_alc_check_answers: AlcCheckItem[] = initAnswer()

  // 回答データをもとに表示用のデータを作成する
  if (alcCheckBeforeJson) {
    const achievement: any = alcCheckBeforeJson.achievement
    start_alc_check_answers.forEach((s) => {
      s.result = achievement[s.key]
    })
  }

  if (inspectionJson) {
    const inspectionPatternId: number =
      inspectionJson.achievement.inspection_pattern_id
    const inspectionSnapshots: InspectionSnapshot[] =
      storeCache.inspectionSnapshots
    const inspectionSnapshot: InspectionSnapshot | undefined =
      inspectionSnapshots?.find(
        (i) =>
          Number(i.snapshot.inspection_pattern_id) ===
          Number(inspectionPatternId)
      )
    const inspectionPatternItems: InspectionPatternItem[] | undefined =
      inspectionSnapshot?.snapshot.inspection_pattern_items
    inspectionPatternItems?.forEach(
      ({ inspection_items, category }: InspectionPatternItem) => {
        inspection_items.forEach(
          ({ inspection_item_id, content }: InspectionItem, i: number) => {
            const data: InspectionPattern = {
              key: 'key_' + inspection_item_id + '-' + i,
              value: content,
              result: 'OK',
              categorie: category.category_name,
              color: category.category_color,
            }
            inspection_answers.push(data)
          }
        )
      }
    )
  }

  if (alcCheckAfterJson) {
    const achievement: any = alcCheckAfterJson.achievement
    end_alc_check_answers.forEach((e) => {
      e.result = achievement[e.key]
    })
  }

  return { start_alc_check_answers, inspection_answers, end_alc_check_answers }
}
