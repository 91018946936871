import { Labels } from 'common/enums'
import StandardTable from 'components/atoms/Table/InspetionItemStandardTable'
import React, { useMemo } from 'react'
import { useTable, useSortBy, Column } from 'react-table'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import { InspectionItemTable } from 'common/types/table/inspection_item'
import NomalButton from 'components/atoms/Button/NomalButton'
import Display4 from 'components/atoms/Span/Display4'
import {
  inspectionItemTableBody,
  inspectionItemTableHeader,
} from 'services/admin/inspection_item'
import { IStoreCache } from 'common/types/auth_provider'

type TTableCard = {
  storeCache: IStoreCache
  onToggle: () => void
  onToggleForCategory: () => void
}

const TableCard: React.VFC<TTableCard> = ({
  storeCache,
  onToggle,
  onToggleForCategory,
}: TTableCard) => {
  const { categories, inspectionItems } = storeCache
  const columns: Column<InspectionItemTable>[] = useMemo(
    () => inspectionItemTableHeader(),
    []
  )
  const data: InspectionItemTable[] = useMemo(
    () => inspectionItemTableBody(categories, inspectionItems),
    [inspectionItems]
  )
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<InspectionItemTable>({ columns, data }, useSortBy)
  return (
    <div className="main-content">
      <Container className="mt-3">
        <Row className="justify-content-center">
          <Col lg="12" md="12">
            <Card className="border-0">
              <CardBody className="px-lg-4 py-0 pb-5">
                <div className="d-flex align-items-center mb-4">
                  <Display4 content={Labels.InspectionItemList} />
                  <NomalButton
                    className="ml-4"
                    color="primary"
                    size="sm"
                    content={Labels.InspectionItemAddEdit}
                    onClick={onToggle}
                  />
                  <NomalButton
                    className="mx-2"
                    color="primary"
                    size="sm"
                    content={Labels.CategoryEdit}
                    onClick={onToggleForCategory}
                  />
                </div>
                <StandardTable
                  getTableProps={getTableProps}
                  getTableBodyProps={getTableBodyProps}
                  headerGroups={headerGroups}
                  rows={rows}
                  prepareRow={prepareRow}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default TableCard
