const eastAsianWidth = (character: string) => {
  let x = character.charCodeAt(0)
  let y = character.length == 2 ? character.charCodeAt(1) : 0
  let codePoint = x
  if (x >= 0xd800 && x <= 0xdbff && y >= 0xdc00 && y <= 0xdfff) {
    x &= 0x3ff
    y &= 0x3ff
    codePoint = (x << 10) | y
    codePoint += 0x10000
  }

  if (
    codePoint == 0x3000 ||
    (codePoint >= 0xff01 && codePoint <= 0xff60) ||
    (codePoint >= 0xffe0 && codePoint <= 0xffe6)
  ) {
    return 'F'
  }
  if (
    codePoint == 0x20a9 ||
    (codePoint >= 0xff61 && codePoint <= 0xffbe) ||
    (codePoint >= 0xffc2 && codePoint <= 0xffc7) ||
    (codePoint >= 0xffca && codePoint <= 0xffcf) ||
    (codePoint >= 0xffd2 && codePoint <= 0xffd7) ||
    (codePoint >= 0xffda && codePoint <= 0xffdc) ||
    (codePoint >= 0xffe8 && codePoint <= 0xffee)
  ) {
    return 'H'
  }
  if (
    (codePoint >= 0x1100 && codePoint <= 0x115f) ||
    (codePoint >= 0x11a3 && codePoint <= 0x11a7) ||
    (codePoint >= 0x11fa && codePoint <= 0x11ff) ||
    (codePoint >= 0x2329 && codePoint <= 0x232a) ||
    (codePoint >= 0x2e80 && codePoint <= 0x2e99) ||
    (codePoint >= 0x2e9b && codePoint <= 0x2ef3) ||
    (codePoint >= 0x2f00 && codePoint <= 0x2fd5) ||
    (codePoint >= 0x2ff0 && codePoint <= 0x2ffb) ||
    (codePoint >= 0x3001 && codePoint <= 0x303e) ||
    (codePoint >= 0x3041 && codePoint <= 0x3096) ||
    (codePoint >= 0x3099 && codePoint <= 0x30ff) ||
    (codePoint >= 0x3105 && codePoint <= 0x312d) ||
    (codePoint >= 0x3131 && codePoint <= 0x318e) ||
    (codePoint >= 0x3190 && codePoint <= 0x31ba) ||
    (codePoint >= 0x31c0 && codePoint <= 0x31e3) ||
    (codePoint >= 0x31f0 && codePoint <= 0x321e) ||
    (codePoint >= 0x3220 && codePoint <= 0x3247) ||
    (codePoint >= 0x3250 && codePoint <= 0x32fe) ||
    (codePoint >= 0x3300 && codePoint <= 0x4dbf) ||
    (codePoint >= 0x4e00 && codePoint <= 0xa48c) ||
    (codePoint >= 0xa490 && codePoint <= 0xa4c6) ||
    (codePoint >= 0xa960 && codePoint <= 0xa97c) ||
    (codePoint >= 0xac00 && codePoint <= 0xd7a3) ||
    (codePoint >= 0xd7b0 && codePoint <= 0xd7c6) ||
    (codePoint >= 0xd7cb && codePoint <= 0xd7fb) ||
    (codePoint >= 0xf900 && codePoint <= 0xfaff) ||
    (codePoint >= 0xfe10 && codePoint <= 0xfe19) ||
    (codePoint >= 0xfe30 && codePoint <= 0xfe52) ||
    (codePoint >= 0xfe54 && codePoint <= 0xfe66) ||
    (codePoint >= 0xfe68 && codePoint <= 0xfe6b) ||
    (codePoint >= 0x1b000 && codePoint <= 0x1b001) ||
    (codePoint >= 0x1f200 && codePoint <= 0x1f202) ||
    (codePoint >= 0x1f210 && codePoint <= 0x1f23a) ||
    (codePoint >= 0x1f240 && codePoint <= 0x1f248) ||
    (codePoint >= 0x1f250 && codePoint <= 0x1f251) ||
    (codePoint >= 0x20000 && codePoint <= 0x2f73f) ||
    (codePoint >= 0x2b740 && codePoint <= 0x2fffd) ||
    (codePoint >= 0x30000 && codePoint <= 0x3fffd)
  ) {
    return 'W'
  }
  if (
    (codePoint >= 0x0020 && codePoint <= 0x007e) ||
    (codePoint >= 0x00a2 && codePoint <= 0x00a3) ||
    (codePoint >= 0x00a5 && codePoint <= 0x00a6) ||
    codePoint == 0x00ac ||
    codePoint == 0x00af ||
    (codePoint >= 0x27e6 && codePoint <= 0x27ed) ||
    (codePoint >= 0x2985 && codePoint <= 0x2986)
  ) {
    return 'Na'
  }
  if (
    codePoint == 0x00a1 ||
    codePoint == 0x00a4 ||
    (codePoint >= 0x00a7 && codePoint <= 0x00a8) ||
    codePoint == 0x00aa ||
    (codePoint >= 0x00ad && codePoint <= 0x00ae) ||
    (codePoint >= 0x00b0 && codePoint <= 0x00b4) ||
    (codePoint >= 0x00b6 && codePoint <= 0x00ba) ||
    (codePoint >= 0x00bc && codePoint <= 0x00bf) ||
    codePoint == 0x00c6 ||
    codePoint == 0x00d0 ||
    (codePoint >= 0x00d7 && codePoint <= 0x00d8) ||
    (codePoint >= 0x00de && codePoint <= 0x00e1) ||
    codePoint == 0x00e6 ||
    (codePoint >= 0x00e8 && codePoint <= 0x00ea) ||
    (codePoint >= 0x00ec && codePoint <= 0x00ed) ||
    codePoint == 0x00f0 ||
    (codePoint >= 0x00f2 && codePoint <= 0x00f3) ||
    (codePoint >= 0x00f7 && codePoint <= 0x00fa) ||
    codePoint == 0x00fc ||
    codePoint == 0x00fe ||
    codePoint == 0x0101 ||
    codePoint == 0x0111 ||
    codePoint == 0x0113 ||
    codePoint == 0x011b ||
    (codePoint >= 0x0126 && codePoint <= 0x0127) ||
    codePoint == 0x012b ||
    (codePoint >= 0x0131 && codePoint <= 0x0133) ||
    codePoint == 0x0138 ||
    (codePoint >= 0x013f && codePoint <= 0x0142) ||
    codePoint == 0x0144 ||
    (codePoint >= 0x0148 && codePoint <= 0x014b) ||
    codePoint == 0x014d ||
    (codePoint >= 0x0152 && codePoint <= 0x0153) ||
    (codePoint >= 0x0166 && codePoint <= 0x0167) ||
    codePoint == 0x016b ||
    codePoint == 0x01ce ||
    codePoint == 0x01d0 ||
    codePoint == 0x01d2 ||
    codePoint == 0x01d4 ||
    codePoint == 0x01d6 ||
    codePoint == 0x01d8 ||
    codePoint == 0x01da ||
    codePoint == 0x01dc ||
    codePoint == 0x0251 ||
    codePoint == 0x0261 ||
    codePoint == 0x02c4 ||
    codePoint == 0x02c7 ||
    (codePoint >= 0x02c9 && codePoint <= 0x02cb) ||
    codePoint == 0x02cd ||
    codePoint == 0x02d0 ||
    (codePoint >= 0x02d8 && codePoint <= 0x02db) ||
    codePoint == 0x02dd ||
    codePoint == 0x02df ||
    (codePoint >= 0x0300 && codePoint <= 0x036f) ||
    (codePoint >= 0x0391 && codePoint <= 0x03a1) ||
    (codePoint >= 0x03a3 && codePoint <= 0x03a9) ||
    (codePoint >= 0x03b1 && codePoint <= 0x03c1) ||
    (codePoint >= 0x03c3 && codePoint <= 0x03c9) ||
    codePoint == 0x0401 ||
    (codePoint >= 0x0410 && codePoint <= 0x044f) ||
    codePoint == 0x0451 ||
    codePoint == 0x2010 ||
    (codePoint >= 0x2013 && codePoint <= 0x2016) ||
    (codePoint >= 0x2018 && codePoint <= 0x2019) ||
    (codePoint >= 0x201c && codePoint <= 0x201d) ||
    (codePoint >= 0x2020 && codePoint <= 0x2022) ||
    (codePoint >= 0x2024 && codePoint <= 0x2027) ||
    codePoint == 0x2030 ||
    (codePoint >= 0x2032 && codePoint <= 0x2033) ||
    codePoint == 0x2035 ||
    codePoint == 0x203b ||
    codePoint == 0x203e ||
    codePoint == 0x2074 ||
    codePoint == 0x207f ||
    (codePoint >= 0x2081 && codePoint <= 0x2084) ||
    codePoint == 0x20ac ||
    codePoint == 0x2103 ||
    codePoint == 0x2105 ||
    codePoint == 0x2109 ||
    codePoint == 0x2113 ||
    codePoint == 0x2116 ||
    (codePoint >= 0x2121 && codePoint <= 0x2122) ||
    codePoint == 0x2126 ||
    codePoint == 0x212b ||
    (codePoint >= 0x2153 && codePoint <= 0x2154) ||
    (codePoint >= 0x215b && codePoint <= 0x215e) ||
    (codePoint >= 0x2160 && codePoint <= 0x216b) ||
    (codePoint >= 0x2170 && codePoint <= 0x2179) ||
    codePoint == 0x2189 ||
    (codePoint >= 0x2190 && codePoint <= 0x2199) ||
    (codePoint >= 0x21b8 && codePoint <= 0x21b9) ||
    codePoint == 0x21d2 ||
    codePoint == 0x21d4 ||
    codePoint == 0x21e7 ||
    codePoint == 0x2200 ||
    (codePoint >= 0x2202 && codePoint <= 0x2203) ||
    (codePoint >= 0x2207 && codePoint <= 0x2208) ||
    codePoint == 0x220b ||
    codePoint == 0x220f ||
    codePoint == 0x2211 ||
    codePoint == 0x2215 ||
    codePoint == 0x221a ||
    (codePoint >= 0x221d && codePoint <= 0x2220) ||
    codePoint == 0x2223 ||
    codePoint == 0x2225 ||
    (codePoint >= 0x2227 && codePoint <= 0x222c) ||
    codePoint == 0x222e ||
    (codePoint >= 0x2234 && codePoint <= 0x2237) ||
    (codePoint >= 0x223c && codePoint <= 0x223d) ||
    codePoint == 0x2248 ||
    codePoint == 0x224c ||
    codePoint == 0x2252 ||
    (codePoint >= 0x2260 && codePoint <= 0x2261) ||
    (codePoint >= 0x2264 && codePoint <= 0x2267) ||
    (codePoint >= 0x226a && codePoint <= 0x226b) ||
    (codePoint >= 0x226e && codePoint <= 0x226f) ||
    (codePoint >= 0x2282 && codePoint <= 0x2283) ||
    (codePoint >= 0x2286 && codePoint <= 0x2287) ||
    codePoint == 0x2295 ||
    codePoint == 0x2299 ||
    codePoint == 0x22a5 ||
    codePoint == 0x22bf ||
    codePoint == 0x2312 ||
    (codePoint >= 0x2460 && codePoint <= 0x24e9) ||
    (codePoint >= 0x24eb && codePoint <= 0x254b) ||
    (codePoint >= 0x2550 && codePoint <= 0x2573) ||
    (codePoint >= 0x2580 && codePoint <= 0x258f) ||
    (codePoint >= 0x2592 && codePoint <= 0x2595) ||
    (codePoint >= 0x25a0 && codePoint <= 0x25a1) ||
    (codePoint >= 0x25a3 && codePoint <= 0x25a9) ||
    (codePoint >= 0x25b2 && codePoint <= 0x25b3) ||
    (codePoint >= 0x25b6 && codePoint <= 0x25b7) ||
    (codePoint >= 0x25bc && codePoint <= 0x25bd) ||
    (codePoint >= 0x25c0 && codePoint <= 0x25c1) ||
    (codePoint >= 0x25c6 && codePoint <= 0x25c8) ||
    codePoint == 0x25cb ||
    (codePoint >= 0x25ce && codePoint <= 0x25d1) ||
    (codePoint >= 0x25e2 && codePoint <= 0x25e5) ||
    codePoint == 0x25ef ||
    (codePoint >= 0x2605 && codePoint <= 0x2606) ||
    codePoint == 0x2609 ||
    (codePoint >= 0x260e && codePoint <= 0x260f) ||
    (codePoint >= 0x2614 && codePoint <= 0x2615) ||
    codePoint == 0x261c ||
    codePoint == 0x261e ||
    codePoint == 0x2640 ||
    codePoint == 0x2642 ||
    (codePoint >= 0x2660 && codePoint <= 0x2661) ||
    (codePoint >= 0x2663 && codePoint <= 0x2665) ||
    (codePoint >= 0x2667 && codePoint <= 0x266a) ||
    (codePoint >= 0x266c && codePoint <= 0x266d) ||
    codePoint == 0x266f ||
    (codePoint >= 0x269e && codePoint <= 0x269f) ||
    (codePoint >= 0x26be && codePoint <= 0x26bf) ||
    (codePoint >= 0x26c4 && codePoint <= 0x26cd) ||
    (codePoint >= 0x26cf && codePoint <= 0x26e1) ||
    codePoint == 0x26e3 ||
    (codePoint >= 0x26e8 && codePoint <= 0x26ff) ||
    codePoint == 0x273d ||
    codePoint == 0x2757 ||
    (codePoint >= 0x2776 && codePoint <= 0x277f) ||
    (codePoint >= 0x2b55 && codePoint <= 0x2b59) ||
    (codePoint >= 0x3248 && codePoint <= 0x324f) ||
    (codePoint >= 0xe000 && codePoint <= 0xf8ff) ||
    (codePoint >= 0xfe00 && codePoint <= 0xfe0f) ||
    codePoint == 0xfffd ||
    (codePoint >= 0x1f100 && codePoint <= 0x1f10a) ||
    (codePoint >= 0x1f110 && codePoint <= 0x1f12d) ||
    (codePoint >= 0x1f130 && codePoint <= 0x1f169) ||
    (codePoint >= 0x1f170 && codePoint <= 0x1f19a) ||
    (codePoint >= 0xe0100 && codePoint <= 0xe01ef) ||
    (codePoint >= 0xf0000 && codePoint <= 0xffffd) ||
    (codePoint >= 0x100000 && codePoint <= 0x10fffd)
  ) {
    return 'A'
  }

  return 'N'
}

const characterLength = (character: string) => {
  let code = eastAsianWidth(character)
  if (code == 'F' || code == 'W' || code == 'A') {
    return 2
  }
  return 1
}

function stringToArray(string: string) {
  return string.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]|[^\uD800-\uDFFF]/g) || []
}

export const lengthChar = (string: string) => {
  let characters = stringToArray(string)
  let len = 0
  for (let i = 0; i < characters.length; i++) {
    len += characterLength(characters[i])
  }
  return len
}
