import * as React from 'react'
import { SVGProps } from 'react'

const IconTickNone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    {...props}
  >
    <g data-name="Line Checkbox Light">
      <g
        data-name="Rectangle 725"
        fill={props.fill || '#fff'}
        stroke={props.color || '#c3daff'}
        strokeWidth={2}
      >
        <rect width={16} height={16} rx={6} stroke="none" />
        <rect x={1} y={1} width={14} height={14} rx={5} fill="none" />
      </g>
    </g>
  </svg>
)

export default IconTickNone
