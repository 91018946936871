import React from 'react'
import classnames from 'classnames'
import 'styles/pagination.scss'
import Next from 'assets/img/NextPagination.svg'
import Back from 'assets/img/BackPagination.svg'
import { usePagination, DOTS } from './usePagination'

interface Props {
  onPageChange: (page: number) => void
  totalCount: number
  siblingCount: number
  currentPage: number
  pageSize: number
}

const Pagination: React.VFC<Props> = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  const onNext = () => {
    onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
  }

  let lastPage = paginationRange
    ? paginationRange[paginationRange?.length - 1]
    : 1
  return (
    <ul className={classnames('pagination-container')}>
      <div
        className={classnames('pagination-item', {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <img src={Back} alt="" />
      </div>
      {paginationRange &&
        paginationRange?.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return <li className="pagination-item dots" key={index}>&#8230;</li>
          }

          return (
            <div
              className={classnames('pagination-item', {
                selected: pageNumber === currentPage,
              })}
              onClick={() => onPageChange(Number(pageNumber))}
              key={index}
            >
              {pageNumber}
            </div>
          )
        })}
      <div
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        <img src={Next} alt="" />
      </div>
    </ul>
  )
}

export default Pagination
