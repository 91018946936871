import * as React from 'react'
import { SVGProps } from 'react'

const IconTickLine = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Line Checkbox Tick"
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    {...props}
  >
    <g data-name="Rectangle 727" fill="#fff" stroke="#3b86ff" strokeWidth={2}>
      <rect width={16} height={16} rx={6} stroke="none" />
      <rect x={1} y={1} width={14} height={14} rx={5} fill="none" />
    </g>
    <path
      data-name="Line 23"
      fill="none"
      stroke="#3b86ff"
      strokeWidth={2}
      d="M4.467 8h7"
    />
  </svg>
)

export default IconTickLine
