import React, { useContext, useState } from 'react'
import { PopoverBody, UncontrolledPopover } from 'reactstrap'
import { IAlcoholProvider } from 'common/types/alcohol_provider'
import { AlcoholContext } from 'providers/AlcoholProvider'
import { IAuthProvider } from 'common/types/auth_provider'
import { AuthContext } from 'providers/AuthProvider'
import { Car } from 'common/types/prework_provider'
import moment from 'moment'
import { getCarList } from 'repositories/vehicle'
import { AchievementAlc } from 'common/types/achievement'
import EditableText from '../Form/Text/EditableText'
import IconTickCheck from '../Img/IconTickCheck'
import IconTickNone from '../Img/IconTickNone'
import LoadingIndicator from '../LoadingIndicator'

type Props = {
  data: any
  toggleDetail: (params: AchievementAlc) => void
}

const TableRow: React.VFC<Props> = ({ data, toggleDetail }) => {
  const { handleEdit, registerAchievements }: IAlcoholProvider =
    useContext(AlcoholContext)
  const {
    storeCache: { loginInfo, token },
    todayCar,
  }: IAuthProvider = useContext(AuthContext)

  const [carList, setCarList] = useState<Car[]>([])
  const [options, setOptions] = useState<any[]>([])
  const [loadingCar, setLoadingCar] = useState<boolean>(false)

  const getCarListData = async () => {
    const dt = new Date(data?.original?.confirm_date)
    const year = dt.getFullYear().toString()
    const month = ('0' + (dt.getMonth() + 1)).slice(-2)
    const day = dt.getDate().toString().padStart(2, '0')
    const hour = dt.getHours().toString().padStart(2, '0')
    const minutes = dt.getMinutes().toString().padStart(2, '0')
    const seconds = dt.getSeconds().toString().padStart(2, '0')
    const confirmDate = year + '/' + month + '/' + day
    if (confirmDate === moment().format('YYYY/MM/DD')) {
      setCarList(todayCar)
      const option = todayCar.map((e) => ({
        label: e.name,
        value: e.driveRecorderId,
        ...e,
      }))
      setOptions(option)
      return
    }
    if (carList.length > 0) {
      const option = carList.map((e) => ({
        label: e.name,
        value: e.driveRecorderId,
        ...e,
      }))
      setOptions(option)
      return
    }
    setLoadingCar(true)
    const checkDate = year + month + day + 'T' + hour + minutes + seconds + '+0900'
    try {
      const res = await getCarList(token, {
        kaisyaCode: loginInfo?.kaisyaCode || '',
        ncsUserId: loginInfo?.ncsUserId || '',
        keyword: '',
        checkDate: checkDate,
      })
      setCarList(res.carList)
      const option = res.carList.map((e) => ({
        label: e.name,
        value: e.driveRecorderId,
        ...e,
      }))
      setOptions(option)
      setLoadingCar(false)
    } catch (error) {
      setLoadingCar(false)
    }
  }

  const onBlur = () => setOptions([])

  const onOpenDetail = () => toggleDetail(data.original)

  return (
    <>
      {loadingCar && <LoadingIndicator />}
      <div
        className="wrap-row"
        style={{
          display: 'flex',
          backgroundColor:
            data.cells[8].value !== '○' &&
            data.cells[8].value !== '' &&
            data.cells[8].value !== null
              ? 'rgba(226, 66, 120, 0.1)'
              : undefined,
        }}
      >
        {data.cells.map((cell: any, index: number) => {
          return (
            <div
              className={`alcohol-table-column table-column-content`}
              key={`${data.original.achievement_id}-${index}`}
            >
              {(index === 3 &&
                (loginInfo?.permission !== '0' &&
                data.original.editable &&
                registerAchievements.length <= 0 ? (
                  <EditableText
                    value={cell.value || ''}
                    options={options}
                    onOpenOptions={getCarListData}
                    onBlur={onBlur}
                    className="edit-high-light"
                    onChange={(value) =>
                      handleEdit(
                        data.original.achievement_id,
                        'car_name',
                        value
                      )
                    }
                    isGray={!data.original.car_soshiki_name}
                  />
                ) : (
                  <span
                    className={`table-column-text ${
                      !data.original.car_soshiki_name ? 'gray-text' : ''
                    }`}
                  >
                    {cell.render('Cell')}
                  </span>
                ))) ||
                (index === 8 && (
                  <div
                    className={`${
                      data.original.achievement_id
                        ? cell.value === '画像相違あり'
                          ? 'inspection-result-2'
                          : 'inspection-result'
                        : 'inspection-result-3'
                    }`}
                    onClick={
                      data.original.achievement_id ? onOpenDetail : () => {}
                    }
                  >
                    {cell.value}
                  </div>
                )) ||
                (index === 9 && (
                  <div className="alcohol-check-result">{cell.value || ''}</div>
                )) ||
                (index === 10 &&
                  (loginInfo?.permission === '0' ||
                  !data.original.achievement_id ||
                  registerAchievements.length > 0 ||
                  !data.original.confirm_date ? (
                    <IconTickNone fill="#E9ECEF" color="#E9ECEF" />
                  ) : (
                    <div
                      className={`table-column-checkbox`}
                      style={{ display: 'flex' }}
                      onClick={() => {
                        if (
                          !(
                            data.original.alc_checklogs_status_shuki &&
                            data.original.alc_checklogs_status_shonin
                          )
                        )
                          handleEdit(
                            data.original.achievement_id,
                            'alc_checklogs_status_shuki',
                            !cell.value
                          )
                      }}
                    >
                      {cell.value ? <IconTickCheck /> : <IconTickNone />}
                    </div>
                  ))) ||
                (index === 11 &&
                  (!data.original.achievement_id ||
                  loginInfo?.permission === '0' ||
                  registerAchievements.length > 0 ||
                  !data.original.confirm_date ? (
                    <IconTickNone fill="#E9ECEF" color="#E9ECEF" />
                  ) : (
                    <div
                      className={`table-column-checkbox`}
                      style={{ display: 'flex' }}
                      onClick={() => {
                        if (
                          !(
                            data.original.alc_checklogs_status_shonin &&
                            data.original?.alc_checklogs_shonin_ncs_user_id &&
                            data.original.alc_checklogs_shonin_ncs_user_id &&
                            loginInfo?.ncsUserId !==
                              data.original.alc_checklogs_shonin_ncs_user_id
                          )
                        )
                          handleEdit(
                            data.original.achievement_id,
                            'alc_checklogs_status_shonin',
                            !cell.value
                          )
                      }}
                    >
                      {cell.value ? <IconTickCheck /> : <IconTickNone />}
                    </div>
                  ))) ||
                ((index === 1 ||
                  index === 2 ||
                  index === 4 ||
                  index === 12 ||
                  index === 13) && (
                  <span
                    className={`table-column-text ${
                      cell.column.id === 'user_name' &&
                      !data.original.user_soshiki_name
                        ? 'gray-text'
                        : ''
                    }`}
                    id={`${cell.column.id}-${cell.row.index}`}
                  >
                    {cell.render('Cell')}
                    <UncontrolledPopover
                      trigger="hover"
                      target={`${cell.column.id}-${cell.row.index}`}
                    >
                      <PopoverBody>{cell.render('Cell')}</PopoverBody>
                    </UncontrolledPopover>
                  </span>
                )) ||
                (index === 14 && (
                  <>
                    <input
                      className="input-table-alcohol"
                      value={cell.value || ''}
                      onChange={(e) => {
                        handleEdit(
                          data.original.achievement_id,
                          'alc_description',
                          e.target.value
                        )
                      }}
                      disabled={
                        loginInfo?.permission === '0' ||
                        !data.original.editable ||
                        registerAchievements.length > 0
                      }
                    />
                  </>
                )) ||
                (index === 6 && (
                  <span className={`table-column-text`}>
                    {cell.value && cell.value[cell.value.length - 1]}
                  </span>
                )) ||
                (index === 7 && (
                  <span className={`table-column-text`}>
                    {cell.value && cell.value[0]}
                  </span>
                )) ||
                ((index === 0 || index === 5) && (
                  <span className={`table-column-text`}>
                    {cell.value?.split(' ')[0]}
                    {/* {cell.value ? moment(cell.value?.split(' ')[0]).format('YYYY/MM/DD') : ''} */}
                    <br />
                    {cell.value?.split(' ')[1]}
                  </span>
                )) || (
                  <span className={`table-column-text`}>
                    {cell.render('Cell')}
                  </span>
                )}
            </div>
          )
        })}
      </div>
    </>
  )
}

export default TableRow
