import React, { useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'

import FullLoadingSpinner from 'components/atoms/FullLoadingSpinner/FullLoadingSpinner'
import { loginDD } from 'services/login'
import { Routes } from 'common/enums'
import { IStoreCache } from '../common/types/auth_provider'
import { AuthContext } from '../providers/AuthProvider'

const WaitingLoginDD: React.VFC = () => {
  const storeCache: IStoreCache = useContext(AuthContext).storeCache
  const history = useHistory()
  const location = useLocation()
  useEffect(() => {
    const query = new URLSearchParams(location.search)
    if (query.get('token')) {
      loginDD(
        {
          token: query.get('token'),
        },
        storeCache,
        history
      )
    } else {
      history.push(Routes.Login)
    }
  }, [])

  return <FullLoadingSpinner />
}
export default WaitingLoginDD
